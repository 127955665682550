import styled from "styled-components";
import { TextField } from "@mui/material";

export const Container = styled("div")`
  display: flex;
`;

export const Input = styled(TextField)`
  width: 100% !important;
  margin-left: 1rem !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
`;
