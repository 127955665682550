import React, { useState } from "react";
import {
  StyledModalTitle,
  StyledModalBodyWrapper,
  StyledVerticalTabs,
  StyledTab,
  StyledModalBody,
  StyledModalContainer,
  StyledModalContent,
  CloseButton,
  CloseButtonContainer,
} from "components";
import { Modal } from "types";
import { modalConfig } from "./config";
import { Close } from "@mui/icons-material";

interface Modals {
  settings: string;
  help: string;
  profile: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FunctionComponent<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <StyledModalBody
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </StyledModalBody>
  );
};

export const ModalBlueprint: React.FunctionComponent<Modal> = ({
  startSubModal,
  modalType,
  handleClose,
}) => {
  const findStartPageIndex = () => {
    let foundPageIndex = 0;
    if (modalType && startSubModal) {
      modalConfig[modalType as keyof typeof modalConfig].tabs.forEach(
        (page, index) => {
          if (page.key === startSubModal) {
            foundPageIndex = index;
          }
        },
      );
    }
    return foundPageIndex;
  };
  const [value, setValue] = useState(findStartPageIndex());
  const modals = modalConfig[modalType as keyof Modals];

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledModalContainer>
      <StyledModalContent>
        <CloseButtonContainer>
          <CloseButton onClick={handleClose}>
            <Close />
          </CloseButton>
        </CloseButtonContainer>
        <StyledModalTitle>{modals.title}</StyledModalTitle>
        <StyledModalBodyWrapper>
          <StyledVerticalTabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
          >
            {modals.tabs.map((tab, index) => {
              return (
                <StyledTab
                  label={tab.label}
                  key={`vertical-tab-key-${index}`}
                  aria-controls={`vertical-tabpanel-${index}`}
                />
              );
            })}
          </StyledVerticalTabs>
          {modals.tabs.map((tab, index) => {
            return (
              <TabPanel
                key={`vertical-tab-panel-key-${index}`}
                value={value}
                index={index}
              >
                <tab.component />
              </TabPanel>
            );
          })}
        </StyledModalBodyWrapper>
      </StyledModalContent>
    </StyledModalContainer>
  );
};
