import Home from "./Home";
import Dashboard from "./Dashboard";
import FeatureRecord from "./FeatureRecord";
import Files from "./Files";
import Map from "./Map";
import Viewer from "./Viewer";
import Analytics from "./Analytics";
import { Routes } from "types";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { tooltipsContent } from "../components/atoms/Tooltip/tooltipsContent";

export const homeRoutes: Routes = [
  {
    name: "home",
    navText: "Home",
    path: "/",
    component: Home,
  },
];

export const dashboardRoutes = [
  {
    name: "dashboard",
    navText: "Dashboard",
    path: "/:tunnel_id/dashboard",
    component: Dashboard,
    icon: DashboardOutlinedIcon,
    tooltip: tooltipsContent.collapsedSidebarDashboard,
  },
  {
    name: "inspector",
    navText: "Inspector",
    path: "/:tunnel_id/inspector",
    component: Viewer,
    icon: ImageSearchIcon,
    tooltip: tooltipsContent.collapsedSidebar360View,
  },
  {
    name: "map",
    navText: "Map",
    path: "/:tunnel_id/map",
    component: Map,
    icon: MapOutlinedIcon,
    tooltip: tooltipsContent.collapsedSidebarMap,
  },
  {
    name: "feature-log",
    navText: "Feature log",
    path: "/:tunnel_id/feature-log",
    component: FeatureRecord,
    icon: CollectionsOutlinedIcon,
    tooltip: tooltipsContent.collapsedSidebarFeatureLog,
  },
  {
    name: "analysis",
    navText: "Analysis",
    path: "/:tunnel_id/analysis",
    component: Analytics,
    icon: InsightsOutlinedIcon,
    tooltip: tooltipsContent.collapsedSidebarAnalysis,
  },
  {
    name: "files",
    navText: "Files",
    path: "/:tunnel_id/files",
    component: Files,
    icon: FolderOutlinedIcon,
    tooltip: tooltipsContent.collapsedSidebarFiles,
  },
];

export const routes: Routes = homeRoutes.concat(dashboardRoutes);
