import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../utils/config";
import { User } from "types";

export const getUser = async (apiToken: string): Promise<User | null> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    headers: { Authorization: `Bearer ${apiToken}` },
  };
  const response = await axios.get(`${API_URL}api/user`, config);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
};
