import React, { useState } from "react";
import {
  Container,
  StyledCheckbox,
  CheckboxContainer,
  CheckboxText,
} from "./CheckboxGroup.styles";
import { CheckboxConfigType } from "./types";

export type CheckboxGroupProps = {
  config: CheckboxConfigType[];
  initialValue?: string[] | null;
  onChange: (_: string[]) => void;
};

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = ({
  config,
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState<string[]>(
    initialValue ? initialValue : [],
  );

  const handleChange = (key: string, checked: boolean) => {
    const newValue = checked ? AddKey(key) : RemoveKey(key);
    setValue(newValue);
    onChange(newValue);
  };

  const AddKey = (key: string): string[] => {
    const newValue = [...value];
    if (!newValue.includes(key)) newValue.push(key);
    return newValue;
  };

  const RemoveKey = (key: string) => {
    return value.filter((currentKey) => currentKey !== key);
  };

  return (
    <Container>
      {config.map((checkbox) => (
        <CheckboxContainer key={checkbox.key}>
          <StyledCheckbox
            style={{ color: checkbox.color }}
            key={checkbox.key}
            checked={value.includes(checkbox.key)}
            onChange={(_, checked) => handleChange(checkbox.key, checked)}
          />
          <CheckboxText>{checkbox.name}</CheckboxText>
        </CheckboxContainer>
      ))}
    </Container>
  );
};
