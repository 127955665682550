import styled from "styled-components";

export const HeadingXl = styled.h1`
  font-size: 3rem;
  letter-spacing: 0px;
  line-height: 76px;
`;

export const HeadingL = styled.h2`
  font-size: 2rem;
  letter-spacing: 0.3px;
  line-height: 52px;
`;

export const HeadingM = styled.h3`
  font-size: 1.5rem;
  letter-spacing: 0.4px;
  line-height: 40px;
`;

export const HeadingS = styled.h4`
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 28px;
`;

export const HeadingXs = styled.h5`
  font-size: 0.875rem !important;
  letter-spacing: 0.6px !important;
  line-height: 24px !important;
`;
