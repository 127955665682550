import React from "react";
import { SearchBarWrapper, PopperBox } from "./SearchBar.styles";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SearchbarOption } from "types";
import { Tooltip, tooltipsContent } from "components";
import { useTheme } from "styled-components";

type SearchBar = {
  options: SearchbarOption[];
  onInputChangeReturnFilteredOptions: (_: string[]) => void;
};
export const SearchBar: React.FunctionComponent<SearchBar> = ({
  options,
  onInputChangeReturnFilteredOptions,
}) => {
  const theme = useTheme();
  const filterArray = (
    options: SearchbarOption[],
    inputValue: string,
  ): string[] => {
    const filteredOptionNames = options.filter((option) => {
      const filteredOptionName = option.optionName
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      if (filteredOptionName) return filteredOptionName;
      if (option.secondaryOptionName) {
        return option.secondaryOptionName
          .toLowerCase()
          .includes(inputValue.toLowerCase());
      }
    });
    return filteredOptionNames.map((option) => option.optionName);
  };
  const handleSearchInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    if (newInputValue !== null) {
      const filteredOptions = filterArray(options, newInputValue);
      //for purpose of dynamic filtering
      onInputChangeReturnFilteredOptions(filteredOptions);
    }
  };
  return (
    <SearchBarWrapper>
      <Autocomplete
        freeSolo
        groupBy={(options) => options.groupBy?.toUpperCase() || ""}
        className="search-bar"
        id="search-bar"
        options={options}
        popupIcon={<SearchIcon />}
        getOptionLabel={(options: SearchbarOption | string) =>
          typeof options === "string" ? options : options.optionName
        }
        onInputChange={handleSearchInputChange}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search here..."
            variant="standard"
            InputProps={{
              ...params.InputProps,
              style: { color: theme.custom.palette.text },
              startAdornment: (
                <Tooltip title={tooltipsContent.searchBar}>
                  <InputAdornment position="start">
                    <SearchIcon htmlColor={theme.custom.palette.foreground} />
                  </InputAdornment>
                </Tooltip>
              ),
              disableUnderline: true,
            }}
          />
        )}
        renderOption={(
          params: React.HTMLAttributes<HTMLElement>,
          option: SearchbarOption,
        ) => <PopperBox {...params}>{option.optionName}</PopperBox>}
      />
    </SearchBarWrapper>
  );
};
