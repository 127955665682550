import React, { useState } from "react";
import moment from "moment";
import {
  StyledSubModalTitle,
  StyledSubModalSubTitle,
  StyledSubModalText,
  VerticalContainer,
  HorizontalContainer,
  DateInput,
  FileUpload,
  FlexContainer,
  ConfirmContainer,
  ConfirmButton,
  TextInput,
  SelectInput,
  Hint,
  Required,
} from "components";
import { UploadedFile } from "types";
import { useUploadTunnel } from "mutations";
import { useAppDispatch } from "redux/hooks";
import { set_modal } from "redux/Modal";
import { tooltipsContent } from "components";

const lineOptions = [
  {
    name: "Upline",
    value: "upline",
  },
  {
    name: "Downline",
    value: "downline",
  },
];

const typeOptions = [
  {
    name: "Road",
    value: "road",
  },
  {
    name: "Rail",
    value: "rail",
  },
];

const geolocationOptions = [
  {
    name: "GPS in EXIF",
    value: "gps_in_exif",
  },
  {
    name: "Ordered images",
    value: "ordered_images",
  },
];

type FileUploadData = {
  thumbnail: UploadedFile[];
  surveyImages: UploadedFile[];
  geologyImage: UploadedFile[];
};

type ValidateParams = null | string | File;

type Error = {
  value: string;
  validate: (_: ValidateParams) => string;
  ignore?: boolean;
};

type Errors = {
  name: Error;
  start_chainage: Error;
  lat: Error;
  long: Error;
  image_spacing: Error;
  survey_images: Error;
  thumbnail: Error;
};

const validateNumber = (value: ValidateParams) => {
  if (value === null) return noError;
  const strValue = value as string;
  const numberValue = parseFloat(strValue);
  if (strValue.replaceAll(" ", "") === "") {
    return "Please complete this field";
  } else if (numberValue <= 0) {
    return "Must be greater than 0";
  }
  return noError;
};

const validateString = (value: ValidateParams): string => {
  if (value === null) return noError;
  const strValue = value as string;
  if (strValue.replaceAll(" ", "") === "") {
    return "Please complete this field";
  } else {
    return noError;
  }
};

const validateFile = (value: ValidateParams): string => {
  const fileValue = value as File | null | "null";
  if (!fileValue || fileValue === "null") {
    return "Please upload a file";
  } else {
    return noError;
  }
};

const noError = " ";

const getFileFromObject = (files: UploadedFile[], multiple?: boolean) => {
  if (!multiple) return files[0] ? files[0].file : [];
  return files.map((file) => file.file);
};

export const NewTunnelModal: React.FunctionComponent = () => {
  const initialErrors: Errors = {
    name: { value: noError, validate: validateString },
    start_chainage: { value: noError, validate: validateNumber },
    lat: { value: noError, validate: validateString },
    long: { value: noError, validate: validateString },
    image_spacing: { value: noError, validate: validateNumber, ignore: true },
    survey_images: { value: noError, validate: validateFile },
    thumbnail: { value: noError, validate: validateFile },
  };
  const [errors, setErrors] = useState<Errors>(initialErrors);
  const { mutate: uploadTunnel } = useUploadTunnel();
  const dispatch = useAppDispatch();
  const [geolocationMethod, setGeolocationMethod] = useState<string>(
    geolocationOptions[0].value,
  );
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const [files, setFiles] = useState<FileUploadData>({
    thumbnail: [],
    surveyImages: [],
    geologyImage: [],
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("date", moment(date).format("DD-MM-YYYY"));
    formData.append("thumbnail", getFileFromObject(files.thumbnail) as File);
    (getFileFromObject(files.surveyImages, true) as File[]).forEach((file) => {
      formData.append("survey_images", file);
    });
    formData.append(
      "geology_image",
      getFileFromObject(files.geologyImage) as File,
    );

    const newErrors = { ...initialErrors };
    (Object.keys(newErrors) as (keyof Errors)[]).forEach((errorKey) => {
      newErrors[errorKey].value = errors[errorKey].validate(
        formData.get(errorKey) as ValidateParams,
      );
    });
    setErrors(newErrors);
    uploadTunnel(formData, {
      onSuccess: () => {
        dispatch(set_modal());
      },
      onError: () => {
        console.log("Error!");
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledSubModalTitle>Add new tunnel</StyledSubModalTitle>
      <StyledSubModalSubTitle>
        Tunnel details <Required>*</Required>
      </StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please provide the name and line of the tunnel
      </StyledSubModalText>
      <HorizontalContainer>
        <FlexContainer>
          <VerticalContainer>
            <TextInput
              name="name"
              label="Name"
              fullWidth
              hint={tooltipsContent.newTunnelName}
              errorMessage={errors.name.value}
            />
            <SelectInput
              name="line"
              style={{ marginTop: 0 }}
              label="Line"
              items={lineOptions}
              hint={tooltipsContent.newTunnelLine}
            />
            <SelectInput
              name="type"
              style={{ marginTop: 0 }}
              label="Line"
              items={typeOptions}
              hint={tooltipsContent.newTunnelType}
            />
          </VerticalContainer>
          <FileUpload
            files={files["thumbnail"]}
            setFiles={(newFiles) => setFiles({ ...files, thumbnail: newFiles })}
            acceptedFileTypes={["image/png", "image/jpeg"]}
            hint={tooltipsContent.newTunnelThumbnailUpload}
            errorMessage={errors.thumbnail.value}
          >
            Upload thumbnail
          </FileUpload>
        </FlexContainer>
      </HorizontalContainer>
      <StyledSubModalSubTitle>
        Geolocation method <Required>*</Required>
      </StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please provide the method of geolocating the survey data
      </StyledSubModalText>
      <HorizontalContainer>
        <VerticalContainer>
          <SelectInput
            name="geolocation_method"
            style={{ marginTop: 0 }}
            label="Method"
            value={geolocationMethod}
            onChange={(event) => {
              setErrors({
                ...errors,
                lat: initialErrors.lat,
                long: initialErrors.long,
                image_spacing: initialErrors.image_spacing,
              });
              setGeolocationMethod(event.target.value);
            }}
            items={geolocationOptions}
            hint={tooltipsContent.newTunnelGeolocation}
          />
        </VerticalContainer>
      </HorizontalContainer>
      <StyledSubModalSubTitle>
        Survey information <Required>*</Required>
      </StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please provide the date of the survey and images captured
      </StyledSubModalText>
      <HorizontalContainer>
        <FlexContainer>
          <VerticalContainer>
            <DateInput
              date={date}
              setDate={setDate}
              hint={tooltipsContent.newTunnelDate}
            />
            <TextInput
              validation="decimalNumber"
              name="start_chainage"
              label="Start chainage"
              unit="m"
              hint={tooltipsContent.newTunnelStartChainage}
              errorMessage={errors.start_chainage.value}
            />
            {geolocationMethod === "gps_in_exif" ? (
              <HorizontalContainer>
                <TextInput
                  validation="negDecimalNumber"
                  name="lat"
                  label="Lat"
                  unit="°"
                  fullWidth
                  errorMessage={errors.lat.value}
                />
                <TextInput
                  validation="negDecimalNumber"
                  name="long"
                  label="Long"
                  unit="°"
                  fullWidth
                  errorMessage={errors.long.value}
                />
                <Hint message={tooltipsContent.newTunnelStartLatLong} />
              </HorizontalContainer>
            ) : geolocationMethod === "ordered_images" ? (
              <TextInput
                validation="decimalNumber"
                name="image_spacing"
                label="Image spacing"
                unit="m"
                fullWidth
                hint={tooltipsContent.newTunnelImageSpacing}
                errorMessage={errors.image_spacing.value}
              />
            ) : null}
          </VerticalContainer>
          <FileUpload
            multipleFiles
            acceptedFileTypes={["image/png", "image/jpeg"]}
            files={files["surveyImages"]}
            setFiles={(newFiles) =>
              setFiles({ ...files, surveyImages: newFiles })
            }
            hint={tooltipsContent.newTunnelImageryUpload}
            errorMessage={errors.survey_images.value}
          >
            Upload 360° Imagery
          </FileUpload>
        </FlexContainer>
      </HorizontalContainer>
      <StyledSubModalSubTitle>Supporting files</StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please provide the optional tunnel alignment and geology image
      </StyledSubModalText>
      <FlexContainer>
        <FileUpload
          acceptedFileTypes={["image/svg+xml"]}
          files={files["geologyImage"]}
          setFiles={(newFiles) =>
            setFiles({ ...files, geologyImage: newFiles })
          }
          hint={tooltipsContent.newTunnelGeologyUpload}
        >
          Geology Image
        </FileUpload>
      </FlexContainer>
      <ConfirmContainer>
        <ConfirmButton type="submit">Add new tunnel</ConfirmButton>
      </ConfirmContainer>
    </form>
  );
};
