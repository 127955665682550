import React from "react";
import { Container, TitleText, Group } from "./DefectsFilter.styles";
import { CheckboxGroup, RadioGroup, SelectInput } from "components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { checkboxConfig, radioConfig } from "./config";
import { viewerInstances } from "components";
import {
  set_defect_type,
  set_min_confidence,
  set_date,
} from "redux/VerticalButtonGroup";
import { SelectInputItem } from "types";

const filterDates: SelectInputItem[] = [
  { name: "Any", value: "any" },
  { name: "02-11-2022", value: "02-11-2022" },
  { name: "22-11-2022", value: "22-11-2022" },
  { name: "30-09-2022", value: "30-09-2022" },
  { name: "16-11-2022", value: "16-11-2022" },
];

export const DefectsFilter: React.FunctionComponent = () => {
  const boundingBoxLayers = useAppSelector(
    (state) => state.VerticalButtonGroup.viewer3D.bounding_box_layers,
  );
  const dispatch = useAppDispatch();

  const handleDateChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = value.target.value;
    dispatch(set_date(newValue));
    setBoundingBoxes(
      newValue,
      boundingBoxLayers.defect_type,
      boundingBoxLayers.min_confidence,
    );
  };

  const handleDefectTypeChange = (value: string[]) => {
    dispatch(set_defect_type(value));
    setBoundingBoxes(
      boundingBoxLayers.date,
      value,
      boundingBoxLayers.min_confidence,
    );
  };

  const handleMinConfidenceChange = (value: string) => {
    const newValue = parseFloat(value);
    dispatch(set_min_confidence(newValue));
    setBoundingBoxes(
      boundingBoxLayers.date,
      boundingBoxLayers.defect_type,
      newValue,
    );
  };

  const setBoundingBoxes = (
    date: string,
    defect_type: string[],
    min_confidence: number,
  ) => {
    Object.values(viewerInstances).forEach((instance) => {
      instance &&
        instance.setBoundingBoxes(
          defect_type.length !== 0,
          min_confidence,
          date,
          defect_type,
        );
      instance &&
        instance.setOriginalBoundingBoxes(
          defect_type.length !== 0,
          min_confidence,
          date,
          defect_type,
        );
    });
  };

  return (
    <Container>
      <TitleText>Date</TitleText>
      <Group>
        <SelectInput
          items={filterDates}
          value={boundingBoxLayers.date}
          onChange={handleDateChange}
          style={{
            margin: "0.5rem",
            marginLeft: 0,
            marginRight: 0,
          }}
        />
      </Group>
      <TitleText>Feature type</TitleText>
      <Group>
        <CheckboxGroup
          config={checkboxConfig}
          initialValue={boundingBoxLayers.defect_type}
          onChange={handleDefectTypeChange}
        />
      </Group>
      <TitleText>Confidence</TitleText>
      <Group>
        <RadioGroup
          config={radioConfig}
          initialValue={String(boundingBoxLayers.min_confidence)}
          onChange={handleMinConfidenceChange}
        />
      </Group>
    </Container>
  );
};
