import { createSlice } from "@reduxjs/toolkit";
import { viewerInstances } from "components";
import { Tunnel } from "types";

export type TunnelDataState = {
  tunnelId?: number;
  actual: {
    ringNumber: number;
    minRingNumber: number;
    maxRingNumber: number;
  };
  display: {
    ringNumber: number;
    minRingNumber: number;
    maxRingNumber: number;
  };
};

const initialState: TunnelDataState = {
  actual: {
    ringNumber: 0,
    minRingNumber: 0,
    maxRingNumber: 0,
  },
  display: {
    ringNumber: 0,
    minRingNumber: 0,
    maxRingNumber: 0,
  },
};

export const TunnelData = createSlice({
  name: "TunnelData",
  initialState,
  reducers: {
    set_new_tunnel: (state, action) => {
      const newTunnel: Tunnel = action.payload;
      return newTunnel.id !== state.tunnelId
        ? {
            tunnelId: newTunnel.id,
            actual: {
              ringNumber: newTunnel.actual_start_ringnumber,
              minRingNumber: newTunnel.actual_start_ringnumber,
              maxRingNumber:
                newTunnel.actual_start_ringnumber + newTunnel.total_rings - 1,
            },
            display: {
              ringNumber: newTunnel.display_start_ringnumber,
              minRingNumber: newTunnel.display_start_ringnumber,
              maxRingNumber:
                newTunnel.display_start_ringnumber + newTunnel.total_rings - 1,
            },
          }
        : state;
    },
    set_display_ring_number: (state, action) => {
      const displayRingNumber = action.payload;
      const ringNumber = displayRingNumber - state.display.minRingNumber;
      Object.values(viewerInstances).forEach(
        (instance) => instance && instance.setVideoRN(ringNumber),
      );
      return {
        ...state,
        actual: {
          ...state.actual,
          ringNumber: ringNumber,
        },
        display: {
          ...state.display,
          ringNumber: displayRingNumber,
        },
      };
    },
  },
});

export const { set_display_ring_number, set_new_tunnel } = TunnelData.actions;

export default TunnelData.reducer;
