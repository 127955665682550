import React, { useState } from "react";
import ReactMapGL, { MapLoadEvent, MapEvent, Popup } from "react-map-gl";

import { Viewport, MapRenderInfo, Feature } from "types";

import { centerMapToAlignment } from "utils/helpers";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { set_access_point } from "redux/MapSideInfo";
import { set_viewport, set_canvas } from "redux/Map";

export const MapMol: React.FunctionComponent<MapRenderInfo> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const { viewport, bounds } = useAppSelector((state) => state.Map);
  const { theme, mapStyle } = useAppSelector((state) => state.Settings);
  const [showPopup, setShowPopup] = useState(false);
  const [popupCoords, setPopupCoords] = useState([0, 0]);
  const [hoverAsset, setHoverAsset] = useState<Feature>({
    type: "",
    geometry: {
      type: "",
      coordinates: [0, 0],
    },
    properties: {
      title: "",
      type: "",
    },
  });
  const [cursorType, setCursorType] = useState("auto");

  const updateViewport = (viewport: Viewport) => {
    dispatch(
      set_viewport({
        longitude: viewport.longitude,
        latitude: viewport.latitude,
        zoom: viewport.zoom,
        width: "100%",
        height: "100%",
      }),
    );
  };

  const handleLoad = (event: MapLoadEvent) => {
    const canvas = event.target.getCanvas().getBoundingClientRect();
    dispatch(set_canvas({ width: canvas.width, height: canvas.height }));

    const { zoom } = centerMapToAlignment(viewport, bounds, {
      width: canvas.width,
      height: canvas.height,
    });
    updateViewport({ ...viewport, zoom: zoom, height: "100%", width: "100%" });
  };

  const handleOnClick = (e: MapEvent) => {
    if (e.features) {
      e.features.length > 0
        ? dispatch(
            set_access_point({
              type: e.features[0].type,
              geometry: e.features[0].geometry,
              properties: e.features[0].properties,
            }),
          )
        : dispatch(set_access_point(null));
    }
  };

  const handleOnHover = (e: MapEvent) => {
    if (e.features && e.features.length > 0)
      if (e.features[0].properties.type === "shaft") {
        setCursorType("pointer");
        setShowPopup(true);
        setPopupCoords(e.lngLat);
        setHoverAsset(e.features[0]);
      } else if (e.features[0].properties.type === "asset-alignment") {
        setCursorType("pointer");
      } else {
        setCursorType("grab");
        setShowPopup(false);
      }
  };

  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle={
        mapStyle === "satellite"
          ? process.env.REACT_APP_MAPBOX_MAPSTYLE_SATELLITE
          : theme === "dark"
          ? process.env.REACT_APP_MAPBOX_MAPSTYLE_DARK
          : process.env.REACT_APP_MAPBOX_MAPSTYLE_LIGHT
      }
      attributionControl={false}
      getCursor={() => cursorType}
      onViewportChange={updateViewport}
      onLoad={handleLoad}
      onClick={handleOnClick}
      onHover={handleOnHover}
    >
      {children}
      {showPopup && (
        <Popup
          longitude={popupCoords[0]}
          latitude={popupCoords[1]}
          anchor="bottom-left"
          closeButton={false}
        >
          {hoverAsset.properties.location
            ? hoverAsset.properties.location
            : hoverAsset.properties.title}
        </Popup>
      )}
    </ReactMapGL>
  );
};
