import * as React from "react";
import { TunnelCard, Loading, Error } from "components";
import { CardCollectionWrapper } from "./CardCollection.styles";
import { Tunnel } from "types";

type Props = {
  tunnels: Tunnel[];
  loading?: boolean;
  updated: number;
};

export const CardCollection: React.FunctionComponent<Props> = ({
  tunnels,
  loading,
  updated,
}) => {
  return (
    <CardCollectionWrapper
      style={{ "--column-count": 4, "--column-width": "calc(22rem - 15%)" }}
    >
      {loading ? (
        <Loading containerStyles={{ height: "25rem" }} />
      ) : tunnels.length > 0 ? (
        tunnels.map(
          (tunnel: Tunnel) =>
            tunnel.id && (
              <TunnelCard key={tunnel.id} {...tunnel} updated={updated} />
            ),
        )
      ) : (
        <Error>No tunnels available</Error>
      )}
    </CardCollectionWrapper>
  );
};
