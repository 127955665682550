import styled from "styled-components";

export const FigmaContainer = styled("iframe")({
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2.45rem)",
  margin: "1rem",
  marginTop: "1.45rem",
  backgroundColor: "black",
  borderRadius: "0.5rem",
});
