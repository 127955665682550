import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem;
  pointer-events: auto;
  width: 45px;
`;
