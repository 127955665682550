import React from "react";
import { Container, StyledSpinner } from "./Loading.styles";

type Props = {
  style?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
};

export const Loading: React.FunctionComponent<Props> = ({
  style,
  containerStyles,
}) => {
  return (
    <Container style={containerStyles}>
      <StyledSpinner style={style} />
    </Container>
  );
};
