import styled from "styled-components";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

export const StyledRadioGroup = styled(RadioGroup)({
  margin: "0rem",
  paddingTop: "0.5rem",
});

export const StyledLabel = styled(FormControlLabel)((props) => {
  return {
    paddingBottom: "0.5rem",
    margin: "0rem",
    "& .MuiTypography-root": {
      fontSize: "14px",
      paddingLeft: "0.5rem",
      color: props.theme.custom.palette.text,
    },
  };
});

export const StyledRadio = styled(Radio)({
  padding: "0rem",
});
