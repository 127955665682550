import React from "react";
import { UserTabs } from "components";
import {
  TopBarWrapper,
  UserTabsWrapper,
  Heading,
  FlexHeadingWrapper,
  FlexWrapper,
  LargeLogo,
  Highlight,
  PortalPlaceholder,
  LogoContainer,
} from "./TopBar.styles";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { Portal } from "@mui/material";
import { useTunnel } from "queries";
import { useTheme } from "styled-components";

type props = {
  type: "dashboard" | "path";
};

export const TopBar: React.FunctionComponent<props> = ({ type }) => {
  const theme = useTheme();
  const location = useLocation();
  const { tunnel } = useTunnel();
  const sideBarOpen = useAppSelector((state) => state.SideBarOpen.open);
  const dashboardLocation = location.pathname.split("/");
  let locationPath = dashboardLocation[dashboardLocation.length - 1];
  locationPath = locationPath ? locationPath.replaceAll("-", " ") : "home";
  const displayLocationPath =
    locationPath.charAt(0).toUpperCase() + locationPath.slice(1);

  return (
    <>
      <PortalPlaceholder />
      <Portal>
        <TopBarWrapper
          style={{
            width: `calc(100% - ${
              type === "dashboard" ? (sideBarOpen ? 240 : 56) : 0
            }px)`,
          }}
        >
          <FlexWrapper>
            {type !== "dashboard" && (
              <LogoContainer>
                <LargeLogo src={theme.custom.media.arupLogo} alt="Arup logo" />
              </LogoContainer>
            )}
            <FlexHeadingWrapper>
              <Heading
                style={{ marginLeft: type === "path" ? "1.5rem" : "1rem" }}
              >
                <Highlight>
                  {type === "dashboard" ? tunnel?.display_name : ""}
                </Highlight>
                {(type === "dashboard" && tunnel?.display_name ? " / " : "") +
                  displayLocationPath}
              </Heading>
            </FlexHeadingWrapper>
          </FlexWrapper>
          <UserTabsWrapper>
            <UserTabs />
          </UserTabsWrapper>
        </TopBarWrapper>
      </Portal>
    </>
  );
};
