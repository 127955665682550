import styled from "styled-components";
import { Slider } from "@mui/material";

export const StyledDefectCountSlider = styled(Slider)<{ middleRG: number }>(
  (props) => ({
    marginTop: "2.5rem",
    marginBottom: "1.5rem",
    marginLeft: "1.5rem",
    width: "calc(100% - 2rem)",

    "& .MuiSlider-track": {
      backgroundColor: props.theme.custom.palette.mediumCondition,
      border: "0px",
    },

    "& .MuiSlider-thumb": {
      backgroundColor: props.theme.custom.palette.sliderThumb,
    },

    "& .MuiSlider-mark": {
      backgroundColor: props.theme.custom.palette.sliderThumb,
    },

    "& .MuiSlider-markLabel": {
      color: props.theme.custom.palette.text,
    },

    "& .MuiSlider-rail": {
      background: `linear-gradient(to right, ${props.theme.custom.palette.goodCondition}, ${props.theme.custom.palette.goodCondition} ${props.middleRG}%, ${props.theme.custom.palette.badCondition} ${props.middleRG}%, ${props.theme.custom.palette.badCondition});`,
      opacity: 1,
    },
  }),
);
