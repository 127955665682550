import styled from "styled-components";
import { Paper, TextField, Slider } from "@mui/material";

export const Wrapper = styled(Paper)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 0.5rem;
  padding-right: 1rem;
  height: 3.5rem;
  margin: 1rem;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
`;

export const SliderWrapper = styled.form`
  position: relative;
  width: calc(100% - 2rem);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0px;
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

export const CustomSlider = styled(Slider)`
  position: absolute !important;
  left: 0.5rem !important;
  width: 100% !important;
  margin-bottom: 0 !important;

  & .MuiSlider-thumb {
    width: 1.25rem;
    height: 1.25rem;
  }
  & .MuiSlider-rail {
    height: 2px;
  }
  & .MuiSlider-track {
    height: 2px;
  }
  & .MuiSlider-markLabel {
    color: ${(props) => props.theme.custom.palette.text};
  }
  & .MuiSlider-mark {
    background-color: ${(props) => props.theme.custom.palette.foreground};
    height: 12px;
    width: 2px;
  }
  & .MuiSlider-markLabel {
    top: 20px !important;
  }
`;

export const SliderHandleFollow = styled(TextField)<{
  style: {
    "--percent": string;
    "--border-color": string;
  };
}>((props) => ({
  position: "absolute",
  left: "calc(var(--percent) + 0.5rem)",
  bottom: "2.75rem",
  width: "3.1rem",
  transform: "translateX(-50%)",
  backgroundColor: props.theme.custom.palette.truePaper,
  borderRadius: "4px",
  "& label.Mui-focused": {
    color: "var(--border-color)",
    borderWidth: 1,
  },
  "& .MuiOutlinedInput-root": {
    color: props.theme.custom.palette.text,
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-color)",
      borderWidth: 1,
    },
  },
}));

export const RingNumberText = styled.div`
  font-size: 0.8rem;
  width: 3rem;
  color: ${(props) => props.theme.custom.palette.text};
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
`;

export const StartingLocation = styled.div`
  margin-top: 0.25rem;
  font-size: 0.825rem;
  position: absolute;
  left: 4.5rem;
  color: ${(props) => props.theme.custom.palette.text};
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EndLocation = styled.div`
  margin-top: 0.25rem;
  font-size: 0.825rem;
  position: absolute;
  color: ${(props) => props.theme.custom.palette.text};
  right: 1.375rem;
  display: flex;
  flex-direction: row;
  top: 0;
  align-items: center;
`;
