import styled from "styled-components";
import { ListItemText } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";

export const FilterAltIcon = styled(FilterAlt)`
  color: ${(props) => props.theme.custom.palette.foreground};
`;

export const ItemText = styled(ListItemText)`
  color: ${(props) => props.theme.custom.palette.text};
`;
