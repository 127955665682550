import { useQuery } from "react-query";
import { ragRatings } from "types";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "utils/config";
import { useAppSelector } from "redux/hooks";
import { getTunnelIdFromUrl } from "utils/helpers";

type TunnelQuery = {
  ragRatings?: ragRatings;
  isLoading: boolean;
  error: Error | null;
};

export const useRagRating = (
  grouping: number,
  defects_filter?: string[],
): TunnelQuery => {
  const tunnelId = getTunnelIdFromUrl();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );
  const settings = useAppSelector((state) => state.Settings);

  const redDefects = Math.ceil(grouping * settings.defectLimitRed);
  const greenDefects = Math.ceil(grouping * settings.defectLimitGreen);

  const { data, isLoading, error } = useQuery<ragRatings, Error>(
    `tunnel_${tunnelId}_rag_rating_red_${redDefects}_green_${greenDefects}_grouping_${grouping}_defects_filter_${defects_filter?.toString()}`,
    async () => {
      const config: AxiosRequestConfig = {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await axios.get(
        `${API_URL}api/tunnels/${tunnelId}/rag-rating?red_defects=${redDefects}&green_defects=${greenDefects}&grouping=${grouping}${
          defects_filter ? `&defects_filter=${defects_filter?.toString()}` : ""
        }`,
        config,
      );
      return response.data;
    },
    { enabled: !!tunnelId },
  );

  return {
    ragRatings: data,
    isLoading,
    error,
  };
};
