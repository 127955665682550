import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { MapTile, MapFullPage, LoadingFlex } from "components";
import { DashboardComponentProps, FeatureCollection } from "types";
import { calculateInitialMapState } from "utils/helpers";
import { useAppDispatch } from "redux/hooks";
import { set_viewport, set_bounds, set_map_data_ready } from "redux/Map";
import { useTunnel } from "queries";

const useStyles = makeStyles(() => ({
  fullscreen: {
    height: "calc(100% - 0.25rem)",
    width: "calc(100% - 0.25rem)",
    marginTop: "0.25rem",
    borderRadius: "0.5rem",
  },
  tile: { height: "calc(100% - 0.25rem)", width: "100%" },
}));

export const MapTemplate: React.FunctionComponent<DashboardComponentProps> = ({
  mapType,
  fullscreen,
}) => {
  const classes = useStyles();
  const componentStyle = fullscreen ? classes.fullscreen : classes.tile;
  const [mapData, setMapData] = useState<FeatureCollection[]>([]);
  const dispatch = useAppDispatch();

  const { tunnel } = useTunnel();

  useEffect(() => {
    if (tunnel) {
      setMapData([
        tunnel.asset_alignment,
        tunnel.general_alignment,
        tunnel.shafts,
      ]);
    }
  }, [tunnel]);

  const { initialViewport, bounds, mapDataReady } =
    calculateInitialMapState(mapData);

  dispatch(set_viewport(initialViewport));
  dispatch(set_bounds(bounds));
  dispatch(set_map_data_ready(mapDataReady));

  return (
    <div className={componentStyle}>
      {mapType && mapDataReady ? (
        mapType === "fullPage" ? (
          <MapFullPage mapData={mapData}></MapFullPage>
        ) : mapType === "tile" ? (
          <MapTile mapData={mapData}></MapTile>
        ) : null
      ) : (
        <LoadingFlex
          message="Loading"
          container
          containerStyle={
            mapType === "fullPage"
              ? {
                  width: "calc(100% - 2rem)",
                  height: "calc(100% - 2rem)",
                  margin: "1rem",
                  marginTop: "1.75rem",
                  borderRadius: "0.5rem",
                }
              : mapType === "tile"
              ? { height: "calc(100% - 36px)" }
              : undefined
          }
        />
      )}
    </div>
  );
};
