import styled from "styled-components";
import Unity from "react-unity-webgl";

export const StyledUnity = styled(Unity)<{
  style: {
    "--display": string;
    "--border-top": string;
  };
}>`
  position: absolute;
  display: var(--display);
  border-radius: 0.5rem;
  border-top-left-radius: var(--border-top);
  border-top-right-radius: var(--border-top);
  overflow: hidden;
  z-index: 2;
`;

export const Frame = styled.div`
  z-index: 3;
  position: absolute;
  pointer-events: none;
`;

export const ControlsContainer = styled.div`
  position: relative;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;
