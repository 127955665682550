import React from "react";
import { PageTemplate, MapTemplate } from "components";

const Map: React.FunctionComponent = () => {
  return (
    <PageTemplate pageType="sideBar">
      <MapTemplate mapType="fullPage"></MapTemplate>
    </PageTemplate>
  );
};

export default Map;
