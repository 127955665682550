import { unityContext } from "components";
import { API_URL } from "utils/config";

export const handleSetServicesConfig = (bearerToken: string): void => {
  unityContext.send(
    "WebInitInputs",
    "SetServicesConfig",
    JSON.stringify({ apiUrl: API_URL, bearerToken: bearerToken }),
  );
};

export const exitFullscreen = (): void => {
  unityContext.send(
    "WebInitInputs",
    "SetFullscreen",
    JSON.stringify({ name: "" }),
  );
};

export default class ViewerInstance {
  private name: string;
  private inputManager: string;
  private currentDefectImageId: number | null = null;

  constructor(_name: string) {
    this.name = _name;
    this.inputManager = `${_name}-input-manager`;
    this.createViewer();
  }

  private createViewer = () => {
    unityContext.send(
      "WebInitInputs",
      "CreateViewer",
      JSON.stringify({ name: this.name }),
    );
  };

  deleteViewer = () => {
    unityContext.send(
      "WebInitInputs",
      "DeleteViewer",
      JSON.stringify({ name: this.name }),
    );
  };

  setTunnel = (
    tunnelId: number,
    _: number,
    __: number,
    ___: number,
    ____: number,
    imageRotation: number,
    wallNumbersEnabled: boolean,
  ): void => {
    unityContext.send(
      this.inputManager,
      "SetTunnel",
      JSON.stringify({
        tunnelId: tunnelId,
        minRing: 0,
        maxRing: 0,
        startRing: 0,
        initialRing: 0,
        imageRotation: imageRotation,
        wallNumbersEnabled: wallNumbersEnabled,
      }),
    );
  };

  setVideoRN = (_: number): void => {
    // unityContext.send(
    //   this.inputManager,
    //   "SetVideoRN",
    //   JSON.stringify({ ringNumber: ringNumber }),
    // );
  };

  zoomIn = (): void => {
    unityContext.send(this.inputManager, "ZoomIn");
  };

  zoomOut = (): void => {
    unityContext.send(this.inputManager, "ZoomOut");
  };

  setRotation = (x: number, y: number, z: number) => {
    unityContext.send(
      this.inputManager,
      "SetRotation",
      JSON.stringify({ x: x, y: y, z: z }),
    );
  };

  setDefaultView = () => {
    unityContext.send(this.inputManager, "SetDefaultView");
  };

  setFullscreen = () => {
    unityContext.send(
      "WebInitInputs",
      "SetFullscreen",
      JSON.stringify({ name: this.name }),
    );
  };

  setBoundingBoxes = (
    enable: boolean,
    minConfidence: number,
    date: string,
    defectTypes: string[],
  ) => {
    unityContext.send(
      this.inputManager,
      "SetBoundingBoxes",
      JSON.stringify({
        enable: enable,
        minConfidence: minConfidence,
        boundingBoxDate: date === "any" ? "" : date,
        defectTypes: defectTypes,
      }),
    );
  };

  setOriginalBoundingBoxes = (
    enable: boolean,
    minConfidence?: number,
    date?: string,
    defectTypes?: string[],
  ) => {
    if (this.currentDefectImageId) {
      unityContext.send(
        this.inputManager,
        "SetOriginalBoundingBoxes",
        JSON.stringify({
          enable: enable,
          minConfidence: minConfidence,
          boundingBoxDate: date === "any" ? "" : date,
          defectTypes: defectTypes,
          defectImageId: this.currentDefectImageId,
        }),
      );
    }
  };

  setPolyLines = (enable: boolean, defectImageId?: number) => {
    unityContext.send(
      this.inputManager,
      "SetPolyLines",
      JSON.stringify({
        enable: enable,
        defectImageId: defectImageId,
      }),
    );
  };

  setSecondaryImage = (enable: boolean, defectImageId?: number) => {
    this.currentDefectImageId = enable
      ? defectImageId
        ? defectImageId
        : null
      : null;
    unityContext.send(
      this.inputManager,
      "SetSecondaryImage",
      JSON.stringify({
        enable: enable,
        defectImageId: defectImageId,
      }),
    );
  };
}
