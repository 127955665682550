import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useLocation } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";
import { locationPathSplit } from "utils/helpers";
import { Wrapper, StyledLink, StyledListItemText } from "./NavList.styles";
import { useTheme } from "styled-components";
import { useParams } from "react-router-dom";
import { Tooltip } from "components";

type Props = {
  text: string;
  color: string;
  link: string;
  hidden: boolean;
  Icon: SvgIconComponent;
  tunnelIdRoute?: boolean;
  tooltip: string;
};

export const NavList: React.FunctionComponent<Props> = ({
  text,
  Icon,
  hidden,
  color,
  link,
  tunnelIdRoute,
  tooltip,
}) => {
  const location = useLocation();
  const locationPath = locationPathSplit(location.pathname);
  const linkPath = locationPathSplit(link);
  const active = locationPath === linkPath;
  const theme = useTheme();
  const params = useParams();
  const tunnelId: string = params.tunnel_id ? params.tunnel_id : ":tunnel_id";

  return (
    <Wrapper
      style={{
        "--backgroundColor": active ? `${theme.palette.primary.main}22` : "",
        "--border-right": active
          ? `6px solid ${theme.palette.primary.main}`
          : "",
      }}
      data-testid="navlist-label"
    >
      <StyledLink
        to={link}
        tunnelId={tunnelIdRoute ? tunnelId : undefined}
        style={{ color: theme.custom.palette.foreground }}
      >
        <ListItem button style={{ paddingLeft: hidden ? "1rem" : "1.5rem" }}>
          <ListItemIcon sx={{ display: "" }}>
            <Tooltip title={hidden ? tooltip : ""} placement="right">
              <Icon
                data-testid="icon"
                style={{
                  color: active
                    ? theme.palette.primary.main
                    : theme.custom.palette.foreground,
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <StyledListItemText
            style={{
              "--color": color,
            }}
            primary={text}
            primaryTypographyProps={{
              style: {
                fontWeight: "400",
                fontSize: "1.1rem",
                color: active ? theme.palette.primary.main : "",
              },
            }}
          />
        </ListItem>
      </StyledLink>
    </Wrapper>
  );
};
