import styled from "styled-components";
import { Box } from "@mui/material";

export const SearchBarWrapper = styled.div`
  display: block;
  height: 2rem;
  min-width: 13rem;
  width: 25%;
  padding-right: 0;
  margin-right: 1rem;
  background-color: ${(props) => props.theme.custom.palette.truePaper};
  padding: 0.5rem;
  border-radius: 0.3rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12);
  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25),
      0px 2px 2.5px rgba(0, 0, 0, 0.14), 0px 0.5px 5px rgba(0, 0, 0, 0.12);
    border: none;
  }
`;

export const PopperBox = styled(Box)`
  color: ${(props) => props.theme.custom.palette.text};
`;
