import React from "react";
import { PageTemplate, PageUnderConstruction } from "components";

const Files: React.FunctionComponent = () => {
  return (
    <PageTemplate pageType="sideBar">
      <PageUnderConstruction subtext="File storage is coming soon..." />
    </PageTemplate>
  );
};

export default Files;
