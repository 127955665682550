import styled from "styled-components";
import { Paper, Typography } from "@mui/material";

export const Container = styled(Paper)`
  min-width: 9rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled(Typography)`
  font-size: 14px;
  color: ${(props) => props.theme.custom.palette.text};
`;

export const Group = styled("div")`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;
