import styled from "styled-components";
import { FileUploadOutlined } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";

export const Container = styled("div")({
  margin: "1rem",
  display: "flex",
  flexDirection: "column",
});

export const DropzoneContainer = styled("div")({
  height: "9rem",
  position: "relative",
});

export const Image = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: "0.5rem",
});

export const Placeholder = styled("div")((props) => ({
  backgroundColor: props.theme.custom.palette.background,
  padding: "1rem",
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.5rem",
}));

export const PlaceholderContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const FileUploadIcon = styled(FileUploadOutlined)((props) => ({
  color: props.theme.custom.palette.foreground,
  width: "3rem !important",
  height: "3rem !important",
}));

export const PlaceholderText = styled(Typography)((props) => ({
  color: props.theme.custom.palette.foreground,
}));

export const UploadContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "2.5rem",
});

export const UploadElement = styled("input")({ display: "none" });

export const UploadButton = styled(Button)({
  whiteSpace: "nowrap",
  minWidth: "0px",
});

export const UploadStatusText = styled(Typography)({
  fontSize: "14px !important",
  paddingLeft: "1rem",
  whiteSpace: "nowrap",
});

export const WarningContainer = styled("div")`
  display:flex
  flex-direction: column;
`;

export const WarningText = styled(Typography)`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
  text-align: left !important;
  margin-top: 4px !important;
  margin-right: 14px !important;
  margin-bottom: 0 !important;
  margin-left: 14px !important;
  line-height: 0.75rem !important;
  margin-top: 0.25rem !important;
  color: #d32f2f !important;
`;
