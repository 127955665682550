import { createSlice } from "@reduxjs/toolkit";

interface ModalState {
  open: boolean;
  type: "profile" | "settings" | "help" | "manage_assets" | null;
  startPage: string | null;
  ringsPerInterval: number;
}

const initialState: ModalState = {
  open: false,
  type: null,
  startPage: null,
  ringsPerInterval: 100,
};

export const Modal = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    set_modal: (state) => {
      return { ...state, open: state.open ? false : true };
    },
    set_modal_type: (state, action) => {
      return { ...state, type: action.payload, startPage: null };
    },
    set_modal_start_page: (state, action) => {
      return { ...state, startPage: action.payload };
    },
  },
});

export const { set_modal, set_modal_type, set_modal_start_page } =
  Modal.actions;

export default Modal.reducer;
