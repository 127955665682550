import { useQuery } from "react-query";
import { Tunnel } from "types";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "utils/config";
import { useAppSelector } from "redux/hooks";

type TunnelsQuery = {
  tunnels?: Tunnel[];
  isLoading: boolean;
  error: Error | null;
  updated: number;
};

export const useTunnels = (): TunnelsQuery => {
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );

  const { data, isLoading, error } = useQuery<Tunnel[], Error>(
    "tunnels",
    async () => {
      const config: AxiosRequestConfig = {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await axios.get(`${API_URL}api/tunnels`, config);
      return response.data;
    },
  );

  return {
    tunnels: data,
    isLoading,
    error,
    updated: Date.now(),
  };
};
