import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import AccessToken from "./AccessToken";
import SideBarOpen from "./SideBarOpen";
import Viewer3DStatus from "./Viewer3DStatus";
import TunnelData from "./TunnelData";
import SessionData from "./SessionData";
import MapSideInfo from "./MapSideInfo";
import Map from "./Map";
import VerticalButtonGroup from "./VerticalButtonGroup";
import Modal from "./Modal";
import Settings from "./Settings";

export const store = configureStore({
  reducer: {
    AccessToken: AccessToken,
    SideBarOpen: SideBarOpen,
    Viewer3DStatus: Viewer3DStatus,
    TunnelData: TunnelData,
    SessionData: SessionData,
    MapSideInfo: MapSideInfo,
    Map: Map,
    VerticalButtonGroup: VerticalButtonGroup,
    Modal: Modal,
    Settings: Settings,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
