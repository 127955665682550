import styled, { css } from "styled-components";
import { Drawer, IconButton } from "@mui/material";
interface Props {
  open: boolean;
}

export const StyledExpandButton = styled(IconButton)<Props>`
  background-color: ${(props) =>
    props.theme.custom.palette.truePaper} !important;
  width: 44px;
  margin-top: 50vh !important;
  border-radius: 6px 0 0 6px !important;
  height: 8vh;
  z-index: 800;
  position: absolute !important;
  right: 0;
  margin-right: ${(props: Props) =>
    props.open ? "calc(25vw + 1rem)" : "0"} !important;
  top: 0;
  transition: margin-right 400ms ease 0ms !important;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%),
    0px 2px 4px rgb(0 0 0 / 20%) !important;
  clip-path: inset(-3px 0px -3px -3px);
`;

export const StyledDrawer = styled(Drawer)<Props>`
  z-index: 1;
  & .MuiDrawer-paper {
    background-color: ${(props) =>
      props.theme.custom.palette.truePaper} !important;
    z-index: 1;
    position: relative;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
    box-shadow: none;
    border-right: none;
    float: right;
    margin-left: 1rem;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 8px;
    width: 25vw;
    margin-top: 0.125rem;
    height: calc(100% - 0.25rem);
    transition: width 400ms ease 0ms;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 8px;
    }
    ${({ open }) =>
      !open &&
      css`
        transition: width 400ms ease 0ms;
        margin-left: 0rem;
        width: 0px;
      `}
  }
`;
