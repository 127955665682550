import React from "react";
import { Box, IconButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTheme } from "styled-components";
import { TabsWrapper, AvatarWrapper } from "./UserTabs.styles";
import { tooltipsContent, ModalAccess } from "components";
import { useAppSelector } from "redux/hooks";

export const UserTabs: React.FunctionComponent = () => {
  const user = useAppSelector((state) => state.AccessToken);
  const sessionData = useAppSelector((state) => state.SessionData);

  const theme = useTheme();

  return (
    <TabsWrapper data-testid="user-tabs">
      <ModalAccess
        pageKey="help"
        startPage="cookie_settings"
        tooltip={tooltipsContent.helpIcon}
      >
        <IconButton aria-label="Help Page" component="span">
          <HelpIcon
            style={{ fontSize: "2rem", color: theme.custom.palette.foreground }}
          />
        </IconButton>
      </ModalAccess>
      <ModalAccess
        pageKey="settings"
        startPage="rag_rating"
        tooltip={tooltipsContent.settingsIcon}
      >
        <IconButton aria-label="Settings Page" component="span">
          <SettingsIcon
            style={{ fontSize: "2rem", color: theme.custom.palette.foreground }}
          />
        </IconButton>
      </ModalAccess>
      <ModalAccess pageKey="profile" tooltip={tooltipsContent.userIcon}>
        <IconButton aria-label="Profile Page" component="span">
          {user.meta.imageURL ? (
            <AvatarWrapper
              src={`data:image/jpeg;base64, ${user.meta.imageURL}`}
            />
          ) : (
            <AccountCircleIcon
              style={{
                fontSize: "2rem",
                color: theme.custom.palette.foreground,
              }}
            />
          )}
        </IconButton>
      </ModalAccess>
      <Box
        color={theme.custom.palette.foreground}
        fontFamily={"Arial"}
        fontWeight={"700"}
      >
        {`${user.meta.name}${sessionData.admin ? " (Admin)" : ""}`}
      </Box>
    </TabsWrapper>
  );
};
