import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled("div")({
  position: "relative",
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2.25rem)",
  margin: "1rem",
  marginTop: "1.75rem",
  backgroundColor: "black",
  borderRadius: "0.5rem",
  pointerEvents: "none",
});

export const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "center",
  borderRadius: "0.5rem",
  opacity: 0.75,
});

export const TextContainer = styled("div")({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const Text = styled(Typography)({
  fontSize: "2rem",
  textAlign: "center",
  color: "white",
  marginTop: "0.5rem",
  marginBottom: "1rem",
});

export const SubText = styled(Typography)({
  textAlign: "center",
  color: "white",
});

export const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const Logo = styled("img")({
  width: "2rem",
  height: "2rem",
});

export const LogoText = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "2rem",
  color: "#DEDEDE",
  marginLeft: "0.5rem",
});

export const CopyrightContainer = styled("div")({
  position: "absolute",
  right: "0px",
  bottom: "0px",
  margin: "1rem",
});

export const CopyrightText = styled(Typography)({
  color: "#ACACAC",
  fontSize: "12px",
});
