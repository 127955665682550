import {
  AboutSubModal,
  AccessibilitySubModal,
  ContactSubModal,
  CookiePolicySubModal,
  CookieSettingsSubModal,
  RagRatingSubModal,
  SubscriptionSubModal,
  ProfileSubModal,
  ThemeSubModal,
  UnitsSubModal,
  NewTunnelModal,
  TncSubModal,
} from "components";

export const modalConfig = {
  settings: {
    title: "Settings",
    tabs: [
      {
        key: "accessibility",
        label: "Accessibility",
        component: AccessibilitySubModal,
      },
      { key: "rag_rating", label: "RAG rating", component: RagRatingSubModal },
      { key: "theme", label: "Theme", component: ThemeSubModal },
      { key: "units", label: "Units", component: UnitsSubModal },
    ],
  },
  help: {
    title: "Help",
    tabs: [
      { key: "about", label: "About", component: AboutSubModal },
      { key: "contact", label: "Contact", component: ContactSubModal },

      {
        key: "cookie_settings",
        label: "Cookie settings",
        component: CookieSettingsSubModal,
      },
      {
        key: "cookie_policy",
        label: "Cookie policy",
        component: CookiePolicySubModal,
      },
      {
        key: "terms_and_conditions",
        label: "Terms & Conditions",
        component: TncSubModal,
      },
      {
        key: "subscription",
        label: "Subscription",
        component: SubscriptionSubModal,
      },
    ],
  },
  profile: {
    title: "Account",
    tabs: [{ key: "profile", label: "Profile", component: ProfileSubModal }],
  },
  manage_assets: {
    title: "Manage assets",
    tabs: [
      { key: "new_tunnel", label: "New tunnel", component: NewTunnelModal },
    ],
  },
};
