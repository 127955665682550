import styled from "styled-components";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export const StyledDragIcon = styled(DragIndicatorIcon)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  color: ${(props) => props.theme.custom.palette.foreground};
`;
