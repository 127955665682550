import styled from "styled-components";
import { Typography } from "@mui/material";
import { Construction } from "@mui/icons-material";

export const Container = styled("div")({
  position: "relative",
  width: "calc(50vw - 12rem)",
  height: "calc(90vh - 10rem)",
  pointerEvents: "none",
});

export const TextContainer = styled("div")((props) => ({
  position: "absolute",
  left: "50%",
  top: "47.5%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: props.theme.custom.palette.watermark,
  alignItems: "center",
}));

export const Text = styled(Typography)((props) => ({
  fontSize: "2rem",
  textAlign: "center",
  color: props.theme.custom.palette.watermark,
  marginTop: "0.5rem",
  marginBottom: "1rem",
}));

export const SubText = styled(Typography)((props) => ({
  textAlign: "center",
  color: props.theme.custom.palette.watermark,
}));

export const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const Logo = styled("img")({
  width: "2rem",
  height: "2rem",
  filter: "grayscale(100%)",
  opacity: 0.4,
});

export const LogoText = styled(Typography)((props) => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "2rem",
  color: props.theme.custom.palette.watermark,
  marginLeft: "0.5rem",
}));

export const ConstructionIcon = styled(Construction)((props) => ({
  marginTop: "3rem",
  width: "6rem",
  height: "6rem",
  color: props.theme.custom.palette.watermark,
}));
