import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "types";

interface SettingsState {
  ringsPerInterval: number;
  defectLimitGreen: number;
  defectLimitRed: number;
  maxDefects: number;
  maxRingsPerInterval: number;
  theme: Theme;
  mapStyle: "digital" | "satellite";
  featureCountGrouping: number | undefined;
}

const initialState: SettingsState = {
  ringsPerInterval: 1,
  defectLimitGreen: 5,
  defectLimitRed: 20,
  maxDefects: 100,
  maxRingsPerInterval: 100,
  theme: "light",
  mapStyle: "digital",
  featureCountGrouping: undefined,
};

export const Settings = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    set_rings_per_interval: (state, action) => {
      return { ...state, ringsPerInterval: action.payload };
    },
    set_defect_limit_green: (state, action) => {
      return { ...state, defectLimitGreen: action.payload };
    },
    set_defect_limit_red: (state, action) => {
      return { ...state, defectLimitRed: action.payload };
    },
    set_settings: (state, action) => {
      return {
        ...state,
        ringsPerInterval: action.payload.ringsPerInterval,
        defectLimitGreen: action.payload.defectLimitGreen,
        defectLimitRed: action.payload.defectLimitRed,
        theme: action.payload.theme,
      };
    },
    set_theme: (state, action) => {
      return { ...state, theme: action.payload };
    },
    set_map_style: (state, action) => {
      return { ...state, mapStyle: action.payload };
    },
    set_feature_count_grouping: (state, action) => {
      return { ...state, featureCountGrouping: action.payload };
    },
  },
});

export const {
  set_rings_per_interval,
  set_defect_limit_green,
  set_defect_limit_red,
  set_settings,
  set_theme,
  set_map_style,
  set_feature_count_grouping,
} = Settings.actions;

export default Settings.reducer;
