import React from "react";
import { Wrapper } from "./Required.styles";

type Props = {
  children: React.ReactNode;
};

export const Required: React.FunctionComponent<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
