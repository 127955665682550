import { WebMercatorViewport } from "react-map-gl";
import posthog from "posthog-js";
import {
  ComponentDimensions,
  FeatureCollection,
  Bounds,
  Feature,
  row,
  Viewport,
  route,
} from "types";
import moment from "moment";

import { routes } from "pages/routes";

export const getTunnelIdFromUrl = (): number | undefined => {
  const tunnelId = parseInt(location.pathname.split("/")[1]);
  return isNaN(tunnelId) ? undefined : tunnelId;
};

export const locationPathSplit = (location: string): string => {
  const splitLocation = location.split("/");
  return splitLocation[splitLocation.length - 1];
};

export const getRouteByPath = (routePath: string): route | null => {
  let selectedRoute = null;
  routes.forEach((route) => {
    if (route.path === routePath) {
      selectedRoute = route;
    }
  });
  return selectedRoute;
};

export const getRouteByName = (routeName: string): route | null => {
  let selectedRoute = null;
  routes.forEach((route) => {
    if (route.name === routeName) {
      selectedRoute = route;
    }
  });
  return selectedRoute;
};

export const DOMRectToDimensions = (domRect: DOMRect): ComponentDimensions => {
  return {
    width: domRect.width,
    height: domRect.height,
    top: domRect.top,
    left: domRect.left,
    bottom: domRect.bottom,
    right: domRect.right,
  };
};

export const CompareComponentDimensions = (
  d1: ComponentDimensions | null,
  d2: ComponentDimensions | null,
): boolean => {
  if (!d1 || !d2) return true;
  let key: keyof typeof d1;
  for (key in d1) {
    if (d1[key] !== d2[key]) return false;
  }
  return true;
};

// MAP HELPERS
export const transformMapDataForPlotting = (
  mapData: FeatureCollection[],
): {
  alignment: FeatureCollection;
  shafts: FeatureCollection;
} => {
  const alignment: FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };
  const alignmentGeneral: FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };
  let shafts: FeatureCollection = { type: "FeatureCollection", features: [] };
  mapData.forEach((item) => {
    if (item.features) {
      item.features.forEach((feat) => {
        if (feat.properties.type === "asset-alignment") {
          alignment.features.push(feat);
        } else if (feat.properties.type === "general-alignment") {
          alignmentGeneral.features.push(feat);
        } else {
          shafts = item;
        }
      });
    }
  });
  alignment.features.unshift(...alignmentGeneral.features);
  return { alignment, shafts };
};

export const calculateInitialMapState = (
  mapData: FeatureCollection[],
): {
  initialViewport: Viewport;
  bounds: Bounds;
  mapData: FeatureCollection[];
  mapDataReady: boolean;
} => {
  const viewport = {
    longitude: 0,
    latitude: 0,
    width: "100%",
    height: "100%",
    zoom: 10,
  };

  const bounds: Bounds = {
    minLong: 0,
    maxLong: 0,
    minLat: 0,
    maxLat: 0,
    centerLong: 0,
    centerLat: 0,
  };

  const alignment: Array<number[]> = [];

  let mapDataReady = false;
  if (mapData.length > 0) {
    mapData.forEach((object) => {
      object.features &&
        object.features.forEach((feat) => {
          if (feat.properties.type === "asset-alignment") {
            feat.geometry.coordinates.forEach((point) => {
              if (Array.isArray(point)) {
                alignment.push(point);
              }
              if (alignment.length === 1) {
                bounds.minLong = Array.isArray(alignment[0])
                  ? alignment[0][0]
                  : 0;
                bounds.maxLong = Array.isArray(alignment[0])
                  ? alignment[0][0]
                  : 0;
                bounds.minLat = Array.isArray(alignment[0])
                  ? alignment[0][1]
                  : 0;
                bounds.maxLat = Array.isArray(alignment[0])
                  ? alignment[0][1]
                  : 0;
              }
              if (alignment.length > 1) {
                if (Array.isArray(point)) {
                  bounds.minLong = Math.min(bounds.minLong, point[0]);
                  bounds.maxLong = Math.max(bounds.maxLong, point[0]);
                  bounds.minLat = Math.min(bounds.minLat, point[1]);
                  bounds.maxLat = Math.max(bounds.maxLat, point[1]);
                }
              }
            });
          }
        });
    });

    viewport.longitude = (bounds.minLong + bounds.maxLong) / 2;
    bounds.centerLong = viewport.longitude;
    viewport.latitude = (bounds.minLat + bounds.maxLat) / 2;
    bounds.centerLat = viewport.latitude;

    mapDataReady = alignment.length > 0 ? true : false;
  }

  return {
    initialViewport: viewport,
    bounds,
    mapData,
    mapDataReady,
  };
};

export const centerMapToAlignment = (
  viewport: Viewport,
  bounds: Bounds,
  canvas: {
    width: number;
    height: number;
  },
): { zoom: number } => {
  const { zoom } = new WebMercatorViewport({
    zoom: viewport.zoom,
    width: canvas.width,
    height: canvas.height,
  }).fitBounds(
    [
      [bounds.minLong, bounds.minLat],
      [bounds.maxLong, bounds.maxLat],
    ],
    { padding: 80 },
  );
  return { zoom };
};

export const cookiesAndTracking = (posthogInstance: typeof posthog): void => {
  posthogInstance.opt_in_capturing();
  posthogInstance.set_config({ disable_persistence: false });
  posthogInstance.capture("Cookies and tracking enabled", {
    property: "Cookies and tracking enabled",
  });
};

export const trackingOnly = (posthogInstance: typeof posthog): void => {
  posthogInstance.opt_in_capturing();
  posthogInstance.set_config({ disable_persistence: true });
  posthogInstance.capture("No cookies, tracking only", {
    property: "No cookies, tracking only",
  });
};

export const disableCookiesAndTracking = (
  posthogInstance: typeof posthog,
): void => {
  posthogInstance.opt_out_capturing();
};

export const transformMapAccordionData = (accessPoint: Feature): row[] => {
  if (accessPoint.properties.type === "shaft") {
    return [
      {
        name: "Gate number",
        value: accessPoint.properties.gateNumber
          ? accessPoint.properties.gateNumber
          : "N/A",
      },
      {
        name: "ELR",
        value: accessPoint.properties.elr ? accessPoint.properties.elr : "N/A",
      },
      {
        name: "Track ID",
        value: accessPoint.properties.trackId
          ? accessPoint.properties.trackId
          : "N/A",
      },
      {
        name: "Kp Point",
        value: accessPoint.properties.kpPoint
          ? accessPoint.properties.kpPoint
          : "N/A",
      },
      {
        name: "Location",
        value: accessPoint.properties.location
          ? accessPoint.properties.location
          : "N/A",
      },
      {
        name: "OS grid reference",
        value: accessPoint.properties.osGridReference
          ? accessPoint.properties.osGridReference
          : "N/A",
      },
      {
        name: "Access to",
        value: accessPoint.properties.accessTo
          ? accessPoint.properties.accessTo
          : "N/A",
      },
      {
        name: "Gate type",
        value: accessPoint.properties.gateType
          ? accessPoint.properties.gateType
          : "N/A",
      },
      {
        name: "Access type",
        value: accessPoint.properties.accessType
          ? accessPoint.properties.accessType
          : "N/A",
      },
      {
        name: "Lock",
        value: accessPoint.properties.lock
          ? accessPoint.properties.lock
          : "N/A",
      },
    ];
  } else if (accessPoint.properties.type === "asset-alignment") {
    return [
      {
        name: "Asset name",
        value: accessPoint.properties.asset
          ? accessPoint.properties.asset
          : "N/A",
      },
      {
        name: "Section Name",
        value: accessPoint.properties.title
          ? accessPoint.properties.title
          : "N/A",
      },
      {
        name: "Start panel",
        value: accessPoint.properties.startRingNumber
          ? accessPoint.properties.startRingNumber
          : "N/A",
      },
      {
        name: "End panel",
        value: accessPoint.properties.endRingNumber
          ? accessPoint.properties.endRingNumber
          : "N/A",
      },
      {
        name: "Start chainage",
        value: accessPoint.properties.startChainage
          ? accessPoint.properties.startChainage
          : "N/A",
      },
      {
        name: "End chainage",
        value: accessPoint.properties.endChainage
          ? accessPoint.properties.endChainage
          : "N/A",
      },
    ];
  } else {
    return [{ name: "", value: "" }];
  }
};

export const capitaliseFirstLetter = (
  item: string | number | null,
): string | number | null => {
  return typeof item !== "string"
    ? item
    : item.charAt(0).toUpperCase() + item.slice(1);
};

export const formatDate = (
  item: string | number | null,
): string | number | null => {
  if (typeof item === "string") {
    return String(moment(new Date(item)).format("DD-MM-YYYY"));
  } else {
    return item;
  }
};

export const formatRingRange = (
  start_ring: string | number | null,
  end_ring: string | number | null | undefined,
): string => {
  return (
    String(start_ring) +
    " to " +
    String(Number(start_ring) + Number(end_ring) - 1)
  );
};
