import styled from "styled-components";
import { Paper, TextField, Typography } from "@mui/material";
import Chevron from "./Chevrons.svg";

export const Wrapper = styled(Paper)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: 2rem;
  margin: 1rem;
  pointer-events: auto;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 0.5rem;
`;

export const TextFieldWrapper = styled(TextField)<{
  style: {
    "--border-color": string;
  };
}>((props) => ({
  width: "4rem",
  "margin-bottom": "0.3rem !important",
  "& label.Mui-focused": {
    color: "var(--border-color)",
    borderWidth: 1,
  },
  "& .MuiOutlinedInput-root": {
    color: props.theme.custom.palette.text,
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-color)",
      borderWidth: 1,
    },
  },
}));

export const RagDiv = styled.div<{
  style: {
    "--background-color": string;
    "--left": string;
    "--width": string;
  };
}>`
  position: absolute;
  left: var(--left);
  top: 17px;
  background-color: var(--background-color);
  height: 25px;
  width: var(--width);
`;
export const RagSvg = styled.svg`
  position: absolute;
  top: 1.5rem;
  background-color: var(--background-color);
  width: calc(100% - 8rem);
  right: 0;
  margin-right: 2rem;
  border-width: 0.125rem;
`;

export const GapDiv = styled.div<{
  style: {
    "--left": string;
    "--width": string;
  };
}>`
  position: absolute;
  left: var(--left);
  top: 1.5rem;
  background-image: url(${Chevron});
  height: 25px;
  width: var(--width);
  background-repeat: repeat-x;
  background-position: 300px;
  background-color: white;
`;
export const Slider = styled.input`
  -webkit-appearance: none;
  outline: none;
  width: 100%;
  height: 2rem;
  margin: 1rem;
  margin-top: 1.5rem;
  background: transparent;
  border-radius: 0.5rem;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  z-index: 1;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    border-width: 0.125rem;
    background-color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border-color: white;
    border-width: 0.125rem;
    background-color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }
`;

export const TypographyWrapper = styled(Typography)`
  font-size: 0.75rem !important;
  width: 4.5rem;
  margin-bottom: 3px !important;
  font-weight: 400 !important;
  color: ${(props) => props.theme.custom.palette.text} !important;
`;

export const RingInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StartingLocation = styled.div`
  margin-top: 0.25rem;
  font-size: 0.825rem;
  position: absolute;
  left: 5.5rem;
  color: ${(props) => props.theme.custom.palette.text};
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EndLocation = styled.div`
  margin-top: 0.25rem;
  font-size: 0.825rem;
  position: absolute;
  color: ${(props) => props.theme.custom.palette.text};
  right: 1rem;
  display: flex;
  flex-direction: row;
  top: 0;
  align-items: center;
`;
