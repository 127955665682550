import React, { useState, useRef, useEffect } from "react";
import {
  Wrapper,
  TextFieldWrapper,
  Slider,
  TypographyWrapper,
  RingInput,
  EndLocation,
  StartingLocation,
} from "./RagSlider.styles";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_display_ring_number } from "redux/TunnelData";
import { useTheme } from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip, tooltipsContent } from "components";
import RagBar from "./RagBar";
import { useTunnel } from "queries";

export const RagSlider: React.FunctionComponent = () => {
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);

  const displayTunnelData = useAppSelector((state) => state.TunnelData.display);
  const dispatch = useAppDispatch();
  const { tunnel } = useTunnel();

  const [inputText, setInputText] = useState<string>(
    `${displayTunnelData.ringNumber}`,
  );

  useEffect(() => {
    setInputText(`${displayTunnelData.ringNumber}`);
  }, [displayTunnelData.ringNumber]);

  const [error, setError] = useState<boolean>(false);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === "0" || Number(newValue) || !newValue.replace(" ", "")) {
      setError(false);
      setInputText(newValue);
    }
  };

  const handleTextSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      displayTunnelData.minRingNumber <= Number(inputText) &&
      Number(inputText) <= displayTunnelData.maxRingNumber
    ) {
      setRingNumber(inputText);
    } else {
      setError(true);
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setRingNumber(newValue);
  };

  const setRingNumber = (newRingNumberStr: string) => {
    const newRingNumber = Number(newRingNumberStr);
    if (newRingNumber === 0 || newRingNumber) {
      dispatch(set_display_ring_number(newRingNumber));
      setInputText(newRingNumberStr);
    }
  };

  return (
    <Wrapper>
      <RingInput>
        <TypographyWrapper>Panel</TypographyWrapper>
        <StartingLocation>
          <ArrowBackIcon
            style={{
              fontSize: "0.825rem",
              marginRight: "0.5rem",
              color: theme.custom.palette.text,
            }}
          />
          {tunnel?.start_terminus}
        </StartingLocation>

        <EndLocation>
          {tunnel?.end_terminus}
          <ArrowForwardIcon
            style={{
              fontSize: "0.825rem",
              color: theme.custom.palette.text,
              marginLeft: "0.5rem",
            }}
          />
        </EndLocation>
        <form onSubmit={handleTextSubmit}>
          <Tooltip
            title={`Panel Range: ${displayTunnelData.minRingNumber}-${displayTunnelData.maxRingNumber}`}
            placement="top"
          >
            <TextFieldWrapper
              value={inputText}
              onChange={handleTextChange}
              variant="outlined"
              size="small"
              inputProps={{ style: { height: "0.8rem" } }}
              style={{
                "--border-color": error ? "red" : theme.palette.primary.main,
              }}
            />
          </Tooltip>
        </form>
      </RingInput>
      <>
        <Tooltip
          title={tooltipsContent.slider}
          placement="top"
          followCursor={true}
        >
          <Slider
            ref={ref}
            type="range"
            min={displayTunnelData.minRingNumber}
            max={displayTunnelData.maxRingNumber}
            value={displayTunnelData.ringNumber}
            onChange={handleSliderChange}
          ></Slider>
        </Tooltip>
        <RagBar />
      </>
    </Wrapper>
  );
};
