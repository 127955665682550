import { CLIENT_ID, TENANT_ID } from "utils/config";
import { Configuration } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
// Authentication Parameters
export const authenticationParameters = {
  scopes: ["openid", "profile", "User.Read", "email"],
};

export const authProvider: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${TENANT_ID}/`,
    clientId: CLIENT_ID,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        //   default:
        //     return;
        // }
      },
    },
  },
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
