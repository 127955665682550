import styled from "styled-components";
import { CircularProgress, Typography, IconButton } from "@mui/material";
import { Replay } from "@mui/icons-material";

export const Container = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
`;

export const CircularProgressWrapper = styled(CircularProgress)`
  display: block;
  color: white;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const IconButtonWrapper = styled(IconButton)`
  display: block;
  color: white;
  height: 3rem;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const TypographyWrapper = styled(Typography)`
  display: block;
  padding: 1.25rem;
  color: white;
`;

export const ReplayIcon = styled(Replay)`
  width: 3rem;
  height: 3rem;
`;
