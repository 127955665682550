import styled from "styled-components";
import { IconButton, MobileStepper, Paper } from "@mui/material";

export const ExpandButton = styled(IconButton)`
  color: white;
  & > svg {
    font-size: 0.875rem;
    color: white;
  }
`;

export const TextSpan = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${(props) =>
    props.theme.custom.palette.backgroundButtonColor};
  width: calc(100% - 1rem);
  color: white;
  height: 2rem;
  font-size: 0.625rem;
  padding-left: 1rem;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledModal = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  padding: 1rem;
  box-shadow: 0px 4px 4px ${(props) => props.theme.custom.palette.boxShadow},
    0px 4px 5px ${(props) => props.theme.custom.palette.boxShadowLighter};
`;

export const Stepper = styled(MobileStepper)`
  color: ${(props) => props.theme.custom.palette.text};
  background: ${(props) => props.theme.custom.palette.truePaper};
  font-size: 0.875rem;
`;
