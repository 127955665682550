import { createSlice } from "@reduxjs/toolkit";
import { Feature } from "types";

export interface MapSideInfoState {
  accessPoint: Feature | null;
}

const initialState: MapSideInfoState = {
  accessPoint: null,
};

export const MapSideInfo = createSlice({
  name: "MapSideInfo",
  initialState,
  reducers: {
    set_access_point: (state, action) => {
      return { accessPoint: action.payload };
    },
  },
});

export const { set_access_point } = MapSideInfo.actions;

export default MapSideInfo.reducer;
