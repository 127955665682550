import styled from "styled-components";
import { Paper, IconButton } from "@mui/material";
import { Responsive } from "react-grid-layout";

export const ResponsiveGrid = styled(Responsive)`
  & .react-grid-placeholder {
    background-color: ${(props) => props.theme.custom.palette.background};
    filter: brightness(
      ${(props) => (props.theme.palette.mode === "light" ? 85 : 200)}%
    );
    border-radius: 0.5rem;
  }

  & .react-grid-item {
    transition: none;
  }

  transition: none;
  min-height: 100%;
  max-height: 100%;
  margin-right: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.scrollbar};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.scrollbarHover};
  }
`;

export const WidgetWrapper = styled.div<{
  style: {
    "--box-shadow": string;
  };
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow) !important;
`;

export const RemoveItemButton = styled(IconButton)`
  position: absolute !important;
  top: 0;
  right: 0;
`;

export const ComponentPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 0.5 rem !important;
`;

export const Title = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0rem;
  color: ${(props) => props.theme.custom.palette.subTitle};
`;
