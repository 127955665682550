import React from "react";
import { Theme, Tooltip as BaseTooltip, TooltipProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.custom.palette.tooltip,
  },
  tooltipRight: {
    backgroundColor: theme.custom.palette.tooltip,
    position: "relative",
    left: "-0.75rem",
  },
  tooltipLeft: {
    backgroundColor: theme.custom.palette.tooltip,
    position: "relative",
    right: "-0.75rem",
  },
}));

export const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
  const classes = useStyles();
  const tooltipByPlacement = () => {
    if (props.placement === "right") {
      return classes.tooltipRight;
    } else if (props.placement === "left") {
      return classes.tooltipLeft;
    }
    return classes.tooltip;
  };

  return (
    <BaseTooltip
      {...props}
      componentsProps={{
        tooltip: {
          className: tooltipByPlacement(),
        },
      }}
    ></BaseTooltip>
  );
};
