import styled from "styled-components";
import { Checkbox, Typography } from "@mui/material";

export const Container = styled("div")`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const CheckboxContainer = styled("div")`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-right: 2px;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0rem !important;
  margin-right: 0.5rem !important;
`;

export const CheckboxText = styled(Typography)`
  font-size: 14px;
  color: ${(props) => props.theme.custom.palette.text};
`;
