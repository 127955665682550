import styled from "styled-components";
import Button from "@mui/material/Button";

export const Heading = styled.div`
  margin: 1rem;
  margin-bottom: 0.5rem;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.custom.palette.title};
`;

export const SideMarginFlexDiv = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
`;

export const PaddedDiv = styled.div`
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    margin-right: 0.5rem;
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
  }
`;

export const DetailDiv = styled.div`
  color: ${(props) => props.theme.custom.palette.text};
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0;
  margin-top: 0.5rem;
`;

export const DateText = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-top: 1rem;
  color: ${(props) => props.theme.custom.palette.subTitle};
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main}22;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 0.625rem;
  text-decoration: none;
  text-transform: none;
  box-shadow: none !important;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main}44;
  }
`;

export const Group = styled("div")`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;

export const SubText = styled("div")`
  font-weight: 700;
  padding-top: 0.5rem;
  padding-left: 0.2vw;
  font-size: 14px;
  color: ${(props) => props.theme.custom.palette.foreground};
`;
