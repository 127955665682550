import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";
import {
  Container,
  DropzoneContainer,
  Image,
  Placeholder,
  PlaceholderContent,
  PlaceholderText,
  FileUploadIcon,
  UploadContainer,
  UploadElement,
  UploadButton,
  UploadStatusText,
  WarningText,
} from "./FileUpload.styles";
import { Hint } from "components";
import { UploadedFile } from "types";

type Props = {
  files: UploadedFile[];
  setFiles: (_: UploadedFile[]) => void;
  dropzone?: boolean;
  multipleFiles?: boolean;
  acceptedFileTypes?: string[];
  hint?: string;
  errorMessage?: string;
  children: React.ReactNode;
};

export const FileUpload: React.FunctionComponent<Props> = ({
  files,
  setFiles,
  dropzone,
  multipleFiles,
  acceptedFileTypes,
  hint,
  errorMessage,
  children,
}) => {
  const inputElement = useRef<HTMLInputElement | null>(null);
  const [statusText, setStatusText] = useState<string>("");

  const handleChange = (newFiles: File[] | null) => {
    if (newFiles) {
      let newStatusText: string | null = null;
      const uploadedFiles = [];
      for (let index = 0; index < newFiles.length; index++) {
        const newFile = newFiles[index];
        if (acceptedFileTypes && !acceptedFileTypes.includes(newFile.type)) {
          newStatusText = "Unsupported file type!";
          break;
        }
        uploadedFiles.push({
          url: dropzone ? URL.createObjectURL(newFile) : "",
          file: newFile,
        });
      }
      setFiles(newStatusText ? [] : uploadedFiles);
      setStatusText(
        newStatusText
          ? newStatusText
          : `${newFiles.length} file${
              newFiles.length > 1 ? "s" : ""
            } uploaded!`,
      );
    }
  };

  return (
    <Container>
      {dropzone && (
        <DropzoneContainer>
          <Dropzone onDrop={(acceptedFile) => handleChange(acceptedFile)}>
            {({ getRootProps, getInputProps }) => (
              <Placeholder {...getRootProps()}>
                <input {...getInputProps()} multiple={multipleFiles} />
                <PlaceholderContent>
                  <FileUploadIcon fontSize="small" />
                  <PlaceholderText>{children}</PlaceholderText>
                </PlaceholderContent>
              </Placeholder>
            )}
          </Dropzone>
          {!multipleFiles && files[0] && <Image src={files[0].url}></Image>}
        </DropzoneContainer>
      )}
      <UploadContainer>
        <label>
          <UploadElement
            ref={inputElement}
            type="file"
            multiple={multipleFiles}
            onChange={(event) =>
              handleChange(event.target.files && Array.from(event.target.files))
            }
          />
          <UploadButton
            disableElevation
            color="secondary"
            variant="contained"
            onClick={() => inputElement.current?.click()}
          >
            {children}
          </UploadButton>
        </label>
        {hint && <Hint message={hint} />}
        <UploadStatusText>{statusText}</UploadStatusText>
      </UploadContainer>
      <WarningText>{errorMessage ? errorMessage : ""}</WarningText>
    </Container>
  );
};
