import { layouts } from "types";

export const initialLayouts: layouts = {
  lg: [
    {
      i: "TunnelHealth",
      w: 3,
      h: 1,
      x: 0,
      y: 2,
      isResizable: false,
    },
    {
      i: "TunnelTable",
      w: 3,
      h: 2,
      x: 0,
      y: 0,
      isResizable: false,
    },
    {
      i: "Map",
      w: 3,
      h: 2,
      x: 0,
      y: 3,
      minW: 2,
      minH: 2,
      maxW: 4,
      maxH: 4,
    },
    {
      i: "Viewer3D",
      w: 6,
      h: 3,
      x: 4,
      y: 0,
      minW: 3,
      minH: 2,
      maxW: 6,
      maxH: 4,
    },
    {
      i: "BarChart",
      w: 6,
      h: 2,
      x: 4,
      y: 5,
      minW: 3,
      minH: 2,
      maxW: 6,
      maxH: 3,
    },
  ],
}; // Will be stored in database in the future.
