import styled from "styled-components";
import { HeadingM } from "utils/fonts.styles";

export const PortalPlaceholder = styled.div`
  background-color: ${(props) => props.theme.custom.palette.truePaper};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 4rem;
  margin-bottom: 5px;
`;

export const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0;
  align-items: center;
  height: 4rem;
  z-index: 900;
  margin-bottom: 5px;
  position: absolute;
  right: 0;
  top: 0;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

export const UserTabsWrapper = styled.div`
  float: right;
  margin-right: 0.5vw;
`;

export const Heading = styled(HeadingM)`
  max-width: 40vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.theme.custom.palette.title};
  margin-top: 0px;
  margin-bottom: 4px;
`;

export const Highlight = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const FlexHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const LargeLogo = styled.img`
  height: 2rem;
  margin-left: 31px;
`;

export const LogoContainer = styled.div`
  width: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
