import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { setUserData } from "auth/setUserData";
import { useMsal } from "@azure/msal-react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/system";
import themes from "utils/theme";
import { Viewer3D } from "components";
import { set_session_data } from "redux/SessionData";
import { set_settings } from "redux/Settings";
import { getUser } from "services/user";
import Pages from "pages";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import posthog from "posthog-js";

posthog.init(process.env.REACT_APP_POSTHOG_TOKEN as string, {
  api_host: "https://posthog.insights.arup.com",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent = () => {
  const { instance, accounts } = useMsal();
  const [time, setTime] = useState(Date.now());
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.Settings.theme);
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );

  useEffect(() => {
    const interval = setInterval(
      () => setTime(Date.now()),
      0.8 * 60 * 60 * 1000,
    );
    setUserData(dispatch, instance, accounts);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, time]);

  useEffect(() => {
    if (accessToken) {
      updateSessionData();
    }
  }, [accessToken]);

  const updateSessionData = async () => {
    const data = await getUser(accessToken);
    if (data) {
      dispatch(set_session_data(data.permissions));
      dispatch(set_settings(data.settings));
      redirectUser(data.permissions.origin);
    }
  };

  const redirectUser = (origin: string) => {
    if (
      (process.env.REACT_APP_DEVELOPMENT_STAGE === "production" ||
        process.env.REACT_APP_DEVELOPMENT_STAGE === "development") &&
      origin !== window.location.origin
    ) {
      window.location.replace(`${origin}${window.location.pathname}`);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={themes[theme]}>
        <StyledThemeProvider theme={themes[theme]}>
          <QueryClientProvider client={queryClient}>
            {accessToken && (
              <Router>
                <div>
                  <Pages />
                </div>
                <Viewer3D />
              </Router>
            )}
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
