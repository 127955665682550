import { createTheme } from "@mui/material/styles";

export interface MuiThemeExtension {
  custom: {
    palette: {
      foreground: string;
      background: string;
      truePaper: string;
      title: string;
      subTitle: string;
      text: string;
      tooltip: string;
      link: string;
      border: string;
      goodCondition: string;
      mediumCondition: string;
      badCondition: string;
      sliderThumb: string;
      watermark: string;

      sideBarTable: string;
      backgroundButtonColor: string;
      scrollbar: string;
      scrollbarHover: string;

      boxShadow: string;
      boxShadowLighter: string;
    };
    media: {
      arupLogo: string;
    };
  };
}

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1E98D7",
    },
    secondary: {
      main: "rgba(219, 219, 219, 1)",
    },
  },
  custom: {
    palette: {
      foreground: "rgba(118, 118, 118, 1)",
      background: "#EFEFEF",
      truePaper: "#FFFFFF",
      title: "rgba(28, 28, 28, 1)",
      subTitle: "#424242",
      text: "#353535",
      tooltip: "rgba(53, 53, 53, 1)",
      link: "#005AAA",
      border: "rgba(0, 0, 0, 0.12)",
      goodCondition: "rgba(104, 193, 99, 1)",
      mediumCondition: "rgba(255, 223, 76, 1)",
      badCondition: "rgba(242, 69, 77, 1)",
      sliderThumb: "rgba(66, 66, 66, 1)",
      watermark: "rgba(215, 215, 215, 1)",

      boxShadow: "rgba(0, 0, 0, 0.25)",
      boxShadowLighter: "rgba(0, 0, 0, 0.15)",

      sideBarTable: "rgba(219, 219, 219, 1)",
      backgroundButtonColor: "rgba(0, 0, 0, 0.6)",
      scrollbar: "rgba(118, 118, 118, 1)",
      scrollbarHover: "rgba(130, 130, 130, 1)",
    },
    media: {
      arupLogo: "/logos/loupe360_logo_dark.png",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1E98D7",
    },
    secondary: {
      main: "rgba(70, 70, 70, 1)",
    },
    background: {
      paper: "#1D1D1D",
    },
  },
  custom: {
    palette: {
      foreground: "#DFDFDF",
      background: "#1A1A1A",
      truePaper: "#282828",
      title: "#FFFFFF",
      subTitle: "#E4E4E4",
      text: "#DDDDDD",
      tooltip: "rgba(53, 53, 53, 1)",
      link: "#005AAA",
      border: "rgba(0, 0, 0, 0.12)",
      goodCondition: "rgba(104, 193, 99, 1)",
      mediumCondition: "rgba(255, 223, 76, 1)",
      badCondition: "rgba(242, 69, 77, 1)",
      sliderThumb: "rgba(255, 255, 255, 1)",
      watermark: "rgba(75, 75, 75, 1)",

      boxShadow: "rgba(0, 0, 0, 0.25)",
      boxShadowLighter: "rgba(0, 0, 0, 0.15)",

      sideBarTable: "rgba(219, 219, 219, 1)",
      backgroundButtonColor: "rgba(0, 0, 0, 0.6)",

      scrollbar: "rgba(118, 118, 118, 1)",
      scrollbarHover: "rgba(130, 130, 130, 1)",
    },
    media: {
      arupLogo: "/logos/loupe360_logo_light.png",
    },
  },
});

export default {
  light: lightTheme,
  dark: darkTheme,
};
