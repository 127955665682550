import * as d3 from "d3";
import React, { useEffect } from "react";
import { rag, ragRatings, ragWithRanges } from "types";
import { RagSvg } from "./RagSlider.styles";
import { useRagRating } from "queries";

const colorMap = {
  green: "rgba(104, 193, 99, 1)",
  yellow: "rgba(255, 223, 76, 1)",
  red: "rgba(242, 69, 77, 1)",
  white: "white",
};

const RagBar: React.FunctionComponent = () => {
  const { ragRatings } = useRagRating(1);

  useEffect(() => {
    if (ragRatings) {
      const accumulatorByColor = ragRatings.reduce(
        (acc: ragRatings, current, index) => {
          if (index + 1 < ragRatings.length) {
            if (ragRatings[index + 1].color !== current.color) {
              acc = [...acc, current];
            }
          } else {
            acc = [...acc, current];
          }
          return acc;
        },
        [],
      );
      const mapByColorRanges = accumulatorByColor.map((d: rag, i: number) => {
        const start = i <= 0 ? 0 : accumulatorByColor[i - 1].ring_number;
        const mapped = {
          color: d.color,
          range: { start, end: d.ring_number },
        };
        return mapped;
      });

      if (ragRatings.length > 0) {
        const svgContainer = d3
          .select(".svg-container")
          .attr("viewBox", [0, 0, ragRatings.length - 1, 30])
          .attr("height", "30")
          .attr("preserveAspectRatio", "none");

        svgContainer
          .append("g")
          .selectAll(".bar")
          .data(mapByColorRanges)
          .join("rect")
          .attr("class", "bar")
          .attr("x", (data: ragWithRanges) => data.range.start)
          .attr(
            "width",
            (data: ragWithRanges) => data.range.end - data.range.start,
          )
          .attr("height", "25")
          .attr(
            "fill",
            (data: ragWithRanges) =>
              colorMap[data.color as keyof typeof colorMap],
          );
      }
    }
  }, [ragRatings]);
  return <RagSvg className="svg-container" />;
};

export default RagBar;
