import React, { useEffect, Dispatch, SetStateAction } from "react";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { StyledDrawer, StyledExpandButton } from "./SideInfoPanel.styles";
import Portal from "@mui/material/Portal";
import { useAppSelector } from "redux/hooks";
import { Tooltip, tooltipsContent } from "components";
import { useTheme } from "styled-components";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
};

export const SideInfoPanel: React.FunctionComponent<Props> = ({
  open,
  setOpen,
  children,
}) => {
  const theme = useTheme();
  const accessPoint = useAppSelector((state) => state.MapSideInfo.accessPoint);
  useEffect(() => {
    accessPoint !== null && accessPoint.type !== ""
      ? setOpen(true)
      : setOpen(false);
  }, [accessPoint]);

  return (
    <>
      <Portal>
        <StyledExpandButton
          size="large"
          open={open}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <Tooltip title={tooltipsContent.showHideInfoPanelRight}>
              <ArrowRight
                fontSize="large"
                htmlColor={theme.custom.palette.foreground}
              />
            </Tooltip>
          ) : (
            <Tooltip title={tooltipsContent.showHideInfoPanelLeft}>
              <ArrowLeft
                fontSize="large"
                htmlColor={theme.custom.palette.foreground}
              />
            </Tooltip>
          )}
        </StyledExpandButton>
      </Portal>
      <StyledDrawer variant="permanent" open={open}>
        {children}
      </StyledDrawer>
    </>
  );
};
