import React from "react";
import { Modal } from "@mui/material";
import { ModalBlueprint } from "components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_modal } from "redux/Modal";
import { updateSettings } from "services/setting";

export const ModalTemplate: React.FunctionComponent = () => {
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );
  const settings = useAppSelector((state) => state.Settings);
  const modal = useAppSelector((state) => state.Modal);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(set_modal());
    if (modal.type === "settings") {
      updateSettings(accessToken, { data: settings });
    }
  };

  return (
    <Modal open={modal.open} onClose={handleClose}>
      <>
        {modal.type && (
          <ModalBlueprint
            modalType={modal.type}
            startSubModal={modal.startPage}
            handleClose={handleClose}
          />
        )}
      </>
    </Modal>
  );
};
