import React from "react";
import { PageTemplate } from "components";
import DashboardPageTemplate from "components/templates/DashboardTemplate";

const Dashboard: React.FunctionComponent = () => {
  return (
    <PageTemplate pageType="sideBar">
      <DashboardPageTemplate />
    </PageTemplate>
  );
};

export default Dashboard;
