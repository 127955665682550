import styled from "styled-components";

export const BotBarWrapper = styled.div`
  margin: 0 16rem 0 14rem;
  height: 4rem;
  z-index: 100;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.h5`
  color: ${(props) => props.theme.custom.palette.text};
  letter-spacing: 0.25px;
  font-weight: 300 !important;
  font-family: Arial;
  font-style: normal;
`;

export const LinkText = styled.a`
  color: ${(props) => props.theme.custom.palette.link};
  letter-spacing: 0.15px;
  font-family: Roboto;
  font-style: normal;
  user-select: none;
  cursor: pointer;
  margin-left: 0.5rem;
  font-weight: normal !important;
`;
