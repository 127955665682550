import styled from "styled-components";
import { Divider } from "@mui/material";

export const Container = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
  height: "calc(100% - 40px)",
});

export const StyledDivider = styled(Divider)({
  height: "85% !important",
});
