import React from "react";
import { PageTemplate, Viewer3DArea, ViewerSideInfoPanel } from "components";
import { Wrapper } from "./Viewer.styles";

const Viewer: React.FunctionComponent = () => {
  return (
    <PageTemplate pageType="sideBar">
      <Wrapper>
        <Viewer3DArea fullscreen />
        <ViewerSideInfoPanel />
      </Wrapper>
    </PageTemplate>
  );
};

export default Viewer;
