import styled, { css } from "styled-components";
import Drawer from "@mui/material/Drawer";
import { IconButton } from "@mui/material";

interface Props {
  open: boolean;
}

export const LargeLogo = styled.img<Props>`
  position: absolute;
  height: 2rem;

  ${({ open }) =>
    !open &&
    css`
      clip: rect(0px, 35px, 100px, 0px);
      margin-left: 11px;
      padding-left: 0;
      object-fit: cover;
      object-position: -20% 0;
    `}
`;

export const ImageHolder = styled.div<Props>`
  box-shadow: 0px 4px 4px ${(props) => props.theme.custom.palette.boxShadow};
  height: 4rem;
  margin-bottom: 1.5rem;
  align-items: center;
  display: flex;
  ${({ open }) =>
    open &&
    css`
      justify-content: center;
    `}
`;

export const StyledDrawer = styled(Drawer)<Props>`
  & .MuiDrawer-paper {
    background-color: ${(props) => props.theme.custom.palette.truePaper};
    position: relative;
    box-shadow: 0px 4px 4px ${(props) => props.theme.custom.palette.boxShadow};
    white-space: nowrap;
    overflow: hidden;
    width: 240px;
    min-height: 100vh;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    box-sizing: border-box;
    ${({ open }) =>
      !open &&
      css`
        overflow-x: hidden;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        width: 56px;
      `}
  }
`;

export const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.custom.palette.foreground};
`;
