import { Typography } from "@mui/material";
import styled from "styled-components";

export const CardCollectionWrapper = styled.div<{
  style: {
    "--column-count": number;
    "--column-width": string;
  };
}>`
  display: grid;
  flex-direction: row;
  margin: 2rem 13rem 2rem 13rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  min-height: calc(100% - 9rem);
  max-height: calc(100% - 9rem);
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      clamp(100%/ (var(--column-count) + 1) + 0.1%, var(--column-width), 100%),
      1fr
    )
  );
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.scrollbar};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.scrollbarHover};
  }
`;

export const NoDataText = styled(Typography)`
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`;
