import styled from "styled-components";
import { TextField } from "@mui/material";

export const Container = styled("div")`
  display: flex;
`;

export const Input = styled(TextField)({
  marginLeft: "1rem !important",
  marginTop: "1rem !important",
  marginBottom: "0rem !important",
  "& .MuiFormHelperText-root": {
    lineHeight: "0.75rem !important",
    marginTop: "0.25rem !important",
  },
});
