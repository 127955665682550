import React from "react";
import {
  MapMol,
  MapShafts,
  MapAlignment,
  Wrapper,
  VerticalButtonGroup,
} from "components";
import { MapRenderInfo } from "types";

import { transformMapDataForPlotting } from "utils/helpers";

import { buttonsConfig } from "./controlsConfig";

export const MapFullPage: React.FunctionComponent<MapRenderInfo> = ({
  mapData,
}) => {
  const { alignment, shafts } = transformMapDataForPlotting(mapData);
  return (
    <Wrapper>
      <MapMol mapData={mapData}>
        <MapAlignment alignment={alignment} />
        <MapShafts shafts={shafts} />
      </MapMol>
      <VerticalButtonGroup buttonsConfig={buttonsConfig} />
    </Wrapper>
  );
};
