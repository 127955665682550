import styled from "styled-components";

export const SideBarWrapper = styled.div`
  background-color: ${(props) => props.theme.custom.palette.background};
  display: flex;
`;

export const TopBarWrapper = styled.div`
  background-color: ${(props) => props.theme.custom.palette.background};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

export const SideBarBodyWrapper = styled.div`
  width: 100%;
  z-index: 1;
`;

export const SideBarComponentContainer = styled.div`
  height: calc(100vh - 4rem - 5px - 0.25rem);
`;

export const TopBarComponentContainer = styled.div`
  height: calc(100vh - 4rem - 5px - 4rem);
`;
