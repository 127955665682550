import React, { useState, useEffect } from "react";
import { UnityContext } from "react-unity-webgl";
import { StyledUnity, Frame, ControlsContainer } from "./Viewer3D.styles";
import { useLocation } from "react-router-dom";
import { locationPathSplit } from "utils/helpers";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_progress, set_loaded, set_comparison } from "redux/Viewer3DStatus";
import { ComponentDimensions } from "types";
import { MinimizedControls } from "./MinimizedControls";
import { MaximizedControls } from "./MaximizedControls";
import ViewerInstance, {
  handleSetServicesConfig,
  exitFullscreen,
} from "services/unity";
import { useTunnel } from "queries";

export const unityContext = new UnityContext({
  loaderUrl: window.location.origin + "/unity_build/Build.loader.js",
  dataUrl: window.location.origin + "/unity_build/Build.data",
  frameworkUrl: window.location.origin + "/unity_build/Build.framework.js",
  codeUrl: window.location.origin + "/unity_build/Build.wasm",
});

export type ViewerInstances = {
  main: ViewerInstance | null;
  compare: ViewerInstance | null;
};

export const viewerInstances: ViewerInstances = {
  main: null,
  compare: null,
};

const viewer3DPages = ["dashboard", "inspector"];

export const Viewer3D: React.FunctionComponent = () => {
  const { tunnel } = useTunnel();
  const [loaded, setLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );
  const location = useLocation();
  const pageName = locationPathSplit(location.pathname);
  const areaRendered = useAppSelector((state) => state.Viewer3DStatus.shown);
  const frameDimensions: ComponentDimensions = useAppSelector((state) => {
    const newFrameDimensions =
      pageName === "dashboard"
        ? state.Viewer3DStatus[pageName].dimensions
        : pageName === "inspector"
        ? state.Viewer3DStatus[pageName].dimensions
        : { width: 0, height: 0, top: 0, left: 0, bottom: 0, right: 0 };
    return newFrameDimensions;
  });

  const closeComparison = () => {
    viewerInstances?.main?.setPolyLines(false);
    viewerInstances?.main?.setSecondaryImage(false);
    viewerInstances?.main?.setOriginalBoundingBoxes(false);
    dispatch(set_comparison(false));
  };

  useEffect(() => {
    if (pageName !== "inspector") {
      closeComparison();
    }
    pageName === "inspector"
      ? exitFullscreen()
      : viewerInstances.main
      ? viewerInstances.main.setFullscreen()
      : exitFullscreen();
  }, [location]);

  useEffect(() => {
    unityContext.on("progress", (progression) => {
      dispatch(set_progress(Math.round(progression * 100)));
    });
    unityContext.on("quitted", () => {
      setLoaded(false);
      dispatch(set_loaded(false));
      dispatch(set_progress(0));
    });
    unityContext.on("OnStart", () => {
      handleSetServicesConfig("");
      viewerInstances.main = new ViewerInstance("main");
      setLoaded(true);
      dispatch(set_loaded(true));
    });
  }, [tunnel?.id]);

  useEffect(() => {
    handleSetServicesConfig(accessToken);
    if (loaded && tunnel && tunnel.id && viewerInstances.main) {
      viewerInstances.main.setTunnel(
        tunnel.id,
        tunnel.actual_start_ringnumber,
        tunnel.total_rings,
        tunnel.display_start_ringnumber,
        0,
        tunnel.image_rotation,
        tunnel.show_wall_numbers,
      );
    }
  }, [loaded, tunnel?.id]);

  useEffect(() => {
    handleSetServicesConfig(accessToken);
  }, [accessToken]);

  return (
    <>
      <StyledUnity
        tabIndex={1}
        unityContext={unityContext}
        matchWebGLToCanvasSize={true}
        style={{
          "--display":
            viewer3DPages.includes(pageName) && loaded && areaRendered
              ? "inline"
              : "none",
          "--border-top": pageName === "dashboard" ? "0rem" : "0.5rem",
          ...frameDimensions,
        }}
      />
      {viewer3DPages.includes(pageName) && loaded && areaRendered && (
        <Frame
          style={{
            ...frameDimensions,
          }}
        >
          <ControlsContainer>
            {pageName === "dashboard" ? (
              <MinimizedControls />
            ) : pageName === "inspector" ? (
              <MaximizedControls />
            ) : null}
          </ControlsContainer>
        </Frame>
      )}
    </>
  );
};
