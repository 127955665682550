import styled from "styled-components";
import { TextField, MenuItem } from "@mui/material";

export const Container = styled("div")`
  display: flex;
`;

export const Input = styled(TextField)`
  width: 100%;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const InputItem = styled(MenuItem)``;
