import React from "react";
import { Container, TitleText } from "./MapStyle.styles";
import { RadioGroup } from "components";
import { radioConfig } from "./config";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { set_map_style } from "redux/Settings";

export const MapStyle: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { mapStyle } = useAppSelector((state) => state.Settings);

  const handleMapStyleChange = (value: string | number) => {
    dispatch(set_map_style(String(value)));
  };
  return (
    <Container>
      <TitleText>Map Style</TitleText>
      <RadioGroup
        config={radioConfig}
        initialValue={mapStyle}
        onChange={handleMapStyleChange}
      ></RadioGroup>
    </Container>
  );
};
