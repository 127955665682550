import { MapLayer, MapSource } from "components";
import { FeatureCollection } from "types";
import { Tooltip, tooltipsContent } from "components";

export const MapShafts: React.FunctionComponent<{
  shafts: FeatureCollection;
}> = ({ shafts }) => {
  const sourceId = "tunnel-shafts";
  return (
    <Tooltip title={tooltipsContent.accessLocationPointOnMap}>
      <>
        <MapSource key={sourceId} id={sourceId} type="geojson" data={shafts}>
          <MapLayer
            key={"tunnel-shafts-layer"}
            id="map-shafts-layer"
            type="circle"
            source={sourceId}
            paint={{
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                8,
                5,
                18,
                12,
              ],
              "circle-color": "#7a34eb",
            }}
            layout={{ visibility: "visible" }}
          ></MapLayer>
        </MapSource>
      </>
    </Tooltip>
  );
};
