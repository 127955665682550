import React from "react";
import Divider from "@mui/material/Divider";
import { BotBarWrapper, TextWrapper, Text, LinkText } from "./BottomBar.styles";
import { useAppDispatch } from "redux/hooks";
import { set_modal_type, set_modal, set_modal_start_page } from "redux/Modal";

export const BottomBar: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  return (
    <BotBarWrapper>
      <Divider />
      <TextWrapper>
        <Text>© Ove Arup, Ltd. 2022. We shape a better world.</Text>
        <Text>
          <LinkText
            onClick={() => {
              dispatch(set_modal());
              dispatch(set_modal_type("help"));
              dispatch(set_modal_start_page("terms_and_conditions"));
            }}
          >
            Terms & Conditions
          </LinkText>
        </Text>
        <Text>
          <LinkText
            onClick={() => {
              dispatch(set_modal());
              dispatch(set_modal_type("help"));
              dispatch(set_modal_start_page("cookie_settings"));
            }}
          >
            Cookie settings
          </LinkText>
          <LinkText
            onClick={() => {
              dispatch(set_modal());
              dispatch(set_modal_type("help"));
              dispatch(set_modal_start_page("cookie_policy"));
            }}
          >
            Cookie policy
          </LinkText>
        </Text>
      </TextWrapper>
    </BotBarWrapper>
  );
};
