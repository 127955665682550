import React from "react";
import { PageTemplate } from "components";
import { FigmaContainer } from "./Analytics.styles";

const Analytics: React.FunctionComponent = () => {
  return (
    <PageTemplate pageType="sideBar">
      <FigmaContainer src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fsld3QWsp4BbETefEn06pO5%2FPIPE-Part-2---Software-Dev%3Fnode-id%3D1244%253A8616%26scaling%3Dmin-zoom%26page-id%3D1241%253A8236"></FigmaContainer>
    </PageTemplate>
  );
};

export default Analytics;
