import styled from "styled-components";
import Accordion from "@mui/material/Accordion";

export const AccordionTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) => props.theme.custom.palette.foreground};
`;

export const StyledAccordion = styled(Accordion)`
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  border-top: none;
  background-image: none;
  background-color: ${(props) => props.theme.custom.palette.truePaper};

  &:before {
    background-color: ${(props) => props.theme.palette.divider};
  }
`;

export const StyledIcon = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
`;
