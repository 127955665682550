import styled from "styled-components";
import { Box, Tab, Tabs, IconButton } from "@mui/material";

export const StyledModalContainer = styled(Box)`
  background: ${(props) => props.theme.palette.background.paper};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 90%;
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const StyledModalContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0rem;
  top: -0.5rem;
`;

export const CloseButton = styled(IconButton)`
  color: ${(props) => props.theme.custom.palette.foreground};
`;

export const StyledModalTitle = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.6px;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0rem;
  color: ${(props) => props.theme.custom.palette.title};
`;

export const StyledModalBodyWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const StyledVerticalTabs = styled(Tabs)`
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  overflow: visible;
`;

export const StyledTab = styled(Tab)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: none;
  align-items: flex-start;
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  white-space: nowrap;
  color: ${(props) => props.theme.custom.palette.subTitle};
`;

export const StyledModalBody = styled(Box)`
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0rem;
  max-height: calc(90vh - 5.5rem);
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.scrollbar};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.scrollbarHover};
  }
`;
