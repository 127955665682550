import React from "react";
import { VerticalButtonGroupConfig } from "./types";
import { Container } from "./VerticalButtonGroup.styles";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useTheme } from "styled-components";
import { PopperButton } from "components";
import { useTunnel } from "queries";

type props = {
  buttonsConfig: VerticalButtonGroupConfig[];
};

export const VerticalButtonGroup: React.FunctionComponent<props> = ({
  buttonsConfig,
}) => {
  const theme = useTheme();
  const { tunnel } = useTunnel();
  const store = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  return (
    <Container>
      {buttonsConfig.map((buttonGroup) => (
        <div key={buttonGroup.id} style={{ marginBottom: 16 }}>
          {buttonGroup.data.map((button) => (
            <PopperButton
              key={button.id}
              tooltip={button.tooltip}
              PopperComponent={button.popperComponent}
              onClick={() =>
                tunnel &&
                button.onClick &&
                button.onClick(store, dispatch, tunnel)
              }
            >
              <button.icon
                style={{
                  color: button.toggle
                    ? store.Viewer3DStatus.comparison
                      ? theme.palette.primary.main
                      : theme.custom.palette.foreground
                    : theme.custom.palette.foreground,
                  height: 32,
                  width: 32,
                }}
              />
            </PopperButton>
          ))}
        </div>
      ))}
    </Container>
  );
};
