import React, { useEffect, useState } from "react";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from "recharts";
import { StyledBarChart, StyledResponsiveContainer } from "./BarChart.styles";
import { ragRatings } from "types";
import { useTheme } from "styled-components";
import { useAppSelector } from "redux/hooks";

type props = {
  data: ragRatings;
};

export const SingleBarChart: React.FunctionComponent<props> = ({ data }) => {
  const theme = useTheme();
  const [graphData, setGraphData] = useState<ragRatings>(data);

  const displayMinRingNumber = useAppSelector(
    (state) => state.TunnelData.display.minRingNumber,
  );
  useEffect(() => {
    setGraphData(
      data.map((item) => ({
        ...item,
        ring_number: displayMinRingNumber + item.ring_number,
      })),
    );
  }, [displayMinRingNumber, data]);

  return (
    <StyledResponsiveContainer width="99%">
      <StyledBarChart data={graphData}>
        <CartesianGrid stroke={theme.palette.divider} vertical={false} />
        <XAxis
          stroke={theme.custom.palette.foreground}
          dataKey="ring_number"
          domain={[" ", " "]}
        />
        <YAxis stroke={theme.custom.palette.foreground} />
        <Tooltip
          labelStyle={{
            color: theme.custom.palette.text,
          }}
          contentStyle={{
            backgroundColor: theme.custom.palette.truePaper,
            borderRadius: "0.25rem",
            borderWidth: 0,
            boxShadow:
              "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
          }}
          itemStyle={{ color: theme.custom.palette.text }}
        />
        <Bar dataKey="count">
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                entry.color === "green"
                  ? theme.custom.palette.goodCondition
                  : entry.color === "yellow"
                  ? theme.custom.palette.mediumCondition
                  : theme.custom.palette.badCondition
              }
            />
          ))}
        </Bar>
      </StyledBarChart>
    </StyledResponsiveContainer>
  );
};
