import styled from "styled-components";
import { Button, TextField } from "@mui/material";

export const StyledSubModalTitle = styled.p`
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.6px;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0rem;
  color: ${(props) => props.theme.custom.palette.title};
`;

export const StyledSubModalSubTitle = styled.p`
  font-family: Arial;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.6px;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  margin-right: 0rem;
  color: ${(props) => props.theme.custom.palette.subTitle};
`;

export const StyledSubModalText = styled.p`
  font-family: Arial;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1.75rem;
  letter-spacing: 0.6px;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0rem;
  color: ${(props) => props.theme.custom.palette.text};
`;

export const StyledTextField = styled(TextField)`
  margin-left: 1rem !important;
  & .MuiOutlinedInput-root {
    color: ${(props) => props.theme.custom.palette.text};
  }
`;

export const HorizontalContainer = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const VerticalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 45%;
  min-width: 11rem;
  max-width: 16rem;
`;

export const IndentContainer = styled("div")`
  margin-left: 1.5rem;
`;

export const FlexContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CenterContainer = styled("div")({
  paddingRight: "10rem",
});

export const ConfirmContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "3rem",
});

export const ConfirmButton = styled(Button)({});
