import React from "react";
import { MapMol } from "../../molecules/MapMol/MapMol";
import { MapAlignment } from "./MapAlignment";
import { MapRenderInfo } from "types";
import { transformMapDataForPlotting } from "utils/helpers";

export const MapTile: React.FunctionComponent<MapRenderInfo> = ({
  mapData,
}) => {
  const { alignment } = transformMapDataForPlotting(mapData);
  return (
    <MapMol mapData={mapData}>
      <MapAlignment alignment={alignment} />
    </MapMol>
  );
};
