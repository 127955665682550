import React from "react";
import { Container, Input } from "./DateInput.styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Hint } from "components";

type Props = {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  hint?: string;
};

export const DateInput: React.FunctionComponent<Props> = ({
  date,
  setDate,
  hint,
}) => {
  const handleChange = (newValue: Date | null) => {
    newValue && setDate(newValue);
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label="Date"
          inputFormat="DD/MM/YYYY"
          value={date}
          onChange={handleChange}
          renderInput={(params) => <Input size="small" {...params} />}
        />
      </LocalizationProvider>
      {hint && <Hint message={hint} />}
    </Container>
  );
};
