import styled from "styled-components";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";

export const ButtonContainer = styled(IconButton)`
  height: 3rem;
  width: 3rem;
  border: 1px dashed ${(props) => props.theme.custom.palette.foreground};
  border-radius: 0.5rem;
`;

export const AddIcon = styled(Add)`
  color: ${(props) => props.theme.custom.palette.foreground};
`;
