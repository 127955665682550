import React from "react";
import {
  Corner,
  ScreenDivider,
  ComparisonToolbar,
  StyledButton,
} from "./Viewer3DComparison.styles";
import { useAppDispatch } from "redux/hooks";
import { set_comparison } from "redux/Viewer3DStatus";
import { viewerInstances } from "components";
import { Close as CloseIcon } from "@mui/icons-material";

export const Viewer3DComparison: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const closeComparison = () => {
    viewerInstances?.main?.setPolyLines(false);
    viewerInstances?.main?.setSecondaryImage(false);
    viewerInstances?.main?.setOriginalBoundingBoxes(false);
    dispatch(set_comparison(false));
  };

  return (
    <>
      <ComparisonToolbar>
        <StyledButton onClick={() => closeComparison()}>
          <CloseIcon />
        </StyledButton>
      </ComparisonToolbar>
      <Corner
        style={{
          transform: "rotate(180deg) scaleX(-1)",
          left: "calc(50% - 1rem - 1px)",
          top: -1,
        }}
      />
      <Corner
        style={{
          transform: "rotate(180deg)",
          left: "calc(50% + 0.5rem - 1px)",
          top: -1,
        }}
      />
      <Corner
        style={{
          left: "calc(50% - 1rem - 1px)",
          bottom: -1,
        }}
      />
      <Corner
        style={{
          transform: "scaleX(-1)",
          left: "calc(50% + 0.5rem - 1px)",
          bottom: -1,
        }}
      />
      <ScreenDivider />
    </>
  );
};
