import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, useQueryClient, UseMutationResult } from "react-query";
import { API_URL } from "../utils/config";
import { useAppSelector } from "redux/hooks";

export const useUploadTunnel = (): UseMutationResult<
  AxiosResponse,
  unknown,
  FormData,
  unknown
> => {
  const queryClient = useQueryClient();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );

  return useMutation(
    (tunnelData: FormData) => {
      const config: AxiosRequestConfig = {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      return axios.post(`${API_URL}api/tunnels`, tunnelData, config);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tunnels"]);
      },
    },
  );
};
