import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";

export const CardWrapper = styled(Card)<{
  style: {
    "--box-shadow": string;
  };
}>`
  position: relative;
  margin: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0;
  box-shadow: var(--box-shadow);
  max-height: 25rem;
  max-width: 25vw;
`;

export const StyledCardMedia = styled(CardMedia)`
  height: 12.125rem;
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.subTitle};
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-bottom: 0;
`;

export const StyledCardContent = styled(CardContent)`
  padding-top: 1rem;
  padding-bottom: 0.25rem;
`;

export const ClientNameText = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.text};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.75rem;
`;

export const SecondaryText = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.foreground};
  font-size: 14px;
`;

export const InspectButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  font-family: Roboto;
  font-weight: 700;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
`;
