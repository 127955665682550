import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_display_ring_number } from "redux/TunnelData";
import {
  Wrapper,
  CustomSlider,
  SliderWrapper,
  SliderHandleFollow,
  RingNumberText,
  EndLocation,
  StartingLocation,
} from "./Viewer3DSlider.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "styled-components";
import { Tooltip, tooltipsContent } from "components";
import { useTunnel } from "queries";

export const Viewer3DSlider: React.FunctionComponent = () => {
  const displayTunnelData = useAppSelector((state) => state.TunnelData.display);
  const dispatch = useAppDispatch();
  const { tunnel } = useTunnel();

  const [inputText, setInputText] = useState<string>(
    `${displayTunnelData.ringNumber}`,
  );

  useEffect(() => {
    setInputText(`${displayTunnelData.ringNumber}`);
  }, [displayTunnelData.ringNumber]);

  const [error, setError] = useState<boolean>(false);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === "0" || Number(newValue) || !newValue.replace(" ", "")) {
      setError(false);
      setInputText(newValue);
    }
  };

  const handleTextSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      displayTunnelData.minRingNumber <= Number(inputText) &&
      Number(inputText) <= displayTunnelData.maxRingNumber
    ) {
      setRingNumber(inputText);
    } else {
      setError(true);
    }
  };

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setRingNumber(`${newValue}`);
    }
  };

  const setRingNumber = (newRingNumberStr: string) => {
    const newRingNumber = Number(newRingNumberStr);
    if (newRingNumber === 0 || newRingNumber) {
      dispatch(set_display_ring_number(newRingNumber));
      setInputText(newRingNumberStr);
    }
  };

  const tunnelProgress = (min: number, max: number, value: number): string => {
    return `${((value - min) / (max - min)) * 100}%`;
  };
  const theme = useTheme();

  const marks = [
    {
      value: displayTunnelData.minRingNumber,
      label: `${displayTunnelData.minRingNumber}`,
    },
    {
      value: displayTunnelData.maxRingNumber,
      label: `${displayTunnelData.maxRingNumber}`,
    },
  ];

  return (
    <Tooltip title={tooltipsContent.slider} followCursor={true}>
      <Wrapper>
        <RingNumberText>Panel</RingNumberText>
        <StartingLocation>
          <ArrowBackIcon
            style={{
              fontSize: "0.825rem",
              marginRight: "0.5rem",
              color: theme.custom.palette.text,
            }}
          />
          {tunnel?.start_terminus}
        </StartingLocation>
        <EndLocation>
          {tunnel?.end_terminus}
          <ArrowForwardIcon
            style={{
              fontSize: "0.825rem",
              color: theme.custom.palette.text,
              marginLeft: "0.5rem",
            }}
          />
        </EndLocation>
        <SliderWrapper onSubmit={handleTextSubmit}>
          <CustomSlider
            aria-label="Set panel"
            min={displayTunnelData.minRingNumber}
            max={displayTunnelData.maxRingNumber}
            value={displayTunnelData.ringNumber}
            onChange={handleSliderChange}
            marks={displayTunnelData.minRingNumber !== 0 && marks}
          />
          <SliderHandleFollow
            style={{
              "--percent": tunnelProgress(
                displayTunnelData.minRingNumber,
                displayTunnelData.maxRingNumber,
                displayTunnelData.ringNumber,
              ),
              "--border-color": error ? "red" : theme.palette.primary.main,
            }}
            size="small"
            inputProps={{
              style: {
                height: "0.5rem",
                paddingLeft: 7,
                paddingRight: 7,
                textAlign: "center",
              },
            }}
            value={inputText}
            onChange={handleTextChange}
          ></SliderHandleFollow>
        </SliderWrapper>
      </Wrapper>
    </Tooltip>
  );
};
