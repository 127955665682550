import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getRouteByPath } from "utils/helpers";
import { Tooltip } from "components";

interface ControlledLinkProps extends LinkProps {
  to: string;
  hideDisabled?: boolean;
  tunnelId?: string;
}

export const ControlledLink: React.FunctionComponent<ControlledLinkProps> = (
  props,
) => {
  const sessionData = useAppSelector((state) => state.SessionData);
  const baseRoute = getRouteByPath(props.to);

  const actualRoute = props.tunnelId
    ? props.to.replace(":tunnel_id", props.tunnelId)
    : props.to;

  const linkProps = { ...props };
  delete linkProps.hideDisabled;
  delete linkProps.tunnelId;

  return baseRoute && sessionData.allowedPages.includes(baseRoute?.name) ? (
    <Link {...linkProps} to={actualRoute}>
      {props.children}
    </Link>
  ) : props.hideDisabled ? (
    <>{props.children}</>
  ) : (
    <Tooltip title="This feature is in development">
      <div style={{ opacity: 0.7, cursor: "not-allowed" }}>
        <div style={{ pointerEvents: "none" }}>{props.children}</div>
      </div>
    </Tooltip>
  );
};
