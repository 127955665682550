import { createSlice } from "@reduxjs/toolkit";
import { CompareComponentDimensions } from "utils/helpers";
import { ComponentDimensions, Viewer3DPageState } from "types";

export interface Viewer3DStatusState {
  loaded: boolean;
  progress: number;
  dashboard: Viewer3DPageState;
  inspector: Viewer3DPageState;
  shown: boolean;
  comparison: boolean;
}

export const initialPageState: Viewer3DPageState = {
  dimensions: { width: 0, height: 0, top: 0, left: 0, bottom: 0, right: 0 },
};

const initialState: Viewer3DStatusState = {
  loaded: false,
  progress: 0,
  dashboard: initialPageState,
  inspector: initialPageState,
  shown: false,
  comparison: false,
};

export const Viewer3DStatus = createSlice({
  name: "Viewer3DStatus",
  initialState,
  reducers: {
    set_dimensions: (state, action) => {
      const page: string = action.payload.pageName;
      const pageName =
        page === "dashboard" || page === "inspector" ? page : null;
      if (!pageName) {
        return { ...state };
      }
      const newDimensions: ComponentDimensions = action.payload.dimensions;
      const currentDimensions = state[pageName].dimensions;
      return newDimensions.width > 0 &&
        newDimensions.height > 0 &&
        !CompareComponentDimensions(currentDimensions, newDimensions)
        ? {
            ...state,
            [pageName]: {
              dimensions: newDimensions,
            },
          }
        : { ...state };
    },
    set_loaded: (state, action) => {
      return { ...state, loaded: action.payload };
    },
    set_progress: (state, action) => {
      return { ...state, progress: action.payload };
    },
    set_shown: (state, action) => {
      return { ...state, shown: action.payload };
    },
    set_comparison: (state, action) => {
      return { ...state, comparison: action.payload };
    },
  },
});

export const {
  set_dimensions,
  set_loaded,
  set_progress,
  set_shown,
  set_comparison,
} = Viewer3DStatus.actions;

export default Viewer3DStatus.reducer;
