export const tooltipsContent = {
  helpIcon: "Help", //UserTabs.tsx
  settingsIcon: "Settings", //UserTabs.tsx
  userIcon: "Your profile", //UserTabs.tsx
  addAsset: "Add a new asset", //Home/index.tsx

  plusIconNextToSearch: "Create new tunnel tile", //not implemented yet?
  notificationsIcon: "New feature notification", //not implemented yet?

  searchBar: "Search for tunnel", //SearchBar.tsx
  collapsedSidebarDashboard: "Dashboard", //routes.ts
  collapsedSidebar360View: "Inspector", //Navlist.tsx //routes.ts
  collapsedSidebarMap: "Map", //Navlist.tsx //routes.ts
  collapsedSidebarFeatureLog: "Feature log", //Navlist.tsx //routes.ts
  collapsedSidebarAnalysis: "Analysis", //Navlist.tsx //routes.ts
  collapsedSidebarFiles: "Files", //Navlist.tsx //routes.ts
  collapsedSidebarBack: "Back to Home", //Navlist.tsx
  collapsedSidebarExpand: "Expand", //Sidebar.tsx
  expandedSidebarCollapse: "Collapse", //Sidebar.tsx
  mapIconsDefaultExtend: "Back to default extent", //MapOrg/controlsConfig.ts //Viewer3D/controlsConfig.ts //PopperButton.tsx
  mapIconsZoomIn: "Zoom in", //MapOrg/controlsConfig.ts //Viewer3D/controlsConfig.ts //PopperButton.tsx
  mapIconsZoomOut: "Zoom out", //MapOrg/controlsConfig.ts //Viewer3D/controlsConfig.ts //PopperButton.tsx
  mapIconsLayers: "Layers", //MapOrg/controlsConfig.ts //PopperButton.tsx
  mapIconsFilter: "Filter", //MapOrg/controlsConfig.ts //PopperButton.tsx
  mapIconsCompare: "Comparison mode", //MapOrg/controlsConfig.ts //PopperButton.tsx
  mapStyle: "Map style", //MapOrg/controlsConfig.ts //PopperButton.tsx
  mapIconsDigitalStyle: "Digital Map Style", ///MapOrg/controlsConfig.ts //PopperButton.tsx
  mapIconsSatellite: "Satellite Map Style", ///MapOrg/controlsConfig.ts //PopperButton.tsx
  showHideInfoPanelRight: "Collapse side panel", //SideInfoPanel.tsx
  showHideInfoPanelLeft: "Expand side panel", //SideInfoPanel.tsx
  slider: "Move slider to navigate", //RagSlider.tsx //Viev3DSlider.tsx - follow cursor enabled
  editDefectInformationRightPanel: "Edit", //not implemented yet?
  cancelDefectInformationRightPanel: "Cancel", //not implemented yet?
  saveDefectInformationRightPanel: "Save", //not implemented yet?
  accessLocationPointOnMap: "Click to see details", //needs approval //MapShafts.tsx
  tunnelLocationLineOnMap: "Click to see details", //needs approval //MapAlignment.tsx
  clickAndDrag: "Click and drag to change position", //DashboardDrag.tsx
  historicDefect: "Historic feature", //BorderedAccordion.tsx
  humanIcon: "Manually labelled feature", //BorderedAccordion.tsx
  laptopIcon: "Machine learning predicted feature", //BorderedAccordion.tsx
  newTunnelName: "What is the tunnel's name? e.g. London Tunnel 2",
  newTunnelLine: "What is line that was surveyed?",
  newTunnelType: "What is the tunnel used for?",
  newTunnelGeolocation: "How are the images located?",
  newTunnelDate: "When was the survey carried out?",
  newTunnelStartChainage: "What is the chainage of the first survey location",
  newTunnelStartLatLong:
    "What is the latitude/longitude of the first survey location? If you do not have this data, please select another geolocation option",
  newTunnelImageSpacing: "How far apart were the images taken?",
  newTunnelThumbnailUpload:
    "The home page image for the tunnel (.jpeg/.png file)",
  newTunnelImageryUpload:
    "The images captured during survey (.jpeg/.png files)",
  newTunnelGeologyUpload:
    "A cross-section of the geology surrounding the tunnel (.svg file)",
};
//to add new tooltip:
// import { Tooltip, tooltipsContent  } from "components";
//then wrap component with <Tooltip title={tooltipsContent.title} placement(optional)="top/right etc"> if automatic placement is not working
//tooltip reference:
//https://arup.sharepoint.com/:x:/s/DigitalTunnelInspectionIdeationLab/EYcG-4wck25Lns-r3K0-O1YBmi54hXDw4aZIqC9tbJxSeQ?e=wftyT8
