import { createSlice } from "@reduxjs/toolkit";

export interface VerticalButtonGroupState {
  viewer3D: {
    bounding_box_layers: {
      defect_type: string[];
      min_confidence: number;
      date: string;
    };
  };
  map: null;
}

const initialState: VerticalButtonGroupState = {
  viewer3D: {
    bounding_box_layers: {
      defect_type: [],
      min_confidence: 0,
      date: "any",
    },
  },
  map: null,
};

export const VerticalButtonGroup = createSlice({
  name: "VerticalButtonGroup",
  initialState,
  reducers: {
    set_defect_type: (state, action) => {
      state.viewer3D.bounding_box_layers.defect_type = action.payload;
    },
    set_min_confidence: (state, action) => {
      state.viewer3D.bounding_box_layers.min_confidence = action.payload;
    },
    set_date: (state, action) => {
      state.viewer3D.bounding_box_layers.date = action.payload;
    },
  },
});

export const { set_defect_type, set_min_confidence, set_date } =
  VerticalButtonGroup.actions;

export default VerticalButtonGroup.reducer;
