import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { authProvider, authenticationParameters } from "./auth/authProvider";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import "typeface-roboto";
import "./styles.css";

export const msalInstance = new PublicClientApplication(authProvider);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authenticationParameters}
        >
          <App />
        </MsalAuthenticationTemplate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
