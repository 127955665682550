import React from "react";
import { Container, Input, InputItem } from "./SelectInput.styles";
import { TextFieldProps } from "@mui/material";
import { SelectInputItem } from "types";
import { Hint } from "components";

type Props = TextFieldProps & {
  items: SelectInputItem[];
  hint?: string;
};

export const SelectInput: React.FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Input
        select
        size="small"
        defaultValue={props.value ? undefined : props.items[0].value}
        {...props}
      >
        {props.items.map((option) => (
          <InputItem key={option.value} value={option.value}>
            {option.name}
          </InputItem>
        ))}
      </Input>
      {props.hint && <Hint message={props.hint} />}
    </Container>
  );
};
