import styled from "styled-components";
import { Paper, Stack, Slider } from "@mui/material";

export const Wrapper = styled(Paper)`
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 0.5rem !important;
`;

export const DefectCount = styled.p`
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  color: ${(props) => props.theme.custom.palette.subTitle};
`;

export const Header = styled("div")`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`;

export const GraphContainer = styled("div")`
  height: calc(100% - 44px - 3.5rem);
  width: 100%;
`;

export const ControlsContainer = styled("div")`
  height: 2rem;
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderStack = styled(Stack)`
  width: 100%;
  margin: 0 30%;
`;

export const CustomSlider = styled(Slider)`
  & .MuiSlider-thumb {
    width: 1.25rem;
    height: 1.25rem;
  }
  & .MuiSlider-rail {
    height: 4px;
  }
  & .MuiSlider-track {
    height: 2px;
  }
`;
