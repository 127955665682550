import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  margin: 1rem;
  margin-top: 1.5rem;
  z-index: 0;
  border-radius: var(--border-top);

  .mapboxgl-popup-content {
    background-color: ${(props) => props.theme.custom.palette.truePaper};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    color: ${(props) => props.theme.custom.palette.text};
  }
  .mapboxgl-popup-tip {
    border-top-color: ${(props) =>
      props.theme.custom.palette.truePaper} !important;
  }

  .mapboxgl-canvas {
    border-radius: 0.5rem;
  }
`;
