import styled from "styled-components";
import Button from "@mui/material/Button";

export const PaddedDiv = styled.div`
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    margin-right: 0.5rem;
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main}22 !important;
  color: ${(props) => props.theme.palette.primary.main} !important;
  font-size: 0.625rem !important;
  text-decoration: none !important;
  text-transform: none !important;
`;
