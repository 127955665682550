import React, { useState } from "react";
import {
  StyledButton,
  StyledPopper,
  ButtonContainer,
} from "./PopperButton.styles";
import { Fade, IconButtonProps } from "@mui/material";
import { Tooltip } from "components";

type Props = IconButtonProps & {
  tooltip: string;
  PopperComponent?: React.FunctionComponent;
};

export const PopperButton: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const PopperComponent = props.PopperComponent;
  const buttonProps = { ...props };
  delete buttonProps.PopperComponent;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (PopperComponent) {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    }
    props.onClick && props.onClick(event);
  };

  return (
    <>
      <ButtonContainer>
        <Tooltip title={props.tooltip} placement="right">
          <StyledButton {...buttonProps} onClick={handleClick}>
            {buttonProps.children}
          </StyledButton>
        </Tooltip>
      </ButtonContainer>
      {PopperComponent && (
        <StyledPopper
          id={open && Boolean(anchorEl) ? "button-popper" : undefined}
          open={open}
          anchorEl={anchorEl}
          placement="right-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div>
                <PopperComponent />
              </div>
            </Fade>
          )}
        </StyledPopper>
      )}
    </>
  );
};
