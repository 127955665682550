import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { viewerInstances } from "components";
import { DefectsFilter } from "components";
import { tooltipsContent } from "components/atoms/Tooltip/tooltipsContent";
import { VerticalButtonGroupConfig } from "components/molecules/VerticalButtonGroup";

export const buttonsConfig: VerticalButtonGroupConfig[] = [
  {
    id: "center_group",
    data: [
      {
        id: "center",
        icon: CenterFocusStrongIcon,
        tooltip: tooltipsContent.mapIconsDefaultExtend,
        onClick: (): void =>
          Object.values(viewerInstances).forEach(
            (instance) => instance && instance.setDefaultView(),
          ),
      },
    ],
  },
  {
    id: "zoom_group",
    data: [
      {
        id: "zoom_in",
        icon: ZoomInIcon,
        tooltip: tooltipsContent.mapIconsZoomIn,
        onClick: (): void => {
          Object.values(viewerInstances).forEach(
            (instance) => instance && instance.zoomIn(),
          );
        },
      },
      {
        id: "zoom_out",
        icon: ZoomOutIcon,
        tooltip: tooltipsContent.mapIconsZoomOut,
        onClick: (): void =>
          Object.values(viewerInstances).forEach(
            (instance) => instance && instance.zoomOut(),
          ),
      },
    ],
  },
  {
    id: "utils_group",
    data: [
      {
        id: "filter",
        icon: FilterAltIcon,
        tooltip: tooltipsContent.mapIconsFilter,
        popperComponent: DefectsFilter,
      },
    ],
  },
];
