import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";

export const StyledTableRow = styled(TableRow)`
  display: flex;
  justify-content: space-between;
`;

export const StyledTableCell = styled(TableCell)`
  font-size: 1rem;
  padding: 0.7vh 0vw 0.7vh 0.2vw;
  width: 50%;
  color: ${(props) => props.theme.custom.palette.foreground};
`;

export const StyledTableCellAlignRight = styled(StyledTableCell)`
  text-align: right;
  padding: 0.7vh 0.2vw 0.7vh 0vw;
`;
export const StyledTable = styled(Table)``;
