import styled from "styled-components";
import { Button, Typography } from "@mui/material";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Row = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`;

export const Text = styled(Typography)`
  font-size: 1rem;
  color: ${(props) => props.theme.custom.palette.text};
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main}22;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 0.625rem;
  text-decoration: none;
  text-transform: none;
  box-shadow: none !important;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main}44;
  }
`;
