import { useQuery } from "react-query";
import { DefectImage } from "types";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "utils/config";
import { useAppSelector } from "redux/hooks";
import { getTunnelIdFromUrl } from "utils/helpers";

type DefectImagesQuery = {
  defectImages?: DefectImage[];
  isLoading: boolean;
  error: Error | null;
};

export const useDefectImages = (
  ringNumber: number,
  enabled: boolean,
): DefectImagesQuery => {
  const tunnelId = getTunnelIdFromUrl();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );

  const { data, isLoading, error } = useQuery<DefectImage[], Error>(
    `tunnel_${tunnelId}_ring_${ringNumber}_defect_images`,
    async () => {
      const config: AxiosRequestConfig = {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await axios.get(
        `${API_URL}api/tunnels/${tunnelId}/defect-images?ring_number=${ringNumber}`,
        config,
      );
      return response.data;
    },
    { enabled: !!tunnelId && enabled },
  );
  return {
    defectImages: data,
    isLoading,
    error,
  };
};
