import React from "react";
import { PageTemplate, ControlledLink } from "components";
import { Wrapper, StyledButton, Text } from "./NoAccess.styles";
import { HeadingM } from "utils/fonts.styles";
import { useAppSelector } from "redux/hooks";
import { getRouteByName } from "utils/helpers";

const NoAccess: React.FunctionComponent = () => {
  const sessionData = useAppSelector((state) => state.SessionData);
  const redirectRoute =
    sessionData.origin && getRouteByName(sessionData.initialPage);

  return (
    <PageTemplate pageType="topBar">
      <Wrapper>
        <HeadingM>Access denied</HeadingM>
        {redirectRoute && redirectRoute?.path ? (
          <ControlledLink to={redirectRoute.path}>
            <StyledButton variant="contained">{`Go to ${redirectRoute.navText}`}</StyledButton>
          </ControlledLink>
        ) : (
          <Text>{"Please contact an admin"}</Text>
        )}
      </Wrapper>
    </PageTemplate>
  );
};

export default NoAccess;
