import React from "react";
import { SideBar, TopBar, BottomBar, ModalTemplate } from "components";
import {
  SideBarWrapper,
  TopBarWrapper,
  SideBarBodyWrapper,
  SideBarComponentContainer,
  TopBarComponentContainer,
} from "./PageTemplate.styles";

type Props = {
  pageType: "topBar" | "sideBar";
};

export const PageTemplate: React.FunctionComponent<Props> = ({
  pageType,
  children,
}) => {
  if (pageType === "sideBar") {
    return (
      <SideBarWrapper data-testid="sideBar">
        <SideBar />
        <SideBarBodyWrapper>
          <TopBar type={"dashboard"} />
          <SideBarComponentContainer>{children}</SideBarComponentContainer>
        </SideBarBodyWrapper>
        <ModalTemplate />
      </SideBarWrapper>
    );
  } else {
    return (
      <TopBarWrapper data-testid="topBar">
        <TopBar type={"path"} />
        <TopBarComponentContainer>{children}</TopBarComponentContainer>
        <BottomBar />
        <ModalTemplate />
      </TopBarWrapper>
    );
  }
};
