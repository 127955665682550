import React, { useState } from "react";
import {
  StyledSubModalTitle,
  StyledSubModalSubTitle,
  StyledSubModalText,
  TextInput,
  DefectCountSlider,
} from "components";
import Box from "@mui/material/Box";
import { CenterContainer } from "./SubModals.styles";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  set_rings_per_interval,
  set_feature_count_grouping,
} from "redux/Settings";

export const RagRatingSubModal: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { ringsPerInterval, maxRingsPerInterval } = useAppSelector(
    (state) => state.Settings,
  );
  const [interval, setInterval] = useState<string>(`${ringsPerInterval}`);

  const [error, setError] = useState(false);

  return (
    <CenterContainer>
      <StyledSubModalTitle>Red - Amber - Green rating</StyledSubModalTitle>
      <StyledSubModalSubTitle>Panels per interval</StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please specify the number of panels per interval. Default:{" "}
        <b>1 panel</b>. This sets the grouping value for the{" "}
        <b>Feature counts</b> component found in the <b>Dashboard</b>.
      </StyledSubModalText>
      <Box>
        <TextInput
          validation="decimalNumber"
          id={"panels-per-interval"}
          value={interval}
          errorMessage={
            error
              ? "Value must be 1 or an integer smaller than 100 and divisible by 10."
              : " "
          }
          onChange={(event) => {
            const newValue = event.target.value;
            if (!isNaN(parseInt(newValue)) || newValue === "") {
              setInterval(newValue);
            }
          }}
          onBlur={() => {
            const newValue = parseInt(interval);
            if (
              !isNaN(newValue) &&
              newValue <= maxRingsPerInterval &&
              (newValue % 10 === 0 || newValue === 1)
            ) {
              setError(false);
              dispatch(set_rings_per_interval(newValue));
              dispatch(set_feature_count_grouping(newValue));
            } else {
              setError(true);
            }
          }}
        />
      </Box>
      <StyledSubModalSubTitle>
        Red - Amber - Green thresholds
      </StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please specify the number of features <b>per panel</b> for <b>RAG</b>{" "}
        rating.
      </StyledSubModalText>
      <DefectCountSlider />
      <StyledSubModalSubTitle>RAG rating definition</StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Defines the RAG rating based on user input.
      </StyledSubModalText>
      <StyledSubModalText>
        Input: Please specify the number of panels per interval. Choose the
        absolute number of features that define Green - Good Condition, Amber -
        Medium Condition and Red - Poor Condition for <b>one panel</b>.
      </StyledSubModalText>
      <StyledSubModalText>
        Output: RAG rating as a function of the size of intervals and thresholds
        specified.
      </StyledSubModalText>
      <StyledSubModalText>
        Example: Interval of 10 panels, with threshold values of 5 and 15. If
        within 10 panels there are 50(5x10) features, or fewer, the interval is
        classified as Green. If there are between 50(5x10) and 150(15x10)
        features within 10 panels, the interval is classified as Amber. If there
        are more than 150(15x10) features within 10 panels, the interval is
        classified as Red.
      </StyledSubModalText>
    </CenterContainer>
  );
};
