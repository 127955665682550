import React, { useState, useEffect } from "react";
import { SingleBarChart } from "components";
import {
  Wrapper,
  DefectCount,
  ControlsContainer,
  Header,
  GraphContainer,
  CustomSlider,
  SliderStack,
} from "./DashboardBarChart.styles";
import { ButtonMultiSelect } from "components";
import { SvgIcon, useTheme } from "@mui/material";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import { ReactComponent as ChartIcon } from "./chart_range.svg";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_feature_count_grouping } from "redux/Settings";
import { useRagRating } from "queries";
import { ragRatings } from "types";

export const DashboardBarChart: React.FunctionComponent = () => {
  const initialValues = {
    value: 0,
    name: "",
    minValue: 0,
    maxValue: 10,
    scaleStep: 10,
  };
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [barChartData, setBarChartData] = useState<ragRatings>([]);
  const [defectFilter, setDefectFilter] = useState<string[]>([]);
  const { featureCountGrouping, ringsPerInterval } = useAppSelector(
    (state) => state.Settings,
  );

  const { ragRatings } = featureCountGrouping
    ? useRagRating(
        featureCountGrouping,
        defectFilter.length > 0 ? defectFilter : undefined,
      )
    : useRagRating(
        ringsPerInterval,
        defectFilter.length > 0 ? defectFilter : undefined,
      );

  useEffect(() => {
    ragRatings && setBarChartData(ragRatings);
  }, [ragRatings]);

  const calculateGrouping = (value: number) => {
    return value === 0 ? 1 : initialValues.scaleStep * value;
  };

  const handleChange = (_: Event, newValue: number | number[]) => {
    const newGrouping = calculateGrouping(newValue as number);
    dispatch(set_feature_count_grouping(newGrouping));
  };

  return (
    <Wrapper>
      <Header>
        <DefectCount>Feature counts</DefectCount>
        <ButtonMultiSelect filter={defectFilter} setFilter={setDefectFilter} />
      </Header>
      <GraphContainer>
        <SingleBarChart data={barChartData} />
      </GraphContainer>
      <ControlsContainer>
        <SliderStack spacing={2} direction="row">
          <SvgIcon
            component={ChartIcon}
            inheritViewBox
            stroke={theme.custom.palette.foreground}
          />
          <CustomSlider
            aria-label="Adjust resolution"
            value={
              featureCountGrouping
                ? featureCountGrouping / initialValues.scaleStep
                : ringsPerInterval / initialValues.scaleStep
            }
            onChange={handleChange}
            valueLabelDisplay="auto"
            marks
            step={1}
            min={initialValues.minValue}
            max={initialValues.maxValue}
            scale={calculateGrouping}
          />
          <BarChartRoundedIcon htmlColor={theme.custom.palette.foreground} />
        </SliderStack>
      </ControlsContainer>
    </Wrapper>
  );
};
