import styled from "styled-components";
import { Info as InfoIcon } from "@mui/icons-material";

export const Container = styled("div")({
  display: "flex",
  height: "100%",
  margin: "1rem",
  marginLeft: "0.5rem",
  marginRight: "0rem",
});

export const StyledInfoIcon = styled(InfoIcon)({
  width: "1rem !important",
  height: "1rem !important",
  color: "#c4c4c4",
});
