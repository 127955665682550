import React from "react";
import { LayerProps, Layer } from "react-map-gl";

export const MapLayer: React.FunctionComponent<LayerProps> = ({
  id,
  type,
  paint,
  source,
  layout,
}) => {
  return (
    <Layer id={id} type={type} paint={paint} source={source} layout={layout} />
  );
};
