import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { useTheme } from "styled-components";
import { FilterAltIcon, ItemText } from "./ButtonMultiSelect.styles";
import { useTunnel } from "queries";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 120,
      marginTop: -10,
    },
  },
};

type SelectItem = {
  name: string;
  value: string;
};

const selectItems: SelectItem[] = [
  { name: "Crack", value: "crack" },
  { name: "Missing brick", value: "missing_brick" },
];

// Temporary until we have a DefectType table per client
const selectItemsTII: SelectItem[] = [
  { name: "D11 - Joint", value: "D11" },
  { name: "D40 - Sum cover", value: "D40" },
  { name: "F00 - Fire panel", value: "F00" },
  { name: "F10 - Fire exit sign", value: "F10" },
  { name: "F20 - Fire refuge", value: "F20" },
  { name: "V00 - Vents", value: "V00" },
];

const convertValueToName = (items: SelectItem[], value: string): string => {
  let name = "";
  items.forEach(({ name: _name, value: _value }) => {
    if (value === _value) name = _name;
  });
  return name;
};

const useStyles = makeStyles(() => ({
  selectEmpty: {
    marginTop: "1rem",
  },
  icon: {
    left: 5,
    fontSize: "1rem",
  },
  iconOpen: {
    transform: "none",
  },

  selectSelect: {
    paddingLeft: "24px",
  },
}));

type Props = {
  filter: string[];
  setFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ButtonMultiSelect: React.FunctionComponent<Props> = ({
  filter,
  setFilter,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  // Temporary until we have a DefectType table per client
  const { tunnel } = useTunnel();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const newFilter = typeof value === "string" ? value.split(",") : value;
    setFilter(newFilter);
  };

  // Temporary until we have a DefectType table per client
  const clientSelectedItems =
    tunnel?.client_name === "TII" ? selectItemsTII : selectItems;

  return (
    <div>
      <FormControl sx={{ m: 1, width: 200 }}>
        <Select
          labelId="feature-select-label"
          id="feature-select"
          multiple
          IconComponent={FilterAltIcon}
          value={filter}
          onChange={handleChange}
          renderValue={(selected) =>
            selected
              .map((value: string) =>
                convertValueToName(clientSelectedItems, value),
              )
              .join(", ")
          }
          MenuProps={MenuProps}
          classes={{
            icon: classes.icon,
            iconOpen: classes.iconOpen,
            select: classes.selectSelect,
          }}
          style={{
            color: theme.custom.palette.text,
            textTransform: "none",
            height: "1.7rem",
            borderRadius: "3px",
            fontSize: "0.875rem",
          }}
        >
          {clientSelectedItems.map(({ name, value }) => (
            <MenuItem key={value} value={value} style={{ padding: 0 }}>
              <Checkbox checked={filter.indexOf(value) > -1} />
              <ItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
