import styled from "styled-components";
import { Paper, Button } from "@mui/material";

export const StyledBox = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  outline: none;
`;

export const TextButton = styled(Button)`
  font-family: Roboto;
  font-weight: 700;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main}22;
  }
`;

export const ContainedButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main}22;
  color: ${(props) => props.theme.palette.primary.main};
  box-shadow: none !important;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main}44;
  }
`;

export const ButtonDiv = styled("div")`
  margin: 1rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
