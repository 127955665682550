import React, { useRef, useEffect } from "react";
import { SizeContainer } from "./Viewer3DArea.styles";
import { withSize } from "react-sizeme";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_dimensions, set_shown } from "redux/Viewer3DStatus";
import { LoadingFlex } from "components";
import { DashboardComponentProps } from "types";
import { useLocation } from "react-router-dom";
import { locationPathSplit, DOMRectToDimensions } from "utils/helpers";

const WithSizeContainer = withSize()(SizeContainer);

const renderDelay = 750;
const renderDelayInterval = 25;

export const Viewer3DArea: React.FunctionComponent<DashboardComponentProps> = ({
  fullscreen,
}) => {
  const dispatch = useAppDispatch();
  const pageName = locationPathSplit(useLocation().pathname);
  const viewer3DStatus = useAppSelector((state) => state.Viewer3DStatus);
  const resizeRef = useRef<any>(null); //eslint-disable-line @typescript-eslint/no-explicit-any
  const timeouts = useRef<NodeJS.Timeout[]>([]);

  useEffect(() => {
    dispatch(set_shown(true));
    return () => {
      dispatch(set_shown(false));
    };
  }, []);

  useEffect(() => {
    if (resizeRef.current && !fullscreen) {
      const divElement: HTMLDivElement = resizeRef.current.domEl;
      if (divElement.parentNode?.parentElement) {
        const observer = new MutationObserver(stageUpdateViewer3D);
        observer.observe(divElement.parentNode?.parentElement, {
          attributes: true,
          attributeFilter: ["style"],
        });
      }
      divElement.parentNode?.parentElement?.parentElement?.addEventListener(
        "scroll",
        updateViewer3D,
      );
    }
    return () => {
      if (resizeRef.current) {
        const divElement: HTMLDivElement = resizeRef.current.domEl;
        divElement.parentNode?.parentElement?.parentElement?.removeEventListener(
          "scroll",
          updateViewer3D,
        );
      }
    };
  }, [resizeRef.current]);

  const stageUpdateViewer3D = () => {
    updateViewer3D();
    resetTimeouts();
    for (
      let timeout = 0;
      timeout < renderDelay;
      timeout += renderDelayInterval
    ) {
      timeouts.current.push(setTimeout(() => updateViewer3D(), timeout));
    }
  };

  const updateViewer3D = () => {
    if (resizeRef.current) {
      dispatch(
        set_dimensions({
          pageName: pageName,
          dimensions: DOMRectToDimensions(
            resizeRef.current.domEl.getBoundingClientRect(),
          ),
        }),
      );
    }
  };

  const resetTimeouts = () => {
    timeouts.current.forEach((timeout) => {
      clearTimeout(timeout);
    });
    timeouts.current = [];
  };

  return (
    <WithSizeContainer
      ref={resizeRef}
      style={{
        backgroundColor: viewer3DStatus.loaded ? "transparent" : "black",
        height: fullscreen ? "calc(100% - 0.25rem)" : "calc(100% - 40px)",
        width: fullscreen ? "100%" : "100%",
        marginTop: fullscreen ? "0.25rem" : "0rem",
        borderRadius: fullscreen ? "0.5rem" : "0rem 0rem 0.5em",
      }}
      onSize={() => fullscreen && updateViewer3D()}
    >
      {!viewer3DStatus.loaded && resizeRef.current && (
        <LoadingFlex message="Loading" percentage={viewer3DStatus.progress} />
      )}
    </WithSizeContainer>
  );
};
