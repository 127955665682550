import React, { useEffect, useState } from "react";
import { VerticalTable } from "components";
import { TableExpand, StyledTableContainer } from "./DashboardTable.styles";
import { DashboardComponentProps, row } from "types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTunnel } from "queries";
import {
  capitaliseFirstLetter,
  formatDate,
  formatRingRange,
} from "utils/helpers";

interface TunnelKeys {
  id: string;
  eam_number: string;
  date_added: string;
  date: string;
  start_terminus: string;
  end_terminus: string;
  display_start_ringnumber: string;
  total_rings: string;
  line: string;
}

const initialData: row[] = [
  { name: "Asset ID", value: null, property: "id" },
  {
    name: "Date surveyed",
    value: null,
    property: "date",
  },
  {
    name: "Date added",
    value: null,
    property: "date_added",
    function: formatDate,
  },
  {
    name: "Start terminus",
    value: null,
    property: "start_terminus",
    function: capitaliseFirstLetter,
  },
  {
    name: "End terminus",
    value: null,
    property: "end_terminus",
    function: capitaliseFirstLetter,
  },
  {
    name: "Panels surveyed",
    value: null,
    property: "display_start_ringnumber",
    supportProperty: "total_rings",
    function: formatRingRange,
  },
  {
    name: "Line",
    value: null,
    property: "line",
    function: capitaliseFirstLetter,
  },
];

export const DashboardTable: React.FunctionComponent<
  DashboardComponentProps
> = ({ setLayouts }) => {
  const [expanded, setExpanded] = useState(false);
  const { tunnel } = useTunnel();
  const [data, setData] = useState<row[]>([...initialData]);

  useEffect(() => {
    if (tunnel) {
      setData(() => {
        return data.map((item) => {
          return {
            ...item,
            value: item.property
              ? item.function
                ? item.supportProperty
                  ? item.function(
                      tunnel[item.property as keyof TunnelKeys],
                      tunnel[item.supportProperty as keyof TunnelKeys],
                    )
                  : item.function(tunnel[item.property as keyof TunnelKeys])
                : tunnel[item.property as keyof TunnelKeys]
              : "",
          };
        });
      });
    }
  }, [tunnel]);

  const expandTable = () => {
    if (setLayouts) {
      setLayouts("TunnelTable", "h", 1, expanded ? "subtract" : "add");
    }
    setExpanded(!expanded);
  };

  return (
    <>
      <StyledTableContainer>
        <VerticalTable rows={[...data]} />
      </StyledTableContainer>
      <TableExpand
        onClick={() => {
          expandTable();
        }}
      >
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </TableExpand>
    </>
  );
};
