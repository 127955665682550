import React, { useState } from "react";
import { CardOverlay } from "components";
import { useQueryClient } from "react-query";
import {
  CardWrapper,
  InspectButton,
  StyledLink,
  SecondaryText,
  StyledTypography,
  StyledCardMedia,
  ClientNameText,
  StyledCardContent,
} from "./Card.styles";
import CardActions from "@mui/material/CardActions";
import { Tunnel } from "types";
import moment from "moment";

type Props = Tunnel & {
  updated: number;
};

export const TunnelCard: React.FunctionComponent<Props> = ({
  id,
  display_name,
  client_name,
  image_url,
  eam_number,
  upload_status,
  updated,
}) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const handleUpdate = async () => {
    setUpdateStatus(true);
    await queryClient.invalidateQueries(["tunnels"]);
    setUpdateStatus(false);
  };

  return (
    <CardWrapper
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        "--box-shadow": hovering
          ? `0px 2px 2px rgba(0, 0, 0, 0.25), 0px 2px 2.5px rgba(0, 0, 0, 0.14),
      0px 0.5px 5px rgba(0, 0, 0, 0.12)`
          : `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12)`,
      }}
    >
      {(upload_status === "FAILED" || upload_status === "UPLOADING") && (
        <CardOverlay
          message={
            upload_status === "UPLOADING"
              ? "This asset is uploading. It will be available soon!"
              : "This asset failed to upload. Please contact the Loupe360 team."
          }
          onUpdate={handleUpdate}
          updating={updateStatus}
          lastUpdate={moment(updated).format("HH:mm:ss")}
        />
      )}
      <StyledCardMedia image={image_url} />
      <StyledCardContent>
        <StyledTypography variant="h6">{display_name}</StyledTypography>
        <ClientNameText gutterBottom variant="body2">
          {client_name}
        </ClientNameText>
        <SecondaryText variant="body2">Asset ID: {id}</SecondaryText>
        <SecondaryText variant="body2">eAM number: {eam_number}</SecondaryText>
      </StyledCardContent>
      <CardActions>
        <StyledLink to={`/${id}/dashboard`}>
          <InspectButton size="small">INSPECT</InspectButton>
        </StyledLink>
      </CardActions>
    </CardWrapper>
  );
};
