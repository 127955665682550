import React from "react";
import {
  Container,
  Image,
  Text,
  SubText,
  TextContainer,
  LogoContainer,
  Logo,
  LogoText,
  CopyrightContainer,
  CopyrightText,
} from "./PageUnderConstruction.styles";

type Props = {
  subtext: string;
};

export const PageUnderConstruction: React.FunctionComponent<Props> = ({
  subtext,
}) => {
  return (
    <Container>
      <Image src="/under_construction.webp" />
      <TextContainer>
        <LogoContainer>
          <Logo src="/android-chrome-192x192.png" />
          <LogoText>Loupe360</LogoText>
        </LogoContainer>
        <Text>Under construction</Text>
        <SubText>{subtext}</SubText>
      </TextContainer>
      <CopyrightContainer>
        <CopyrightText>
          Pygmalion Karatzas Photography © 2013 - 2022
        </CopyrightText>
      </CopyrightContainer>
    </Container>
  );
};
