import { styled } from "@mui/system";
import { Paper, Typography } from "@mui/material";

export const Container = styled(Paper)`
  padding: 0.5rem;
  padding-right: 0.75rem;
  margin-left: 0.5rem;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled(Typography)`
  font-size: 14px;
`;
