import styled from "styled-components";
import { Select, MenuItem, IconButton } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

export const ScreenDivider = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.custom.palette.background};
  width: 1rem;
  top: 0px;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
`;

const cornerRadius = 0.61;

export const Corner = styled.div`
  position: absolute;
  width: ${cornerRadius}rem;
  height: ${cornerRadius}rem;
  background-image: radial-gradient(
    circle at 0 0,
    rgba(0, 0, 0, 0) ${cornerRadius}rem,
    ${(props) => props.theme.custom.palette.background} ${cornerRadius}rem
  );
  z-index: 100;
`;

export const MainDate = styled.div`
  pointer-events: auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  left: calc(50% - 1.5rem);
  transform: translateX(-100%);
  width: 7.5rem;
  height: 3rem;
  background-color: ${(props) => props.theme.custom.palette.truePaper};
  box-shadow: ${(props) => props.theme.shadows[1]};
  border-radius: 0.5rem;
`;

export const SelectIcon = styled(ArrowDropDown)`
  color: ${(props) => props.theme.custom.palette.text};
`;

export const ComparisonDate = styled(Select)`
  width: 9.5rem;
  height: 3rem;
  color: ${(props) => props.theme.custom.palette.text};
  background-color: ${(props) => props.theme.custom.palette.truePaper};
  box-shadow: ${(props) => props.theme.shadows[1]};
  border-radius: 0.5rem;
`;

export const ComparisonToolbar = styled("div")`
  pointer-events: auto;
  position: absolute;
  top: 1rem;
  left: calc(100% - 1rem);
  transform: translateX(-100%);
  display: flex;
`;

export const DateMenuItem = styled(MenuItem)`
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.custom.palette.text};
`;

export const DateText = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.custom.palette.text};
`;

export const StyledButton = styled(IconButton)`
  height: 3rem;
  width: 3rem;
  background-color: ${(props) =>
    props.theme.custom.palette.truePaper} !important;
  border-radius: 0.5rem;
  box-shadow: ${(props) => props.theme.shadows[1]};
  margin-left: 0.5rem;
`;
