import React, { useState } from "react";
import { SideInfoPanel } from "components";
import {
  StyledButton,
  Heading,
  DetailDiv,
  PaddedDiv,
  SideMarginFlexDiv,
  DateText,
  Group,
  SubText,
} from "./ViewerSideInfoPanel.styles";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import {
  Loading,
  Error,
  DefectImageList,
  BorderedAccordion,
  VerticalTable,
} from "components";
import { capitaliseFirstLetter } from "utils/helpers";
import { useAppSelector } from "redux/hooks";
import { useDefects, useDefectImages } from "queries";
import { useTheme } from "styled-components";
import { Divider } from "@mui/material";
import { MLDefect } from "types";

export const ViewerSideInfoPanel: React.FunctionComponent = () => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const tunnelData = useAppSelector((state) => state.TunnelData);
  const defectFilters = useAppSelector(
    (state) => state.VerticalButtonGroup.viewer3D.bounding_box_layers,
  );
  const {
    defects,
    isLoading: isDefectLoading,
    error: defectsError,
  } = useDefects(tunnelData.actual.ringNumber, open);
  const {
    defectImages,
    isLoading: isDefectImagesLoading,
    error: defectImagesErrors,
  } = useDefectImages(tunnelData.actual.ringNumber, open);

  const filterDefects = (defects: MLDefect[]) => {
    if (defectFilters.date === "any" && defectFilters.defect_type.length === 0)
      return defects;
    return defects.filter(
      (defect) =>
        (defectFilters.date === "any" ||
          defect.date_recorded === defectFilters.date) &&
        defectFilters.defect_type.includes(defect.type),
    );
  };

  const filteredDefects = defects?.ml && filterDefects(defects?.ml);

  return (
    <SideInfoPanel open={open} setOpen={setOpen}>
      {open && (
        <>
          <Heading>Panel {tunnelData.display.ringNumber}</Heading>
          {
            <div
              style={{
                height: "calc(100% - 40px)",
              }}
            >
              <>
                <SideMarginFlexDiv>
                  <StyledButton variant="contained">
                    <ArrowBackIos fontSize={"inherit"} />
                    Previous
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    size={"small"}
                    style={{ marginLeft: "15px" }}
                  >
                    Next
                    <ArrowForwardIos fontSize={"inherit"} />
                  </StyledButton>
                </SideMarginFlexDiv>
                <SideMarginFlexDiv>
                  <DetailDiv>Jump to next panel with features</DetailDiv>
                </SideMarginFlexDiv>
              </>
              {isDefectLoading || isDefectImagesLoading ? (
                <Loading />
              ) : defectsError || defectImagesErrors ? (
                <Error>Failed to load feature data</Error>
              ) : (
                <PaddedDiv
                  style={{
                    maxHeight: "calc(100% - 6.5rem)",
                  }}
                >
                  <DateText>Site images</DateText>
                  <Group>
                    {defectImages && defectImages.length !== 0 ? (
                      <DefectImageList defectImages={defectImages} />
                    ) : (
                      <Error>No site images to display</Error>
                    )}
                  </Group>
                  <DateText>Defects</DateText>
                  <Group>
                    {filteredDefects && filteredDefects.length ? (
                      filteredDefects.map((defect) => {
                        const visibleIn =
                          defect.visible_in === ""
                            ? []
                            : defect.visible_in
                                .split(",")
                                .map((id_string) => parseInt(id_string));

                        const visibleInDefectImages = defectImages?.filter(
                          (defectImage) => visibleIn.includes(defectImage.id),
                        );

                        const sourceDefectImage = defectImages?.filter(
                          (defectImage) => defectImage.id === defect.source_id,
                        );

                        const defectType = (
                          capitaliseFirstLetter(defect.type) as string
                        ).replaceAll("_", " ");
                        return (
                          <BorderedAccordion title={defectType} type="machine">
                            <div style={{ marginBottom: "1rem" }}>
                              <VerticalTable
                                rows={[
                                  { name: "Id", value: defect.id },
                                  {
                                    name: "Feature Id",
                                    value: defect.defect_id,
                                  },
                                  {
                                    name: "Date recorded",
                                    value: defect.date_recorded,
                                  },
                                  { name: "Type", value: defectType },
                                  {
                                    name: "Confidence",
                                    value: defect.confidence,
                                  },
                                  {
                                    name: "Human annotated",
                                    value: defect.human_annotated
                                      ? "Yes"
                                      : "No",
                                  },
                                ]}
                              />
                              {sourceDefectImage &&
                                sourceDefectImage?.length !== 0 && (
                                  <>
                                    <SubText>Detected in</SubText>
                                    <DefectImageList
                                      defectImages={sourceDefectImage}
                                      textStyle={{
                                        color: theme.custom.palette.foreground,
                                        paddingLeft: "0.2vw",
                                      }}
                                      containerStyle={{
                                        paddingBottom: "0.5rem",
                                      }}
                                    />
                                    <Divider />
                                  </>
                                )}
                              {visibleInDefectImages &&
                                visibleInDefectImages?.length !== 0 && (
                                  <>
                                    <SubText>Also visible in</SubText>
                                    <DefectImageList
                                      defectImages={visibleInDefectImages}
                                      textStyle={{
                                        color: theme.custom.palette.foreground,
                                        paddingLeft: "0.2vw",
                                      }}
                                    />
                                  </>
                                )}
                            </div>
                          </BorderedAccordion>
                        );
                      })
                    ) : (
                      <Error>No features to display</Error>
                    )}
                  </Group>
                </PaddedDiv>
              )}
            </div>
          }
        </>
      )}
    </SideInfoPanel>
  );
};
