export const radioConfig = [
  {
    key: "digital",
    name: "Digital",
    value: "digital",
  },
  {
    key: "satellite",
    name: "Satellite",
    value: "satellite",
  },
];
