import styled from "styled-components";
import { Button, Typography } from "@mui/material";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  text-transform: none !important;
`;

export const Text = styled(Typography)({});
