import { useQuery } from "react-query";
import { IncomingDefectData } from "types";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "utils/config";
import { useAppSelector } from "redux/hooks";
import { getTunnelIdFromUrl } from "utils/helpers";

type DefectsQuery = {
  defects?: IncomingDefectData;
  isLoading: boolean;
  error: Error | null;
};

export const useDefects = (
  ringNumber: number,
  enabled: boolean,
): DefectsQuery => {
  const tunnelId = getTunnelIdFromUrl();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );

  const { data, isLoading, error } = useQuery<IncomingDefectData, Error>(
    `tunnel_${tunnelId}_ring_${ringNumber}_defects`,
    async () => {
      const config: AxiosRequestConfig = {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await axios.get(
        `${API_URL}api/tunnels/${tunnelId}/defects?ring_number=${ringNumber}`,
        config,
      );
      return response.data;
    },
    { enabled: !!tunnelId && enabled },
  );
  return {
    defects: data,
    isLoading,
    error,
  };
};
