import {
  CenterFocusStrong,
  ZoomIn,
  ZoomOut,
  Layers,
} from "@mui/icons-material";
import { RootState, AppDispatch } from "redux/store";
import { set_viewport } from "redux/Map";
import { centerMapToAlignment } from "utils/helpers";
import { tooltipsContent } from "components/atoms/Tooltip/tooltipsContent";
import { VerticalButtonGroupConfig } from "components/molecules/VerticalButtonGroup";
import { MapStyle } from "components/molecules";

const mapZoomStep = 0.2;

export const buttonsConfig: VerticalButtonGroupConfig[] = [
  {
    id: "center-group",
    data: [
      {
        id: "center",
        icon: CenterFocusStrong,
        tooltip: tooltipsContent.mapIconsDefaultExtend,
        onClick: (store: RootState, dispatch: AppDispatch): void => {
          const { zoom } = centerMapToAlignment(
            store.Map.viewport,
            store.Map.bounds,
            store.Map.canvas,
          );
          dispatch(
            set_viewport({
              longitude: store.Map.bounds.centerLong,
              latitude: store.Map.bounds.centerLat,
              zoom: zoom,
              width: "100%",
              height: "100%",
            }),
          );
          return;
        },
      },
    ],
  },
  {
    id: "zoom-group",
    data: [
      {
        id: "zoom-in",
        icon: ZoomIn,
        tooltip: tooltipsContent.mapIconsZoomIn,
        onClick: (store: RootState, dispatch: AppDispatch): void => {
          dispatch(
            set_viewport({
              ...store.Map.viewport,
              zoom: store.Map.viewport.zoom + mapZoomStep,
              width: "100%",
              height: "100%",
            }),
          );
        },
      },
      {
        id: "zoom-out",
        icon: ZoomOut,
        tooltip: tooltipsContent.mapIconsZoomOut,
        onClick: (store: RootState, dispatch: AppDispatch): void => {
          dispatch(
            set_viewport({
              ...store.Map.viewport,
              zoom: store.Map.viewport.zoom - mapZoomStep,
              width: "100%",
              height: "100%",
            }),
          );
        },
      },
    ],
  },
  {
    id: "map-style-group",
    data: [
      {
        id: "map-style",
        icon: Layers,
        tooltip: tooltipsContent.mapStyle,
        toggle: true,
        popperComponent: MapStyle,
      },
    ],
  },
];
