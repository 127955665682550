import React from "react";
import { ButtonContainer, AddIcon } from "./OutlinedAddButton.styles";
import { IconButtonProps } from "@mui/material";

export const OutlinedAddButton: React.FunctionComponent<IconButtonProps> = (
  props,
) => {
  return (
    <ButtonContainer {...props}>
      <AddIcon fontSize="small" />
    </ButtonContainer>
  );
};
