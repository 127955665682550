import React from "react";
import {
  Container,
  Body,
  Footer,
  Text,
  FooterText,
  UpdateButton,
  UpdateProgress,
} from "./CardOverlay.styles";
import { Update as UpdateIcon } from "@mui/icons-material";

type Props = {
  message: string;
  onUpdate: () => void;
  updating: boolean;
  lastUpdate: string;
};

export const CardOverlay: React.FunctionComponent<Props> = ({
  message,
  updating,
  onUpdate,
  lastUpdate,
}) => {
  return (
    <Container>
      <Body>
        <Text>{message}</Text>
      </Body>
      <Footer>
        {updating ? (
          <UpdateProgress />
        ) : (
          <UpdateButton onClick={onUpdate}>
            <UpdateIcon htmlColor="white" />
          </UpdateButton>
        )}
        <FooterText>{`Last updated: ${lastUpdate}`}</FooterText>
      </Footer>
    </Container>
  );
};
