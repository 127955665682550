import styled from "styled-components";
import { ControlledLink } from "components";
import ListItemText from "@mui/material/ListItemText";

export const Wrapper = styled.div<{
  style: { "--backgroundColor": string; "--border-right": string };
}>`
  background-color: var(--backgroundColor);
  border-right: var(--border-right);
`;

export const StyledLink = styled(ControlledLink)`
  text-decoration: none;
`;

export const StyledListItemText = styled(ListItemText)<{
  style: { "--color": string };
}>`
  color: var(--color);
  font-weight: 400;
`;
