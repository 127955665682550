import axios from "axios";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { ENDPOINT_ME, ENDPOINT_PHOTO } from "utils/config";
import { set_meta, set_user_read_token } from "redux/AccessToken";
import type { AppDispatch } from "redux/store";
import { authenticationParameters } from "./authProvider";

// type token = {
//   accessToken: string;
//   expiresOn: Date;
//   scopes: string[];
//   state: string;
// };

export const setUserData = async (
  dispatch: AppDispatch,
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
): Promise<void> => {
  const bearer = await instance.acquireTokenSilent({
    scopes: authenticationParameters.scopes,
    account: accounts[0],
  });

  dispatch(set_user_read_token({ accessToken: bearer.accessToken }));
  const config = {
    headers: { Authorization: `Bearer ${bearer.accessToken}` },
  };

  const nameRequest = axios.get(ENDPOINT_ME, config);
  const imageRequest = axios.get(ENDPOINT_PHOTO, {
    ...config,
    responseType: "arraybuffer",
  });

  await axios.all([nameRequest, imageRequest]).then(
    axios.spread((...resp) => {
      const name = resp[0].data.displayName;
      const email = resp[0].data.userPrincipalName;
      const jobTitle = resp[0].data.jobTitle;
      const officeLocation = resp[0].data.officeLocation;
      const userId = resp[0].data.id;
      const buffer = Buffer.from(resp[1].data, "binary").toString("base64");

      dispatch(
        set_meta({
          imageURL: buffer,
          name,
          jobTitle,
          officeLocation,
          userId,
          email,
        }),
      );
    }),
  );
};
