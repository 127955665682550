import { MapLayer, MapSource } from "components";
import { FeatureCollection, Feature } from "types";
import { Tooltip, tooltipsContent } from "components";

export const MapAlignment: React.FunctionComponent<{
  alignment: FeatureCollection;
}> = ({ alignment }) => {
  return (
    <>
      {alignment.features.map((item: Feature) => {
        const alignmentTitle = item.properties.title;
        return (
          <div key={"tunnel-alignment-source-" + alignmentTitle}>
            <Tooltip title={tooltipsContent.accessLocationPointOnMap}>
              <>
                <MapSource
                  id={"tunnel-alignment-source-" + alignmentTitle}
                  type="geojson"
                  data={item}
                >
                  <MapLayer
                    key={"tunnel-alignment-layer-" + alignmentTitle}
                    id={"tunnel-alignment-layer-" + alignmentTitle}
                    source={"tunnel-alignment-source-" + alignmentTitle}
                    type="line"
                    paint={{
                      "line-color": [
                        "match",
                        ["get", "type"],
                        "asset-alignment",
                        "#DB37CB",
                        "general-alignment",
                        "#bc95f0",
                        "#bc95f0",
                      ],
                      "line-width": [
                        "match",
                        ["get", "type"],
                        "asset-alignment",
                        8,
                        "general-alignment",
                        5,
                        5,
                      ],
                    }}
                    layout={{
                      visibility: "visible",
                      "line-cap": "round",
                      "line-join": "bevel",
                      "line-sort-key": [
                        "match",
                        ["get", "type"],
                        "asset-alignment",
                        2,
                        "general-alignment",
                        1,
                        1,
                      ],
                    }}
                  />
                </MapSource>
              </>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
};
