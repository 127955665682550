import React from "react";
import {
  StyledSubModalTitle,
  StyledSubModalSubTitle,
  StyledSubModalText,
  ConfirmButton,
  CenterContainer,
} from "components";
import {
  cookiesAndTracking,
  trackingOnly,
  disableCookiesAndTracking,
} from "utils/helpers";
import { useAppDispatch } from "redux/hooks";
import { set_modal } from "redux/Modal";
import posthog from "posthog-js";

export const CookieSettingsSubModal: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <CenterContainer>
        <StyledSubModalTitle>Cookie settings</StyledSubModalTitle>
        <StyledSubModalSubTitle>Why do we use cookies?</StyledSubModalSubTitle>
        <StyledSubModalText>
          Our analytics tool utilises cookies and tracking to monitor user
          behaviour. This means we can improve user experience and analyse
          performance.
        </StyledSubModalText>
        <StyledSubModalSubTitle>Cookies and Tracking</StyledSubModalSubTitle>
        <StyledSubModalText>
          When cookies and tracking is enabled you will be remembered as the
          same user after your session ends and activity will be recorded over
          multiple sessions.
        </StyledSubModalText>
        <ConfirmButton
          style={{ marginLeft: "0.5rem", marginBottom: "0.5rem" }}
          onClick={() => {
            cookiesAndTracking(posthog);
            dispatch(set_modal());
          }}
        >
          Enable cookies and tracking
        </ConfirmButton>
        <StyledSubModalSubTitle>Tracking only</StyledSubModalSubTitle>
        <StyledSubModalText>
          When tracking only is enabled, no cookies are stored and it will not
          remember you after the session refreshes. We still monitor activity
          during these sessions.
        </StyledSubModalText>
        <ConfirmButton
          style={{ marginLeft: "0.5rem", marginBottom: "0.5rem" }}
          onClick={() => {
            trackingOnly(posthog);
            dispatch(set_modal());
          }}
        >
          Enable tracking only
        </ConfirmButton>
        <StyledSubModalSubTitle>Neither</StyledSubModalSubTitle>
        <StyledSubModalText>
          Both tracking and cookies are disabled.
        </StyledSubModalText>
        <ConfirmButton
          style={{ marginLeft: "0.5rem", marginBottom: "0.5rem" }}
          onClick={() => {
            disableCookiesAndTracking(posthog);
            dispatch(set_modal());
          }}
        >
          Disable cookies and tracking
        </ConfirmButton>
      </CenterContainer>
    </div>
  );
};
