import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: `${100 / 3}%`,
  height: "85%",
});

export const VerticalContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "min(95%, 5.5rem)",
});

export const TitleText = styled("div")((props) => ({
  color: props.theme.custom.palette.text,
  fontFamily: "Arial",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
}));

export const PercentageTextWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "calc(100% - min(12.5%, 0.75rem) - 12px)",
});

export const PercentageText = styled("div")((props) => ({
  color: props.theme.custom.palette.title,
  fontFamily: "Arial",
  fontWeight: "bold",
  fontSize: "min(2.5vh, 2rem)",
  letterSpacing: "0.5px",
}));

export const ColorChip = styled("div")({
  width: "min(60%, 6.5rem)",
  height: "min(12.5%, 0.75rem)",
  borderRadius: "0.5rem",
});

// export const MainText = styled.div`
//   color: ${(props) => props.theme.palette.dashboardHelper};
//   font-family: Arial;
//   font-style: normal;
//   font-weight: bold;
//   font-size: min(3vh, 2rem);
//   letter-spacing: 0.5px;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
