import { createSlice } from "@reduxjs/toolkit";

export interface SideBarOpenState {
  open: boolean;
}

const initialState: SideBarOpenState = {
  open: true,
};

export const SideBarOpen = createSlice({
  name: "SideBarOpen",
  initialState,
  reducers: {
    flip_state: (state) => {
      return { open: !state.open };
    },
  },
});

export const { flip_state } = SideBarOpen.actions;

export default SideBarOpen.reducer;
