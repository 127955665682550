import React from "react";
import {
  CircularProgressWrapper,
  IconButtonWrapper,
  TypographyWrapper,
  ReplayIcon,
  Container,
} from "./LoadingFlex.styles";

type Props = {
  container?: boolean;
  containerStyle?: React.CSSProperties;
  message?: string;
  percentage?: number;
  retry?: boolean;
  retryEvent?: () => void;
};

export const LoadingFlex: React.FunctionComponent<Props> = ({
  container,
  containerStyle,
  message,
  percentage,
  retry,
  retryEvent,
}) => {
  let displayText = "";
  displayText += message ? `${message}...` : "";
  displayText += percentage
    ? `${message ? ` ${percentage}` : percentage}%`
    : "";

  const body = (
    <div>
      {retry ? (
        <IconButtonWrapper onClick={() => retryEvent && retryEvent()}>
          <ReplayIcon />
        </IconButtonWrapper>
      ) : (
        <CircularProgressWrapper />
      )}
      {displayText !== "" && (
        <TypographyWrapper>{displayText}</TypographyWrapper>
      )}
    </div>
  );

  return container ? (
    <Container style={containerStyle}>{body}</Container>
  ) : (
    body
  );
};
