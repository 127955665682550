import React, { useState } from "react";
import { useTheme } from "styled-components";
import { Container, StyledDivider } from "./TunnelHealth.styles";
import { TunnelHealthRating } from "components";
import { useTunnelQuality } from "queries";
import { tunnelQuality } from "types";
import { withSize } from "react-sizeme";

type HealthItem = { name: string; color: string; percentage: number };

const WithSizeContainer = withSize({
  monitorHeight: false,
  monitorWidth: true,
})(Container);

export const TunnelHealth: React.FunctionComponent = () => {
  const [width, setWidth] = useState<number | null>(null);
  const smallLayout = !!(width && width < 350);
  const theme = useTheme();
  const { tunnelQuality, isLoading } = useTunnelQuality();

  const getQuality = (key: keyof tunnelQuality) => {
    return tunnelQuality ? tunnelQuality[key] : 0;
  };

  const healthConfig: HealthItem[] = [
    {
      name: "Good",
      color: theme.custom.palette.goodCondition,
      percentage: getQuality("green"),
    },
    {
      name: "Medium",
      color: theme.custom.palette.mediumCondition,
      percentage: getQuality("yellow"),
    },
    {
      name: "Bad",
      color: theme.custom.palette.badCondition,
      percentage: getQuality("red"),
    },
  ];

  return (
    <WithSizeContainer onSize={(size) => setWidth(size.width)}>
      {healthConfig.map((props, index) => (
        <React.Fragment key={props.name}>
          <TunnelHealthRating
            name={`${props.name} ${smallLayout ? "" : "condition"}`}
            color={props.color}
            percentage={props.percentage}
            isLoading={isLoading}
          />
          {index < healthConfig.length - 1 && (
            <StyledDivider flexItem orientation="vertical" />
          )}
        </React.Fragment>
      ))}
    </WithSizeContainer>
  );
};
