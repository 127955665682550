import { useQuery } from "react-query";
import { Tunnel } from "types";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "utils/config";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { getTunnelIdFromUrl } from "utils/helpers";
import { set_new_tunnel } from "redux/TunnelData";

type TunnelQuery = {
  tunnel?: Tunnel;
  isLoading: boolean;
  error: Error | null;
};

export const useTunnel = (): TunnelQuery => {
  const tunnelId = getTunnelIdFromUrl();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );
  const { data, isLoading, error } = useQuery<Tunnel, Error>(
    `tunnel_${tunnelId}`,
    async () => {
      const config: AxiosRequestConfig = {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await axios.get(
        `${API_URL}api/tunnels/${tunnelId}`,
        config,
      );
      return response.data;
    },
    { enabled: !!tunnelId },
  );

  data && dispatch(set_new_tunnel(data));

  return {
    tunnel: data,
    isLoading,
    error,
  };
};
