import styled from "styled-components";
import { Typography } from "@mui/material";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
`;

export const Text = styled(Typography)`
  color: ${(props) => props.theme.custom.palette.text};
`;
