import React from "react";
import {
  StyledSubModalTitle,
  StyledSubModalSubTitle,
  StyledSubModalText,
  CenterContainer,
} from "components";

export const CookiePolicySubModal: React.FunctionComponent = () => {
  return (
    <CenterContainer>
      <StyledSubModalTitle>Cookie policy</StyledSubModalTitle>
      <StyledSubModalSubTitle>What are cookies?</StyledSubModalSubTitle>
      <StyledSubModalText>
        Cookies are small text files stored on your device to remember you as a
        user.
      </StyledSubModalText>
      <StyledSubModalSubTitle>Types of cookies we use</StyledSubModalSubTitle>
      <StyledSubModalText>
        We use first party cookies meaning the cookies we use are set and
        controlled by us not a third party. We also use both session cookies,
        cookies that are deleted after each session, and persistent cookies,
        cookies that stay until an expiry date or until you manually delete
        them. You can opt out of cookies using Cookie settings or browsing in
        private.
      </StyledSubModalText>
      <StyledSubModalSubTitle>What we use cookies for</StyledSubModalSubTitle>
      <StyledSubModalText>
        We use cookies for the purpose of understanding user behaviour to
        improve the experience of our service. We may also use cookies for
        strictly necessary purposes such as identifying you having logged in.
      </StyledSubModalText>
      <StyledSubModalSubTitle>Information we use</StyledSubModalSubTitle>
      <StyledSubModalText>
        We do not collect any personal data beyond remembering users through use
        of cookies; UserID is a random combination of text. We only use this
        data for the purpose of improving the experience of the service and not
        for any other reason.
      </StyledSubModalText>
    </CenterContainer>
  );
};
