import React from "react";
import {
  Container,
  VerticalContainer,
  TitleText,
  PercentageText,
  PercentageTextWrapper,
  ColorChip,
} from "./TunnelHealthRating.styles";
import { Loading } from "components";

type Props = {
  name: string;
  percentage: number;
  color: string;
  isLoading?: boolean;
};

export const TunnelHealthRating: React.FunctionComponent<Props> = ({
  name,
  percentage,
  color,
  isLoading,
}) => {
  return (
    <Container>
      <VerticalContainer>
        <TitleText>{name}</TitleText>
        <PercentageTextWrapper>
          {isLoading ? (
            <Loading
              style={{ height: "min(3vh, 2rem)", width: "min(3vh, 2rem)" }}
            />
          ) : (
            <PercentageText>{`${percentage}%`}</PercentageText>
          )}
        </PercentageTextWrapper>
        <ColorChip style={{ backgroundColor: color }} />
      </VerticalContainer>
    </Container>
  );
};
