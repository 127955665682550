import React from "react";
import { PageTemplate, PageUnderConstruction } from "components";

const FeatureRecord: React.FunctionComponent = () => {
  return (
    <PageTemplate pageType="sideBar">
      <PageUnderConstruction subtext="The feature log is coming soon..." />
    </PageTemplate>
  );
};

export default FeatureRecord;
