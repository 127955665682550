export const CLIENT_ID = "4063dc44-b3b1-45a3-a74d-7b15051752d5";
export const TENANT_ID = "4ae48b41-0137-4599-8661-fc641fe77bea";

export const API_URL =
  process.env.REACT_APP_DEVELOPMENT_STAGE === "production"
    ? "https://hs2-api.loupe360.arup.com/"
    : process.env.REACT_APP_DEVELOPMENT_STAGE === "development"
    ? "https://hs2-api.loupe360.arup.com/"
    : "http://localhost:5000/";

export const ENDPOINT_ME = "https://graph.microsoft.com/v1.0/me";
export const ENDPOINT_PHOTO =
  "https://graph.microsoft.com/v1.0/me/photos/48x48/$value";
