import React, { useState } from "react";
import { Container, Input } from "./TextInput.styles";
import { TextFieldProps, InputAdornment } from "@mui/material";
import { Hint } from "components";

type Props = TextFieldProps & {
  validation?: "decimalNumber" | "negDecimalNumber";
  unit?: string;
  hint?: string;
  errorMessage?: string;
};

export const TextInput: React.FunctionComponent<Props> = (props) => {
  const inputProps = { ...props };
  delete inputProps.errorMessage;

  const [value, setValue] = useState<string>(
    props.validation === "decimalNumber" ||
      props.validation === "negDecimalNumber"
      ? "0"
      : "",
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    switch (props.validation) {
      case "decimalNumber":
        if (newValue.match("^[0-9]*[.]{0,1}[0-9]*$")) {
          setValue(newValue);
        }
        break;
      case "negDecimalNumber":
        if (newValue.match("^-?[0-9]*[.]{0,1}[0-9]*$")) {
          setValue(newValue);
        }
        break;
      default:
        setValue(newValue);
    }
  };

  return (
    <Container>
      <Input
        value={value}
        onChange={handleChange}
        size="small"
        {...inputProps}
        helperText={
          props.helperText
            ? props.helperText
            : props.errorMessage
            ? props.errorMessage
            : " "
        }
        error={!!props.errorMessage && props.errorMessage !== " "}
        InputProps={{
          endAdornment: props.unit && (
            <InputAdornment position="end">{props.unit}</InputAdornment>
          ),
        }}
      />
      {props.hint && <Hint message={props.hint} />}
    </Container>
  );
};
