import { CircularProgress, IconButton, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  flexDirection: "column",
});

export const Body = styled("div")({
  width: "calc(100% - 2rem)",
  height: "calc(100% - 60px - 4rem)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "46px",
  padding: "1rem",
});

export const Footer = styled("div")({
  width: "calc(100% - 2rem)",
  height: "14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  padding: "1rem",
});

export const Text = styled(Typography)({
  color: "white",
  textAlign: "center",
  fontSize: "18px !important",
});

export const FooterText = styled(Typography)({
  fontSize: "14px !important",
  color: "white",
});

export const UpdateButton = styled(IconButton)({});

export const UpdateProgress = styled(CircularProgress)({
  width: "20px !important",
  height: "20px !important",
  padding: "10px !important",
  color: "white !important",
});
