import React, { useEffect } from "react";
import posthog from "posthog-js";

type Props = {
  eventName: string;
  children: React.ReactNode;
};

export const Posthog: React.FunctionComponent<Props> = ({
  eventName,
  children,
}) => {
  useEffect(() => {
    posthog.capture(eventName, {
      property: eventName,
    });
  }, [eventName]);
  return <>{children}</>;
};
