import styled from "styled-components";
import { IconButton, Popper, Paper } from "@mui/material";

export const ButtonContainer = styled(Paper)`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
`;

export const StyledButton = styled(IconButton)``;

export const StyledPopper = styled(Popper)`
  z-index: 1000;
`;
