import styled from "styled-components";
import IconButton from "@mui/material/IconButton";

export const StyledTableContainer = styled.div`
  display: block;
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  width: calc(100% - 2rem);
  height: calc(100% - 5.5rem);
  overflow-x: auto;
  overflow-y: visible;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
  }
`;

export const TablesHolder = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  height: calc(100% - 2.5rem);
  border-width: 0px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.custom.palette.sideBarTable};
  }
`;

const SingleTableWrapper = styled.div`
  width: "50%";
`;

export const LeftTable = styled(SingleTableWrapper)`
  float: left;
  margin-right: 1vw;
`;

export const RightTable = styled(SingleTableWrapper)`
  margin-right: 0.5vw;
  float: right;
`;

export const TableExpand = styled(IconButton)`
  color: ${(props) => props.theme.custom.palette.foreground};
  position: absolute;
  right: 1rem;
  bottom: 0.25rem;
`;
