import { useQuery } from "react-query";
import { tunnelQuality } from "types";
import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "utils/config";
import { useAppSelector } from "redux/hooks";
import { getTunnelIdFromUrl } from "utils/helpers";

type TunnelQualityQuery = {
  tunnelQuality?: tunnelQuality;
  isLoading: boolean;
  error: Error | null;
};

export const useTunnelQuality = (): TunnelQualityQuery => {
  const tunnelId = getTunnelIdFromUrl();
  const accessToken = useAppSelector(
    (state) => state.AccessToken.userReadToken.accessToken,
  );
  const settings = useAppSelector((state) => state.Settings);

  const { data, isLoading, error } = useQuery<tunnelQuality, Error>(
    `tunnel_${tunnelId}_quality_red_${settings.defectLimitRed}_green_${settings.defectLimitGreen}`,
    async () => {
      const config: AxiosRequestConfig = {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await axios.get(
        `${API_URL}api/tunnels/${tunnelId}/quality?red_defects=${settings.defectLimitRed}&green_defects=${settings.defectLimitGreen}`,
        config,
      );
      return response.data;
    },
    { enabled: !!tunnelId },
  );
  return {
    tunnelQuality: data,
    isLoading,
    error,
  };
};
