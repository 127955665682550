import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  margin: 1rem;
  margin-top: 1.5rem;
  z-index: 0;
`;
