import React from "react";
import { Source } from "react-map-gl";

type props = {
  id: string;
  type: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
};

export const MapSource: React.FunctionComponent<props> = ({
  id,
  type,
  data,
  children,
}) => {
  return (
    <Source id={id} type={type} data={data}>
      {children}
    </Source>
  );
};
