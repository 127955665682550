import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { routes } from "pages/routes";
import { route } from "types";
import { Posthog } from "components";
import { useAppSelector } from "redux/hooks";
import { PermissionPrompt } from "components";
import NoAccess from "pages/NoAccess";
import posthog from "posthog-js";

const Pages: React.FunctionComponent = () => {
  const sessionData = useAppSelector((state) => state.SessionData);
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState<boolean>(
    !(posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing()),
  );

  useEffect(() => {
    if (sessionData.origin) {
      const splitPathname = window.location.pathname.split("/");
      const pageName = splitPathname[splitPathname.length - 1];
      if (!sessionData.allowedPages.includes(pageName)) {
        navigate(sessionData.initialPath);
      }
    }
  }, [sessionData]);

  return sessionData.origin ? (
    <>
      <PermissionPrompt prompt={prompt} setPrompt={setPrompt} />
      <Routes>
        {routes.map((route: route, i: number) => (
          <Route
            key={i}
            path={route.path}
            element={
              sessionData.allowedPages.includes(route.name) ? (
                <Posthog eventName={route.navText}>
                  <route.component />
                </Posthog>
              ) : (
                <Posthog eventName={"No access"}>
                  <NoAccess />
                </Posthog>
              )
            }
          />
        ))}
      </Routes>
    </>
  ) : null;
};

export default Pages;
