import styled from "styled-components";
import { Avatar } from "@mui/material";

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row-reverse;
  text-align: center;
`;

export const AvatarWrapper = styled(Avatar)`
  height: 28px !important;
  width: 28px !important;
  margin: 2px !important;
`;
