export const checkboxConfig = [
  {
    key: "crack",
    name: "Crack",
    color: "#9747ff",
  },
  {
    key: "missing_brick",
    name: "Missing brick",
    color: "#43B3EA",
  },
];

export const radioConfig = [
  {
    key: "low",
    name: "Low",
    value: "0",
  },
  {
    key: "medium",
    name: "Medium",
    value: "0.5",
  },
  {
    key: "high",
    name: "High",
    value: "0.7",
  },
];
