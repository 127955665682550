import { createSlice } from "@reduxjs/toolkit";

export interface AccessTokenState {
  userReadToken: {
    accessToken: string;
    expiresOn: Date | null;
    scopes: string[];
    state: string;
  };
  meta: {
    imageURL: string;
    name: string;
    jobTitle: string;
    officeLocation: string;
    userId: string;
    email: string;
  };
}

const initialState: AccessTokenState = {
  userReadToken: {
    accessToken: "",
    expiresOn: null,
    scopes: [""],
    state: "",
  },
  meta: {
    imageURL: "",
    name: "",
    jobTitle: "",
    officeLocation: "",
    userId: "",
    email: "",
  },
};

export const AccessToken = createSlice({
  name: "AccessToken",
  initialState,
  reducers: {
    set_user_read_token: (state, action) => {
      return { ...state, userReadToken: { ...action.payload } };
    },
    set_meta: (state, action) => {
      return { ...state, meta: { ...action.payload } };
    },
  },
});

export const { set_user_read_token, set_meta } = AccessToken.actions;

export default AccessToken.reducer;
