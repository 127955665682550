import { BarChart, ResponsiveContainer } from "recharts";
import styled from "styled-components";

export const StyledResponsiveContainer = styled(ResponsiveContainer)``;

export const StyledBarChart = styled(BarChart)((props) => {
  return {
    "& .recharts-tooltip-cursor": {
      fill: `${props.theme.custom.palette.background}${
        props.theme.palette.mode === "light" ? "DD" : "44"
      }`,
    },
  };
});
