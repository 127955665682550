import React, { Dispatch, SetStateAction } from "react";
import {
  StyledBox,
  TextButton,
  ContainedButton,
  ButtonDiv,
} from "./PermissionPrompt.styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import posthog from "posthog-js";
import {
  cookiesAndTracking,
  trackingOnly,
  disableCookiesAndTracking,
} from "utils/helpers";

type Props = {
  prompt: boolean;
  setPrompt: Dispatch<SetStateAction<boolean>>;
};

export const PermissionPrompt: React.FunctionComponent<Props> = ({
  prompt,
  setPrompt,
}) => {
  return (
    <Modal
      open={prompt}
      onClose={() =>
        (posthog.has_opted_in_capturing() ||
          posthog.has_opted_out_capturing()) &&
        setPrompt(false)
      }
    >
      <StyledBox>
        <Typography variant="h6" component="h2">
          Cookie settings
        </Typography>
        <Typography sx={{ mt: 2 }}>
          We are using an analytics tool, that utilises cookies and tracking, to
          monitor user behaviour so we can improve the website experience and
          analyse performance.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Proceed with cookies and analytics enabled so you will be remembered
          as the same user after your session ends and activity is recorded over
          multiple sessions.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Enabling just the analytics tool, means no cookies are used and it
          will not remember you after the session refreshes. We still monitor
          activity during these sessions.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Or neither, no tracking or cookies are enabled.
        </Typography>
        <Typography sx={{ mt: 2 }}></Typography>
        <ButtonDiv>
          <TextButton
            onClick={() => {
              disableCookiesAndTracking(posthog);
              setPrompt(false);
            }}
          >
            Neither
          </TextButton>
          <TextButton
            onClick={() => {
              trackingOnly(posthog);
              setPrompt(false);
            }}
          >
            Analytics tool only
          </TextButton>
          <ContainedButton
            variant="contained"
            onClick={() => {
              cookiesAndTracking(posthog);
              setPrompt(false);
            }}
          >
            Enable cookies
          </ContainedButton>
        </ButtonDiv>
      </StyledBox>
    </Modal>
  );
};
