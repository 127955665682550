import React, { useState, useEffect } from "react";
import {
  PageTemplate,
  CardCollection,
  SearchBar,
  OutlinedAddButton,
  ModalAccess,
  tooltipsContent,
} from "components";
import { useTunnels } from "queries";
import { SearchbarOption, Tunnel } from "types";
import { ControlsContainer } from "./Home.styles";
import { useAppSelector } from "redux/hooks";

const Home: React.FunctionComponent = () => {
  const admin = useAppSelector((state) => state.SessionData.admin);
  const { tunnels, isLoading, updated } = useTunnels();
  const [filteredTunnels, setFilteredTunnels] = useState<Tunnel[]>([]);
  const [options, setOptions] = useState<SearchbarOption[]>([]);

  useEffect(() => {
    if (tunnels) {
      setFilteredTunnels(tunnels);
      const options = tunnels.map((tunnel: Tunnel) => {
        const optionName = tunnel.display_name;
        // there is also option to groupBy - add it then to return
        const secondaryOptionName = tunnel.eam_number.toString();
        return {
          optionName,
          secondaryOptionName,
        };
      });
      setOptions(options);
    }
  }, [tunnels]);

  const updateFilteredOptions = (filteredOptions: string[]) => {
    const newFilteredTunnels = tunnels?.filter((tunnel) =>
      filteredOptions.includes(tunnel.display_name),
    );
    newFilteredTunnels && setFilteredTunnels(newFilteredTunnels);
  };
  return (
    <>
      <PageTemplate pageType="topBar">
        <ControlsContainer>
          <SearchBar
            options={options}
            onInputChangeReturnFilteredOptions={updateFilteredOptions}
          />
          {admin && (
            <ModalAccess
              pageKey="manage_assets"
              tooltip={tooltipsContent.addAsset}
            >
              <OutlinedAddButton />
            </ModalAccess>
          )}
        </ControlsContainer>
        <CardCollection
          tunnels={filteredTunnels}
          loading={isLoading}
          updated={updated}
        />
      </PageTemplate>
    </>
  );
};

export default Home;
