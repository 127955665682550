import React from "react";
import { RagSlider, VerticalButtonGroup, Viewer3DComparison } from "components";
import { buttonsConfig } from "./controlsConfig";
import { useAppSelector } from "redux/hooks";

export const MaximizedControls: React.FunctionComponent = () => {
  const comparison = useAppSelector((state) => state.Viewer3DStatus.comparison);

  return (
    <>
      {comparison && <Viewer3DComparison />}
      <VerticalButtonGroup buttonsConfig={buttonsConfig} />
      <RagSlider />
    </>
  );
};
