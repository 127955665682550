import React, { useState } from "react";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import {
  AccordionTitle,
  StyledAccordion,
  StyledIcon,
} from "./BorderedAccordion.styles";

import SummarizeIcon from "@mui/icons-material/Summarize";
import PersonIcon from "@mui/icons-material/Person";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import { Tooltip, tooltipsContent } from "components/atoms";

type Props = {
  title: string;
  expanded?: boolean;
  type?: string;
  children: React.ReactNode;
};

const icons = [
  {
    name: "historic",
    icon: (
      <Tooltip title={tooltipsContent.historicDefect} placement="left">
        <StyledIcon>
          <SummarizeIcon />
        </StyledIcon>
      </Tooltip>
    ),
  },
  {
    name: "manual",
    icon: (
      <Tooltip title={tooltipsContent.humanIcon} placement="left">
        <StyledIcon>
          <PersonIcon />
        </StyledIcon>
      </Tooltip>
    ),
  },
  {
    name: "machine",
    icon: (
      <Tooltip title={tooltipsContent.laptopIcon} placement="left">
        <StyledIcon>
          <LaptopChromebookIcon />
        </StyledIcon>
      </Tooltip>
    ),
  },
];

export const BorderedAccordion: React.FunctionComponent<Props> = ({
  title,
  expanded = false,
  type,
  children,
}) => {
  const [expandStatus, setExpandStatus] = useState(expanded);

  return (
    <StyledAccordion
      expanded={expandStatus}
      onChange={(_, expandStatus) => {
        setExpandStatus(expandStatus || expanded);
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-content": {
            justifyContent: "space-between !important",
          },
        }}
      >
        <AccordionTitle>{title}</AccordionTitle>
        {type ? icons.filter(({ name }) => type === name)[0].icon : null}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
