import React from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { Tooltip } from "components";
import { set_modal, set_modal_type, set_modal_start_page } from "redux/Modal";

type Props = {
  pageKey: string;
  tooltip?: string;
  startPage?: string;
  children: React.ReactNode;
};

export const ModalAccess: React.FunctionComponent<Props> = ({
  pageKey,
  tooltip,
  startPage,
  children,
}) => {
  const dispatch = useAppDispatch();
  const allowedPages = useAppSelector(
    (state) => state.SessionData.allowedPages,
  );

  const handleClick = () => {
    dispatch(set_modal());
    dispatch(set_modal_type(pageKey));
    dispatch(set_modal_start_page(startPage));
  };

  return allowedPages.includes(pageKey) ? (
    <Tooltip title={tooltip ? tooltip : false}>
      <div onClick={() => handleClick()}>{children}</div>
    </Tooltip>
  ) : (
    <Tooltip title="This feature has been disabled">
      <div style={{ opacity: 0.7, cursor: "not-allowed" }}>
        <div style={{ pointerEvents: "none" }}>{children}</div>
      </div>
    </Tooltip>
  );
};
