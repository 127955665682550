import React, { useState, useEffect } from "react";
import { withSize, SizeMeProps } from "react-sizeme";
import {
  ResponsiveGrid,
  WidgetWrapper,
  ComponentPaper,
  Title,
} from "./DashboardTemplate.styles";
import {
  DashboardTable,
  TunnelHealth,
  DashboardBarChart,
  Viewer3DArea,
  MapTemplate,
} from "components";
import { layouts, layoutItem } from "types";
import { initialLayouts } from "./config";

const desiredRowCount = 5;
const minRowHeight = 110;
const maxRowHeight = 180;
const verticalMargin = 24;
const horizontalMargin = 16;

const calculateRowHeight = (): number => {
  const newRowHeight =
    (window.innerHeight - 77 - (desiredRowCount + 1) * verticalMargin) /
    desiredRowCount;
  return Math.min(Math.max(newRowHeight, minRowHeight), maxRowHeight);
};

const Content: React.ComponentType<SizeMeProps> = ({ size: { width } }) => {
  const [layouts, setLayouts] = useState(initialLayouts);
  const [rowHeight, setRowHeight] = useState<number>(calculateRowHeight());

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    setRowHeight(calculateRowHeight());
  };

  const enlargeTable = (
    itemName: string,
    type: "h" | "w",
    value: number,
    operation: "add" | "subtract",
  ) => {
    const newLayout = JSON.parse(JSON.stringify(layouts));
    for (const [key] of Object.entries(newLayout)) {
      newLayout[key] = newLayout.lg.map((item: layoutItem) => {
        if (item.i === itemName) {
          item[type] =
            operation === "add" ? item[type] + value : item[type] - value;
        }
        return item;
      });
    }
    setLayouts(newLayout);
  };

  const components = [
    { id: "TunnelHealth", name: "Wall health", component: TunnelHealth },
    {
      id: "Map",
      component: MapTemplate,
      name: "Map",
      props: {
        fullscreen: false,
        mapType: "tile",
      },
    },
    {
      id: "TunnelTable",
      component: DashboardTable,
      name: "Information",
      props: {
        setLayouts: enlargeTable,
      },
    },
    {
      id: "BarChart",
      component: DashboardBarChart,
    },
    {
      id: "Viewer3D",
      name: "Inspector",
      component: Viewer3DArea,
      props: {
        fullscreen: false,
      },
    },
  ];

  const onLayoutChange = (_: layoutItem[], allLayouts: layouts) => {
    setLayouts(allLayouts);
  };

  const componentWidth = width ? width : window.innerWidth;

  return (
    <ResponsiveGrid
      className="layout"
      draggableHandle=".drag-handle"
      layouts={layouts}
      breakpoints={{ lg: 0 }}
      cols={{ lg: 9 }}
      margin={{
        lg: [horizontalMargin, verticalMargin],
      }}
      maxRows={desiredRowCount}
      rowHeight={rowHeight}
      width={componentWidth}
      onLayoutChange={onLayoutChange}
      isResizable={false}
    >
      {components.map((item) => (
        <WidgetWrapper
          key={item.id}
          className="widget"
          style={{
            "--box-shadow": `
              0px 4px 4px rgba(0, 0, 0, 0.25), 
              0px 4px 5px rgba(0, 0, 0, 0.14), 
              0px 1px 10px rgba(0, 0, 0, 0.12)
              `,
          }}
        >
          {item.name ? (
            <ComponentPaper>
              <Title>{item.name}</Title>
              <item.component {...item.props} />
            </ComponentPaper>
          ) : (
            <item.component {...item.props} />
          )}
        </WidgetWrapper>
      ))}
    </ResponsiveGrid>
  );
};

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Content);
