import React from "react";
import { Container, Row, Text, StyledButton } from "./DefectImageList.styles";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { set_comparison } from "redux/Viewer3DStatus";
import { viewerInstances } from "components";
import { DefectImage } from "types";

type Props = {
  defectImages: DefectImage[];
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
};

export const DefectImageList: React.FunctionComponent<Props> = ({
  defectImages,
  containerStyle,
  textStyle,
}) => {
  const comparison = useAppSelector((state) => state.Viewer3DStatus.comparison);
  const boundingBoxLayers = useAppSelector(
    (state) => state.VerticalButtonGroup.viewer3D.bounding_box_layers,
  );
  const dispatch = useAppDispatch();

  const setDefectImage = (defectImageId: number) => {
    viewerInstances.main?.setPolyLines(true, defectImageId);
    viewerInstances.main?.setSecondaryImage(true, defectImageId);
    viewerInstances?.main?.setOriginalBoundingBoxes(
      boundingBoxLayers.defect_type.length !== 0,
      boundingBoxLayers.min_confidence,
      boundingBoxLayers.date,
      boundingBoxLayers.defect_type,
    );
    if (!comparison) {
      dispatch(set_comparison(true));
    }
  };

  return (
    <Container style={containerStyle}>
      {defectImages.map((defectImage) => (
        <Row key={defectImage.name}>
          <Text style={textStyle}>{defectImage.name}</Text>
          <StyledButton onClick={() => setDefectImage(defectImage.id)}>
            View
          </StyledButton>
        </Row>
      ))}
    </Container>
  );
};
