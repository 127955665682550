import * as React from "react";
import TableBody from "@mui/material/TableBody";
import { row } from "types";
import {
  StyledTableRow,
  StyledTableCell,
  StyledTable,
  StyledTableCellAlignRight,
} from "./VerticalTable.styles";

type props = {
  rows: row[];
};
export const VerticalTable: React.FunctionComponent<props> = ({ rows }) => {
  return (
    <StyledTable size="small">
      <TableBody>
        {rows.map((row: row) => (
          <StyledTableRow key={row.name}>
            <StyledTableCell>{row.name}</StyledTableCell>
            <StyledTableCellAlignRight>{row.value}</StyledTableCellAlignRight>
          </StyledTableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};
