import { createSlice } from "@reduxjs/toolkit";

export interface SessionDataState {
  allowedPages: string[];
  initialPage: string;
  initialPath: string;
  origin: string;
  admin: boolean;
}

const initialState: SessionDataState = {
  allowedPages: [],
  initialPage: "",
  initialPath: "",
  origin: "",
  admin: false,
};

export const SessionData = createSlice({
  name: "SessionData",
  initialState,
  reducers: {
    set_session_data: (_, action) => {
      return { ...action.payload };
    },
  },
});

export const { set_session_data } = SessionData.actions;

export default SessionData.reducer;
