import { createSlice } from "@reduxjs/toolkit";
import { Bounds, Viewport } from "types";

interface MapState {
  viewport: Viewport;
  bounds: Bounds;
  canvas: {
    width: number;
    height: number;
  };
  mapDataReady: boolean;
}

const initialState: MapState = {
  viewport: {
    longitude: 0,
    latitude: 0,
    width: "100%",
    height: "100%",
    zoom: 10,
  },
  bounds: {
    minLong: 0,
    maxLong: 0,
    minLat: 0,
    maxLat: 0,
    centerLong: 0,
    centerLat: 0,
  },
  canvas: {
    width: 0,
    height: 0,
  },
  mapDataReady: false,
};

export const Map = createSlice({
  name: "Map",
  initialState,
  reducers: {
    set_viewport: (state, action) => {
      return { ...state, viewport: action.payload };
    },
    set_bounds: (state, action) => {
      return { ...state, bounds: action.payload };
    },
    set_canvas: (state, action) => {
      return { ...state, canvas: action.payload };
    },
    set_map_data_ready: (state, action) => {
      return { ...state, mapDataReady: action.payload };
    },
  },
});
export const { set_viewport, set_bounds, set_canvas, set_map_data_ready } =
  Map.actions;

export default Map.reducer;
