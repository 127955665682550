import React from "react";
import {
  CenterContainer,
  StyledSubModalTitle,
  StyledSubModalSubTitle,
  StyledSubModalText,
  IndentContainer,
  RadioGroup,
} from "components";
import { Theme } from "types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { set_theme } from "redux/Settings";

type ThemeSelectItem = { key: string; name: string; value: Theme };

const themes: ThemeSelectItem[] = [
  { key: "light_mode", name: "Light mode", value: "light" },
  { key: "dark_mode", name: "Dark mode", value: "dark" },
];

export const ThemeSubModal: React.FunctionComponent = () => {
  const theme = useAppSelector((state) => state.Settings.theme);
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(set_theme(value));
  };

  return (
    <CenterContainer>
      <StyledSubModalTitle>Colour theme</StyledSubModalTitle>
      <StyledSubModalSubTitle>Choose a colour theme</StyledSubModalSubTitle>
      <StyledSubModalText style={{ marginTop: 0 }}>
        Please select light mode or dark mode.
      </StyledSubModalText>
      <IndentContainer>
        <RadioGroup
          config={themes}
          initialValue={theme}
          onChange={handleChange}
        />
      </IndentContainer>
    </CenterContainer>
  );
};
