import React from "react";
import { Container, Text } from "./Error.styles";

type Props = {
  children: React.ReactNode;
};

export const Error: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <Container>
      <Text>{children}</Text>
    </Container>
  );
};
