import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../utils/config";
import { Settings } from "types";

export const updateSettings = async (
  apiToken: string,
  settings: Settings,
): Promise<string | null> => {
  const config: AxiosRequestConfig = {
    method: "PATCH",
    headers: { Authorization: `Bearer ${apiToken}` },
  };
  const response = await axios.patch(
    `${API_URL}api/settings`,
    settings,
    config,
  );
  if (response.status === 201) {
    return response.data;
  } else {
    return null;
  }
};
