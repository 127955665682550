import React, { useState } from "react";
import { StyledDefectCountSlider } from "components";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { set_defect_limit_green, set_defect_limit_red } from "redux/Settings";

export const DefectCountSlider: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { defectLimitGreen, defectLimitRed, maxDefects } = useAppSelector(
    (state) => state.Settings,
  );
  const [value, setValue] = useState<number[]>([
    defectLimitGreen,
    defectLimitRed,
  ]);

  const minDistance = 1;

  const handleChange = (
    _: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      const minValue = Math.min(newValue[0], value[1] - minDistance);
      setValue([minValue, value[1]]);
    } else {
      const maxValue = Math.max(newValue[1], value[0] + minDistance);
      setValue([value[0], maxValue]);
    }
  };

  const handleSetLimits = () => {
    dispatch(set_defect_limit_green(value[0]));
    dispatch(set_defect_limit_red(value[1]));
  };

  const middleRG = ((value[0] + value[1]) / 2 / maxDefects) * 100;
  const railStyle = {};

  return (
    <StyledDefectCountSlider
      value={value}
      min={0}
      max={maxDefects}
      sx={railStyle}
      getAriaLabel={() => "Minimum distance"}
      valueLabelDisplay="on"
      onChange={handleChange}
      onBlur={handleSetLimits}
      disableSwap
      middleRG={middleRG}
      marks={Array(maxDefects / 10 + 1)
        .fill(null)
        .map((_, i) => i * 10)
        .map((i) => ({
          label: i === 0 || i === maxDefects ? i : "",
          value: i,
        }))}
    />
  );
};
