import React, { useState } from "react";
import {
  StyledRadioGroup,
  StyledLabel,
  StyledRadio,
} from "./RadioGroup.styles";
import { RadioConfigType } from "./types";

export type RadioGroupProps = {
  config: RadioConfigType[];
  initialValue: string;
  onChange: (_: string) => void;
};

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = ({
  config,
  initialValue,
  onChange,
}) => {
  const [selectedRadio, setSelectedRadio] = useState<string>(initialValue);

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSelectedRadio(value);
    onChange(value);
  };

  return (
    <StyledRadioGroup value={selectedRadio} onChange={handleChange}>
      {config.map((radio) => (
        <StyledLabel
          key={radio.key}
          value={radio.value}
          control={<StyledRadio />}
          label={radio.name}
        />
      ))}
    </StyledRadioGroup>
  );
};
