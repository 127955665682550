import React from "react";
import { Tooltip } from "components";
import { Container, StyledInfoIcon } from "./Hint.styles";

type Props = {
  message: string;
};

export const Hint: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <Container>
      <Tooltip title={message} placement="right">
        <StyledInfoIcon />
      </Tooltip>
    </Container>
  );
};
