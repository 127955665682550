import React from "react";
import {
  Container,
  Text,
  SubText,
  TextContainer,
  LogoContainer,
  Logo,
  LogoText,
  ConstructionIcon,
} from "./ModalUnderConstruction.styles";

type Props = {
  subtext: string;
};

export const ModalUnderConstruction: React.FunctionComponent<Props> = ({
  subtext,
}) => {
  return (
    <Container>
      <TextContainer>
        <LogoContainer>
          <Logo src="/android-chrome-192x192.png" />
          <LogoText>Loupe360</LogoText>
        </LogoContainer>
        <Text>We're working on it</Text>
        <SubText>{subtext}</SubText>
        <ConstructionIcon />
      </TextContainer>
    </Container>
  );
};
