import React from "react";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { dashboardRoutes } from "pages/routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  LargeLogo,
  StyledDrawer,
  ImageHolder,
  StyledIconButton,
} from "./SideBar.styles";
import { useTheme } from "styled-components";
import { flip_state } from "redux/SideBarOpen";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ControlledLink, NavList } from "components";
import { Tooltip, tooltipsContent } from "components";

export const SideBar: React.FunctionComponent = () => {
  const open = useAppSelector((state) => state.SideBarOpen.open);
  const dispatch = useAppDispatch();

  const toggleDrawer = () => {
    dispatch(flip_state());
  };
  const theme = useTheme();
  return (
    <StyledDrawer variant="permanent" open={open}>
      <ControlledLink to="/" hideDisabled>
        <ImageHolder open={open}>
          <LargeLogo
            open={open}
            src={theme.custom.media.arupLogo}
            alt="Arup logo"
          />
        </ImageHolder>
      </ControlledLink>

      {dashboardRoutes.map((route) => (
        <NavList
          tunnelIdRoute
          text={route.navText}
          color={theme.custom.palette.foreground}
          Icon={route.icon}
          hidden={!open}
          link={route.path}
          key={route.path}
          tooltip={route.tooltip}
        />
      ))}
      <Divider />
      <NavList
        text={"Back"}
        color={theme.custom.palette.foreground}
        Icon={ArrowBackIcon}
        hidden={!open}
        link={"/"}
        tooltip={tooltipsContent.collapsedSidebarBack}
      />
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
      >
        <StyledIconButton onClick={toggleDrawer}>
          {open ? (
            <Tooltip title={tooltipsContent.expandedSidebarCollapse}>
              <ChevronLeftIcon />
            </Tooltip>
          ) : (
            <Tooltip title={tooltipsContent.collapsedSidebarExpand}>
              <ChevronRightIcon />
            </Tooltip>
          )}
        </StyledIconButton>
      </Toolbar>
    </StyledDrawer>
  );
};
